import React, { useState, useEffect } from "react";
import FollowingLoader from "../Loader/FollowingLoader";
import UserCard from "../Accounts/FansFollowing/UserCard";
import NoDataFound from "../NoDataFound/NoDataFound";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { t } from "react-multi-lang";

const SubscribersActiveSec = (props) => {
  const [sortedSubscribersData, setSortedSubscribersData] = useState([]);

  // Sorting logic based on props.sortingToogle
  useEffect(() => {
    if (props.subscribers?.data?.subscriber && props.subscribers.data?.subscriber?.length > 0) {
      const sortedData = [...props.subscribers.data.subscriber];
      if (props.sortingToogle?.aToz) {
        // Sort A to Z by username
        sortedData.sort((a, b) =>
          a.from_user?.username?.localeCompare(b.from_user?.username)
        );
      } else if (!props.sortingToogle?.aToz) {
        // Sort Z to A by username
        sortedData.sort((a, b) =>
          b.from_user?.username?.localeCompare(a.from_user?.username)
        );
      }
      if (props.sortingToogle?.upToDown) {
        // Sort latest to oldest by updated_at
        sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
      setSortedSubscribersData(sortedData);
    } else {
      setSortedSubscribersData([]);
    }
  }, [props.subscribers?.data?.subscriber, props.sortingToogle]);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.subscribers.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <div className="bookmarkes-list bookmarks-right-side">
              <div className="pull-left">
                <Link className="bookmarkes-list" to="#">
                  <span className="mr-2">
                    {props.subscribers?.data?.total
                      ? props.subscribers?.data?.total
                      : 0}{" "}
                  </span>{" "}
                  {t("users")}
                </Link>
              </div>
            </div>
            <Row>
              {sortedSubscribersData && sortedSubscribersData.length > 0 ? (
                sortedSubscribersData.map((subscriber, index) => (
                  <UserCard user={subscriber?.from_user} key={index} />
                ))
              ) : (
                <NoDataFound />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default SubscribersActiveSec;
