import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { deleteCustomListStart } from '../../../store/actions/CustomListAction';

const DeleteListModal = (props) => {
	const {isDeleteId, isCustomListDeleteModal, handleDeleteListModalClose, customList} = props;

	const dispatch = useDispatch();
	const isLoading = useSelector(state => state.customList.deleteCustomList.loading)
	return (
		<Modal
			show={isCustomListDeleteModal}
			onHide={handleDeleteListModalClose}
			backdrop="static"
			keyboard={false}
			centered
			className={`${
				localStorage.getItem("theme") !== "" &&
				localStorage.getItem("theme") !== null &&
				localStorage.getItem("theme") !== undefined &&
				localStorage.getItem("theme") === "dark"
					? "dark-theme-modal"
					: ""
			}
			`}
		>
			<Modal.Header closeButton>
				<Modal.Title>Delete subscription level</Modal.Title>
			</Modal.Header>
			<Modal.Body>
       <p style={{fontSize : "16px", fontWeight: "500"}}>Are you sure you want to delete?</p>
    	</Modal.Body>
			<Modal.Footer>
      <Button
        variant="secondary"
        size="lg"
        onClick={handleDeleteListModalClose}
      >
				 Back
      </Button>
      <Button
        variant=""
        size="lg"
        className="ftr-btn"
        onClick={()=> { 
            dispatch(deleteCustomListStart({list_id: {custom_list_id : isDeleteId} , handleClose : handleDeleteListModalClose, customList: customList} ))
        }}
				disabled={isLoading ? true: false}
        >
         {isLoading ? 'Loading...': 'Delete'}
      </Button>
    </Modal.Footer>
		</Modal>
	)
}

export default DeleteListModal