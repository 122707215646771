import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../../components/helper/NotificationMessage";
import { checkLogoutStatus } from "../actions/ErrorAction";
import { fetchSubscribingFailure, fetchSubscribingSuccess } from "../actions/SubscribingAction";
import { FETCH_SUBSCRIBING_START } from "../actions/ActionConstant";
import { addUserListSuccessD } from "../actions/CustomListAction";

function* fetchSubscribingAPI({payload}) {
	try {

    if(payload !== undefined && payload.setIsLoadingList !== undefined){
      payload.setIsLoadingList(true);
    }

    const filterSortPayload = payload?.filterSort ? payload?.filterSort : {
      filter: '',
      sort: '',
      tag_title: '',
      tag_colour: '',
      category: "",
    }
    
    console.log("fetchSubscribingAPI", filterSortPayload)
    const response = yield api.postMethod("active_subscribing", filterSortPayload);
    if (response.data.success) {
      if(payload !== undefined && payload.setIsLoadingList !== undefined){
        payload.setIsLoadingList(false);
      }

      const updatedSubscribingData = response.data?.data?.subscribing?.map((item) => {
        return {
          ...item,
          isSelected: false
        }
      })

      yield put(fetchSubscribingSuccess({subscribing: updatedSubscribingData, total: response.data?.data?.total}));

     if(payload !== undefined && payload.allListData !== undefined){

      const updatedData = payload.allListData.map((ele)=>{
         if(ele.list_name === payload.typeList){
           
          const updatedData = response?.data?.data?.subscribing?.map((ele)=>{
                
            return{
              ...ele,
              customListFlag : "SubscribingList"
            }

          })

          return {
               ...ele,
               data : updatedData,
               total : response?.data?.data?.total
          }

         }else{
          return ele;
         }

      })
      yield put(addUserListSuccessD(updatedData));
  
     }

    } else {
      if(payload !== undefined && payload.setIsLoadingList !== undefined){
        payload.setIsLoadingList(false);
      }
      yield put(fetchSubscribingFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    if(payload !== undefined && payload.setIsLoadingList !== undefined){
      payload.setIsLoadingList(false);
    }
    yield put(fetchSubscribingFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_SUBSCRIBING_START, fetchSubscribingAPI)]);
}