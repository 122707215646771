import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab } from "react-bootstrap";
import "../../Post/NewExplore/NewExplore.css";
import NewExploreCard from "../../Post/NewExplore/NewExploreCard";
import NewCategoryCard from "../../Post/NewExplore/NewCategoryCard";
import { connect, useSelector } from "react-redux";
import { translate, t } from "react-multi-lang";
import Map, { GoogleApiWrapper, Marker } from "google-maps-react";
import { apiConstants } from "../../Constant/constants";
import NewHomeSearch from "../../NewHome/NewSingleView/NewHomeSearch";
import { Multiselect } from "multiselect-react-dropdown";
import "./search_style.css";
import {
  clearSearchPageData,
  fetchPostCategoriesStart,
  fetchSearchPageContentStart,
  searchValueChange,
} from "../../../store/actions/PostAction";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import CustomListCard from "../../Accounts/CustomListUser/CustomListCard";
import NewExplorePostCard from "../../Post/NewExplore/NewExplorePostCard";
import NewFeedSuggestionCard from "../../NewHome/NewFeedSuggestionCard";
import NewFeedTrendingCard from "../../NewHome/NewFeedTrendingCard";

const memberOptions = [
  { id: 1, name: "All Members", label: "All Members", payload: "all_users" },
  { id: 2, name: "Star creator", label: "Star creator", payload: "" },
  {
    id: 3,
    name: "Certified creator",
    label: "Certified creator",
    payload: "all_certified",
  },
  { id: 4, name: "Creator", label: "Creator", payload: "creator" },
  { id: 5, name: "Subscribing", label: "Subscribing", payload: "subscribing" },
  { id: 6, name: "Following", label: "Following", payload: "following" },
];

const SearchPageIndex = (props) => {
  const [location, setLocation] = useState({});
  const [isSelectedType, setIsSelectedType] = useState("user");
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // const [inputSearchValue, setInputSearchValue] = useState("");
  console.log("🚀 ~ SearchPageIndex ~ selectedCategory:", selectedCategory);
  console.log("🚀 ~ SearchPageIndex ~ isSelectedType:", isSelectedType);
  const { loading: cateLoding, selectCategoryData: postCateOptions } =
    useSelector((state) => state.post.postCategories);
    const {
    loading: searchLoading,
    error: SearchError,
    data: SearchData,
    isSearch
  } = useSelector((state) => state.post.searchPageData);    

  let autocomplete;

  const renderAutoComplete = () => {
    const { google } = props;
    if (!google) {
      console.log("asdfsadfasdfno");
      return;
    }

    autocomplete = new google.maps.places.Autocomplete(autocomplete, {
      types: ["geocode"],
    });

    autocomplete.setFields(["address_component", "geometry", "name"]);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      console.log("Place", place);
      if (!place.geometry) return;
      let full_address = "";
      place.address_components.map(
        (address) =>
          (full_address =
            full_address == ""
              ? address.long_name
              : full_address + "," + address.long_name)
      );
      setLocation({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      });
    });
  };

  const clearSearch = () => {
    setLocation({});
    autocomplete.value = "";
  };

  const handleSelectType = (e) => {
    setIsSelectedType(e.target.value);
    setSelectedCategory([]);
    props.dispatch(clearSearchPageData());
    // setInputSearchValue("");
  };
  const handleKeySearch = (e) => {
    if (e.key === "Enter" && (searchValue)) {
      // props.dispatch(searchValueChange(searchValue));
      if (isSelectedType === "user") {
        props.dispatch(
          fetchSearchPageContentStart({
            search: searchValue,
            type: isSelectedType === "user" ? "user" : "post",
            filter: selectedCategory.map((ele) => ele.payload).toString(),
          })
        );
      } else {
        props.dispatch(
          fetchSearchPageContentStart({
            search: searchValue,
            type: isSelectedType === "user" ? "user" : "post",
            category: selectedCategory.map((ele) => ele.id).toString(),
          })
        );
      }
    }
  };

  const handleSelectSubmit = () => {
    if (searchValue) {
      // props.dispatch(searchValueChange(inputSearchValue));
      if (isSelectedType === "user") {
        props.dispatch(
          fetchSearchPageContentStart({
            search: searchValue,
            type: isSelectedType === "user" ? "user" : "post",
            filter: selectedCategory.map((ele) => ele.payload).toString(),
          })
        );
      } else {
        props.dispatch(
          fetchSearchPageContentStart({
            search: searchValue,
            type: isSelectedType === "user" ? "user" : "post",
            category: selectedCategory.map((ele) => ele.id).toString(),
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isSelectedType === "post") {
      props.dispatch(fetchPostCategoriesStart());
    }
  }, [isSelectedType]);

  return (
    <>
      <div className="new-explore-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="new-explore-tab-sec">
                <Tab.Container id="left-tabs-example" defaultActiveKey="search">
                  <Row>
                    <Col sm={12}>
                    <div className="d-flex justify-content-center">
                            <NewHomeSearch
                              desktop
                              handleKeySearch={handleKeySearch}
                              handleSelectSubmit={handleSelectSubmit}
                              // setInputSearchValue={setInputSearchValue}
                              searchValue={searchValue}
                              onSearchChange={setSearchValue}
                              // inputSearchValue={inputSearchValue}
                            />
                          </div>  
                      <div className="new-explore-tab-header-sec">
                     
                        <div className="searchPage_select_container">
                          <h3>Type</h3>
                          <select
                            value={isSelectedType}
                            onChange={handleSelectType}
                            className="searchPage_select_explore"
                            name="serachPageType"
                            id="serachPageType-id"
                          >
                            <option value="user">User</option>
                            <option value="post">Post</option>
                          </select>
                        </div>
                        {/* <Nav variant="pills">
                          <Nav.Item>
                            <Link
                              className="nav-link active"
                              eventKey="search"
                              // onClick={() => setActiveTab("explore")}
                              to="/search"
                            >
                              Member
                            </Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Link
                              className="nav-link"
                              eventKey="category"
                              // onClick={() => setActiveTab("category")}
                              to="/search-categories"
                            >
                              {t("category")}
                            </Link>
                          </Nav.Item>
                        </Nav> */}
                        <div
                          className="new-explore-search-sec-2"
                          style={{ display: "flex", gap: "10px" }}
                          >
                          <div>
                            <Multiselect
                              name="users_ids"
                              selectedValues={selectedCategory}
                              options={
                                isSelectedType === "post"
                                  ? postCateOptions
                                  : memberOptions
                              }
                              displayValue="name"
                              avoidHighlightFirstOption="true"
                              placeholder={
                                cateLoding ? "Loading..." : t("choose_category")
                              }
                              disable={cateLoding}
                              loading={cateLoding}
                              onSelect={(values) => {
                                // for post
                                const selectedPostsIds = values
                                  .map((ele) => ele.id)
                                  .toString();
                                  console.log(
                                  "🚀 ~ SearchPageIndex ~ selectedPosts:",
                                  selectedPostsIds
                                );

                                // isSelectedType === "post" && (
                                //   props.dispatch(
                                //     fetchSearchPageContentStart({
                                //       search: searchValue,
                                //       type:
                                //         isSelectedType === "user"
                                //           ? "user"
                                //           : "post",
                                //       filter: selectedCategory
                                //         .map((ele) => ele.payload)
                                //         .toString(),
                                //       category: selectedPostsIds,
                                //     })
                                //   )
                                // )

                                // for user
                                setSelectedCategory(values);
                              }}
                              onRemove={(values) => {
                                // for post
                                const selectedPostsIds = values
                                  .map((ele) => ele.id)
                                  .toString();
                                console.log(
                                  "🚀 ~ SearchPageIndex ~ selectedPosts:",
                                  selectedPostsIds
                                );

                                // isSelectedType === "post" &&   props.dispatch(
                                //   fetchSearchPageContentStart({
                                //     search: searchValue,
                                //     type:
                                //       isSelectedType === "user"
                                //         ? "user"
                                //         : "post",
                                //     filter: selectedCategory
                                //       .map((ele) => ele.payload)
                                //       .toString(),
                                //     category: selectedPostsIds,
                                //   })
                                // )

                                setSelectedCategory(values);
                              }}
                            />
                          </div>
                        
                        </div>
                        
                      </div>
                      
                    </Col>

                     <Col sm={12}>
                      {searchLoading && isSearch === "search" ? (
                        <FollowingLoader />
                      ) : isSearch === "search" ? (
                        SearchData.length > 0 ? (
                          SearchData.map((ele, index) => {
                            return ele?.postFiles?.length > 0 ? (
                              <NewExplorePostCard
                                data={ele}
                                key={ele?.user_id}
                              />
                            ) : (
                              <CustomListCard
                                key={ele?.user_id}
                                user={ele}
                                id={ele?.user_id}
                                customListType={
                                      props?.pageType === "customList"
                                    ? true
                                    : false
                                }
                              />
                            );
                          })
                        ) : (
                          <NoDataFound />
                        )
                      ) : (
                        ""
                      )}
                    </Col>
                   {
                    isSelectedType !== "post" && SearchData.length <= 0 && isSearch !== "search" && (
                      <div className="new-home-page-right">
                      <div className="new-feed-right-sec">
                        <div className="new-feed-suggestions-trending-sec" style={{display: "flex !important", gap: "10px !important"}}>
                          <NewFeedSuggestionCard />
                          {/* <NewFeedTrendingCard /> */}
                        </div>
                      </div>
                    </div>
                    )
                   }

                   <Col sm={12}>
                   {
                    isSelectedType === "post" && SearchData.length <= 0 && isSearch !== "search" && <NewExploreCard location={location} />
                   }
                   
                   </Col> 

                  

                   
                    {/* <Col sm={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="explore">
                          <NewExploreCard location={location} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="category">
                          <NewCategoryCard />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col> */}
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const connector = connect(null, mapDispatchToProps)(translate(SearchPageIndex));

export default GoogleApiWrapper({
  apiKey: apiConstants.google_api_key,
})(connector);
