import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image, Button, Media, Form, InputGroup, FormControl } from "react-bootstrap";
import "./BecomeAContentCreator.css";
// import { createNotification } from "react-redux-notify";
// import {
// 	getErrorNotificationMessage,
// } from "../helper/NotificationMessage";
import { connect, useDispatch } from "react-redux";
import { translate, t } from "react-multi-lang";
// import { Field } from "formik";
import { Multiselect } from "multiselect-react-dropdown";
import { fetchPostCategoriesStart } from "../../store/actions/PostAction";
import { addCategoriesStart } from "../../store/actions/UserAction";


const Step2 = (props) => {

  const [isCategoriesSelected, setIsCategoriesSelected] = useState([]);
	const [isSelected, setIsSelected] = useState(false);
	const dispatch = useDispatch()

	const handleCheckboxChange = (e) => {
		props.onCheckboxChange(e.target.checked); 
		props.setIsTerm(e.target.checked);
	  };

	const handleSelectList = (selectedList) => {
		if (selectedList.length <= 3) {
			setIsCategoriesSelected(selectedList);
		}
		setIsSelected(false)
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const selectCategoriesId = isCategoriesSelected.map(item => item.category_id).toString()

		if (selectCategoriesId) {
			dispatch(addCategoriesStart({category_id: selectCategoriesId, jumpToStep: props.jumpToStep}))
		} else {
			setIsSelected(true)
		}
	}
	useEffect(() => {
    props.dispatch(fetchPostCategoriesStart());
  }, []);

	return (
		<>
			{/* <div className="step-2-content-sec">
				<div className="step-2-info">
					<h4>{t('conditions')}</h4>
					<p>{t('here_are_some_important_points_about_your_account')}</p>
				</div>
				<div className="step-2-footer-sec">
					<p>{t('if_you_are_reading_this_page')}</p>
				</div>

				<div className="step-2-footer-sec d-flex ">
				<input type="checkbox" id="terms" name="terms" checked={props.isTerm} onChange={handleCheckboxChange} />
					<p className="ml-2">{t("terms_and_conditions")}</p>
				</div>

				<div className="edit-save">
			  {
                 props.isTerm && (
					<Button
					className="btn gradient-btn gradientcolor addBank"
					type="button"
					onClick={(e) => props.handleSubDoc(e, props.jumpToStep)}
					// disabled={props.addBankAccount.buttonDisable}
				  >
					{/* {props.addBankAccount.loadingButtonContent !== null
					  ? props.addBankAccount.loadingButtonContent
					  : "Next"} */}
				{/*	  Next
				  </Button>
				 )
			  }
          
            </div>


			</div> */}
			<div className="step-2-content-sec">
				<div
					style={{
						display: "flex",
						justifyContent: "center"
					}}
				>
					<Col md={6}>
						<Form.Group className="mb-0">
							<Form.Label style={{fontSize: "13px"}}>CATEGORY</Form.Label>
							{
							props.postCategories.data.post_categories ? 
							
							(
								<Multiselect
									name="category_ids"
									selectedValues={isCategoriesSelected}
									options={props.postCategories.data.post_categories}
									displayValue="name"
									avoidHighlightFirstOption="true"
									placeholder={t("choose_category")}
									onSelect={handleSelectList}
									onRemove={handleSelectList}
									selectionLimit={3}
								/>
							) : null}
							{
								isSelected ? (
									<p className="is_selected_error">Please select atleast one category</p>
								) : ""
							}
						</Form.Group>
						{/* <Form.Group controlId="formHorizontalLastname">
					<Form.Label>{t("last_name")}: (*)</Form.Label>
					<Form.Control
						type="text"
						placeholder={t("last_name")}
						// value={inputData.last_name}
						name="last_name"
						// onChange={(event) => {
						//   setInputData({
						//     ...inputData,
						//     last_name: event.currentTarget.value,
						//   });
						// }}
					/>
				</Form.Group> */}
					</Col>
				</div>
			
				<div className="edit-save" style={{paddingBottom: 0}}>
					<Button
						className="btn gradient-btn gradientcolor addBank"
						type="submit"
						onClick={handleSubmit}
						// disabled={props.addBankAccount.buttonDisable}
					>
						Next
					</Button>
				</div>
			</div>
		</>
	);
};

const mapStateToPros = (state) => ({
  postCategories: state.post.postCategories,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(Step2));
