import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Media,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "../BecomeAContentCreator/BecomeAContentCreator.css";
import { Link } from "react-router-dom";
import BecomeAContentCreatorLoader from "../Loader/BecomeAContentCreatorLoader";
import { connect } from "react-redux";
import { fetchUserDetailsStart } from "../../store/actions/UserAction";
import { translate, t } from "react-multi-lang";
import StepZilla from "react-stepzilla";
import Step1 from "../BecomeAContentCreator/Step1";
import Step2 from "../BecomeAContentCreator/Step2";
import Step3 from "../BecomeAContentCreator/Step3";
import Step4 from "../BecomeAContentCreator/Step4";
import Step5 from "../BecomeAContentCreator/Step5";
import Step7 from "../BecomeAContentCreator/Step7";
import Step8 from "../BecomeAContentCreator/Step8";
import StepRefferals from "../BecomeAContentCreator/StepRefferals";

const BecomeAContentCreator = (props) => {


  const [stepsNavigation, setStepsNavigation] = useState(true);

  const [initalStep, setInitalStep] = useState("");
  const [showNavigation, setShowNavigation] = useState(true);
  const [isTerm, setIsTerm] = useState(false);
  const [isApproModalOpen, setIsApproModalOpen] = useState(false);

  const handleCheckboxChange = (isChecked) => {
    // if (isChecked) {
    //   setShowNavigation(true);
    // } else {
    //   setShowNavigation(false);
    // }
  };

  const handleNextButton = () => {

    if (props.user.data.is_category_add === 1) {
      setInitalStep(4)
    }
    
    if (props.user.data.content_creator_step === 0) {
      setInitalStep(1)
    }
  }

  const handleApprvalModalOpen = ()=>{
    setIsApproModalOpen(true);
  }
  const handleApprvalModalClose = ()=>{
    setIsApproModalOpen(false);
  }

  const handleSubmitDocumentC = (e)=>{
    e.preventDefault();

    
    props.dispatch(fetchUserDetailsStart({is_approve_tc : isTerm}))
  }




  useEffect(() => {

   
    if (props.user.data.is_primary_data_full === 1 && props.user.data.is_category_add !== 1) {
      
      if (props.user.data.content_creator_step === 1 ) {

        setInitalStep(1)  
        setShowNavigation(true)
      } else {
        setInitalStep(1)
        setShowNavigation(false)
      }
    } else if (props.user.data.is_certified_content_creator === 2 && props.user.data.is_document_verified === 2) {
      
      setInitalStep(5)
      setShowNavigation(false)
    } else if (props.user.data.is_category_add === 1) {

      setInitalStep(3)
      setShowNavigation(false)
    } else if (props.user.data.content_creator_step === 0 && props?.user?.data?.content_creator_step !== 1) {
      setInitalStep(0)
      setShowNavigation(false)

    }  
  }, [props.user.data]);

  const steps = [
    
    { name: "Primary info", component: <Step8 user={props.user} />},
    { name: "Upload Documents", component: <Step1 user={props.user} /> },
    { name: "Categories", component: <Step2 handleApprvalModalClose={handleApprvalModalClose} handleApprvalModalOpen={handleApprvalModalOpen} isApproModalOpen={isApproModalOpen} handleSubDoc={handleSubmitDocumentC} user={props.user} setInitalStep={setInitalStep}  setIsTerm={setIsTerm} isTerm={isTerm} onCheckboxChange={handleCheckboxChange} showNavigation={false}/>},
    // { name: "Waiting for Admin approve the document", component: <Step3 user={props.user} /> },
    // { name: "Update billing details", component: <Step4 user={props.user} /> },
    {name: 'Referral', component: <StepRefferals handleNextButton={handleNextButton} setInitalStep={setInitalStep}/>},
    { name: "Wait for Admin approve the request", component: <Step7 user={props.user} /> },
    { name: "Finish", component: <Step5 user={props.user} /> },
  ];

  useEffect(() => {
    !props.user.loading && props.dispatch(fetchUserDetailsStart());
  }, [props.addKycDocInput.data]);

  return (
    <>
      <div className="become-content-creator-sec">
        <Container>
          {/**<BecomeAContentCreatorLoader/> **/}

          {initalStep === "" ? null : (
            <div className="become-content-creator-box box-step-4">
              <div className="step-progress">
                <StepZilla
                  steps={steps}
                  preventEnterSubmission={true}
                  stepsNavigation={stepsNavigation}
                  startAtStep={initalStep}
                  showNavigation={showNavigation}
                  onStepChange={handleNextButton}
                />
              </div>
            </div>
          )}
          {/* {props.user.loading ? (
            ""
          ) : props.user.data.is_content_creator == 2 ? (
            <div className="text-center">
              <h2 className="text-center text-success">
                {t("you_became_a_creator_now")}
              </h2>
              <Link
                to="/add-post"
                className="btn gradient-btn gradientcolor addBank"
              >
                {t("click_here_to_add_post")}
              </Link>
            </div>
          ) : (
            ""
          )} */}
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  user: state.users.profile,
  addKycDocInput: state.kycDocument.addKycDocInput,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(BecomeAContentCreator));
