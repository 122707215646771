import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { t } from 'react-multi-lang';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from 'formik';


const PostSchedualDateTModal = ({isScheduleModdalOpen , handleCloseModal , isSchedualData ,setStartDate , startDate , descriptionNote, handleChangeNote , handleSubmitSchadual , setIsStartDateError , IsStartDateError}) => {

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
    
        return currentDate.getTime() < selectedDate.getTime();
      };

 
      console.log(isSchedualData)




  return (
    <Modal
    show={isScheduleModdalOpen}
    onHide={()=> {
       handleCloseModal()
      }}
    backdrop="static"
    keyboard={false}
    centered
    className={`${
      localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
        ? "dark-theme-modal"
        : ""
    }
      `}
  >
         

          <Modal.Header closeButton>
            <Modal.Title>Schedule Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
        
              <div className='schedule_data_wrapper-c' style={{display: "flex", flexDirection: "column", gap : "3px"}}>
                   
                   <h4>Date & Time</h4>

                   <DatePicker
                   required
                   id='datePickerListP'
                  showTimeSelect
                  minDate={new Date()}
                  maxDate={null}
                  filterTime={filterPassedTime}
                  // className="dateTimePick"
                  selected={startDate}
                  value={startDate || isSchedualData?.schedule_time}
                  selectsStart
                  timeIntervals={1}
                  startDate={startDate}
                  placeholderText="Select Date & Time"
                  onChange={(date) => { 
                     setStartDate(date); 
                     setIsStartDateError(false);
                    
                    }}
                  dateFormat="MMM d, yyyy h:mm aa"
                />

            

                {
                  IsStartDateError && (
                    <p style={{color : "red"}}>Select date & time</p>
                  )
                }

                <div style={{marginTop: "20px"}} className='description_note_enter'>
                <h4 htmlFor="description_note">Note</h4>
                    <textarea
                      // className="form-control"
                      placeholder={"Enter Note..."}
                      name="description_note"
                      style={{border: "1px solid rgb(210, 214, 222)", padding: "8px", width: "100%", height: '70px', outline: "none" , fontSize:"12px"}}
                      value={descriptionNote || isSchedualData?.schedule_note}
                      onChange={(e)=> handleChangeNote(e)}
                    />
                </div>
              </div>
           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              size="lg"
              onClick={() => {
                // dispatch(subscriptionBadgeSuccess({}));
                  handleCloseModal()

                }
            }
            >
              {/* {t("close")} */} Back
            </Button>
            <Button size="lg" className="ftr-btn" type="button" onClick={(e)=> handleSubmitSchadual(e)}>
              {t("save")}
            </Button>
          </Modal.Footer>

  </Modal>
  )
}

export default PostSchedualDateTModal