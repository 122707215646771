import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addUserCustomListStart } from "../../../store/actions/CustomListAction";
import './AddUserCustomUserList.css'
import * as Yup from "yup";

const checkCustomList = Yup.object().shape({
	checkedList: Yup.array().min(1, "At least 1 custom list is required").required("required")
})

const AddUserCustomUserList = (props) => {
	
	const {isMoveModalOpen, handleMoveModalClose, handleMoveModalOpen, isCheckUserCustom, user_id, OtherUser} = props

	const initialValues = {
		checkedList: []
	}

	const dispatch = useDispatch()
	const isLoading = useSelector(state => state.customList.addAllUserCustomList.loading)
	return (
		<>
		
			<Modal
				show={isCheckUserCustom.length === 0 ? "" :isMoveModalOpen}
				onHide={handleMoveModalClose}
				backdrop="static"
				keyboard={false}
				centered
				className={`${
					localStorage.getItem("theme") !== "" &&
					localStorage.getItem("theme") !== null &&
					localStorage.getItem("theme") !== undefined &&
					localStorage.getItem("theme") === "dark"
						? "dark-theme-modal"
						: ""
					}`
				}
			>
			<Formik
				initialValues={initialValues}
				validationSchema={checkCustomList}
				onSubmit={(values) => {

					if (values.checkedList.length > 0) {
						dispatch(addUserCustomListStart({
							user_id: user_id, 
							cusom_list_id: values.checkedList.toString(), 
							handleClose: handleMoveModalClose, 
							OtherUser: OtherUser
						}))
					}
				}}
			>
				{({errors, touched, handleSubmit}) => (
					<form onSubmit={handleSubmit}>
						<Modal.Header closeButton>
							<Modal.Title>Move to custom list</Modal.Title>
						</Modal.Header>
						<Modal.Body>
								<div className="field-checkbox-container">
									{
										isCheckUserCustom.length > 0 
											?
										<> 
											{isCheckUserCustom.map((item) => (
												<div className="check-container" role="group" aria-labelledby="checkbox-group">	
													<label>
														<Field 
															name="checkedList"
															type="checkbox"
															value={`${item?.CID}`}
														/>
														{item?.list_name}
													</label>
												</div>		
											))}
											{
												errors.checkedList && touched.checkedList ? 
												(
													<div className="list-error">{errors.checkedList}</div>
												)
												: null
											}
										</>
											:
											<div className="no-list-container">
												<h3>User is present in all custom list</h3>
											</div>
									}
								</div>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant="secondary"
								size="lg"
								onClick={() => {
									handleMoveModalClose()
								}}
							>
								Back
							</Button>
							<Button 
								size="lg" 
								className="ftr-btn" 
								type="submit"
								disabled={isLoading}
							>
								{isLoading ? 'loading...' : 'Move'}
							</Button>
						</Modal.Footer>
					</form>
				)}
			</Formik>
			</Modal> 
		
		</>
	)
}

export default AddUserCustomUserList