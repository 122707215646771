import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";

import { createNotification } from "react-redux-notify";

import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";

import { checkLogoutStatus } from "../actions/ErrorAction";
import { ADD_CUSTOM_LIST_START, ADD_USER_CUSTOM_LIST_START, ALL_USER_CUSTOM_LIST_START, DELETE_CUSTOM_LIST_START, DELETE_USER_CUSTOM_LIST_START, FRIREND_REQUEST_SEEN_START, GET_CHECK_USER_CUSTOM_LIST_START, GET_CUSTOM_LIST_START, GET_FRIREND_REQUEST_SEEN_START, GET_SINGLE_SEARCH_USER_LIST_START, GET_USER_ALL_COLOR_TAG_START, GET_USER_CUSTOM_LIST_START, POST_CUSTOM_FAVOURITE_LIST_START, SEARCH_USER_LIST_START } from "../actions/ActionConstant";
import { addCustomListFailure, addCustomListSuccess, addUserCustomListFailure, addUserCustomListSuccess, addUserListSuccessD, allUserListFailure, allUserListSuccess, deleteCustomListFailure, deleteCustomListSuccess, deleteCustomUserListFailure, deleteCustomUserListSuccess, getAllUserCustomListFailure, getAllUserCustomListSuccess, getCheckUserCustomListFailure, getCheckUserCustomListSuccess, getCustomListFailure, getCustomListSuccess, getSingleSearchListUserFailure, getSingleSearchListUserSuccess, getUserAllColorTagFailure, getUserAllColorTagSuccess, isListFriendRequestUpdate,
  addUserCustomFavouriteListFailure,
  addUserCustomFavouriteListSuccess, } from "../actions/CustomListAction";
import { searchListUserSFailure, searchListUserSuccess } from "../actions/HomeAction";

// function* addCustomLists ({payload}) {
// 	try {
// 		const response = yield api.postMethod("add_custom_list_for_owner",
// 			payload?.isEditCustomList?.CID 
// 				?	{custom_list_id: payload?.isEditCustomList?.CID, list_name: payload.listValue.list_name}
// 				:  payload.listValue)

// 		if (response.data?.success) {

// 			if (payload?.isEditCustomList?.CID) {

// 				const updatedCustomList = payload?.customList.map(item => {
// 					if (item.CID === payload?.isEditCustomList?.CID) {
// 						return {
// 							...item,
// 							list_name: response.data?.data?.list_name
// 						}
// 					} else {
// 						return item
// 					}
// 				})
				
// 				yield put(getCustomListSuccess(updatedCustomList))
// 				yield put(addCustomListSuccess(response.data?.data))
// 			} else {
// 				yield put(addCustomListSuccess(response.data?.data))

// 				yield put(getCustomListSuccess([...payload.customList, {CID: response.data?.data.id , list_name: response.data?.data?.list_name, total_user: payload.totalSelectedListUsers}]))
// 			}
// 		}
// 		const notificationMessage = getSuccessNotificationMessage(response.data?.message);
// 		yield put(createNotification(notificationMessage));

// 		payload.handleClose();
// 	} catch (error) {
// 		payload.handleClose();
// 		yield put(addCustomListFailure(error))
// 		const notificationMessage = getErrorNotificationMessage(error.message);
// } }
function* addCustomLists({ payload }) {
  try {
    const response = yield api.postMethod(
      "add_custom_list_for_owner",
      payload?.isEditCustomList?.CID
        ? {
            custom_list_id: payload?.isEditCustomList?.CID,
            list_name: payload.listValue.list_name,
          }
        : payload.listValue
    );

    if (response.data?.success) {
      if (payload?.isEditCustomList?.CID) {
        const updatedCustomList = payload?.customList.map((item) => {
          if (item.CID === payload?.isEditCustomList?.CID) {
            return {
              ...item,
              list_name: response.data?.data?.list_name,
            };
          } else {
            return item;
          }
        });

        yield put(getCustomListSuccess(updatedCustomList));
        yield put(addCustomListSuccess(response.data?.data));
        
      } else {
        yield put(addCustomListSuccess(response.data?.data));

        yield put(
          getCustomListSuccess([
            ...payload.customList,
            {
              CID: response.data?.data.id,
              list_name: response.data?.data?.list_name,
              total_user: payload.totalSelectedListUsers,
            },
          ])
        );
      }
    }
    const notificationMessage = getSuccessNotificationMessage(
      response.data?.message
    );
    yield put(createNotification(notificationMessage));

    payload.handleClose();
  } catch (error) {
    payload.handleClose();
    yield put(addCustomListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addUserCustomFavouriteList({ payload }) {
  try {
    const response = yield api.postMethod("add_mulusers_in_cflist", {
      customlist_ids: payload.customlist_ids,
      users_ids: payload.users_ids,
      is_customlist: payload.is_customlist,
      is_fav: payload.is_fav,
    });

    if (response.data?.success) {
      yield put(addUserCustomFavouriteListSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data?.message
      );
      yield put(createNotification(notificationMessage));

      // const allSelectedFollowingUser = yield select(state => state.follow.activeFollowing.data.followers)

      if (payload.pageType === "followingType") {
        const updatedSelectedFollowingUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.user_id)) {
            return {
              ...elem,
              otherUser: {
                ...elem.otherUser,
                is_fav_user:
                  payload.is_fav === "yes" ? 1 : elem.otherUser.is_fav_user,
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedFollowingUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload.pageType === "followersType") {
        const updatedSelectedFollowersUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.otherUser?.user_id)) {
            return {
              ...elem,
              otherUser: {
                ...elem.otherUser,
                is_fav_user:
                  payload.is_fav === "yes" ? 1 : elem.otherUser.is_fav_user,
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedFollowersUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "friendType") {
        const updatedSelectedFriendsUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.user?.user_id)) {
            return {
              ...elem,
              is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
              user: {
                ...elem.user,
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedFriendsUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "subscribingType") {
        const updatedSelectedSubscribingUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.to_user?.user_id)) {
            return {
              ...elem,
              is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
              isSelected: false,
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedSubscribingUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "subscribersType") {
        const updatedSelectedSubscribersUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.from_user?.user_id)) {
            return {
              ...elem,
              is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
              isSelected: false,
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedSubscribersUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "unsubscribedType") {
        const updatedSelectedUnsubscribedUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.to_user?.user_id)) {
            return {
              ...elem,
              is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
              isSelected: false,
            };
          } else {
            return {
              ...elem,
              isSelected: false,
            };
          }
        });

        payload.setUserData(updatedSelectedUnsubscribedUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "formerSubscribersType") {
        const updatedSelectedFormerSubscriberUser = payload.userData.map(
          (elem) => {
            if (payload.users_ids.includes(elem.from_user?.user_id)) {
              return {
                ...elem,
                is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
                isSelected: false,
              };
            } else {
              return {
                ...elem,
                isSelected: false,
              };
            }
          }
        );

        payload.setUserData(updatedSelectedFormerSubscriberUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "restrictedUserType") {
        const updatedSelectedRestrictedUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.restricteduser?.user_id)) {
            return {
              ...elem,
              is_fav_user: payload.is_fav === "yes" ? 1 : elem.is_fav_user,
              restricteduser: {
                ...elem.restricteduser,
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              restricteduser: {
                ...elem.restricteduser,
                isSelected: false,
              },
            };
          }
        });

        payload.setUserData(updatedSelectedRestrictedUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

      if (payload?.pageType === "blockedUserType") {
        const updatedSelectedBlockedUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.blockeduser?.user_id)) {
            return {
              ...elem,
              blockeduser: {
                ...elem.blockeduser,
                isSelected: false,
              },
            };
          } else {
            return {
              ...elem,
              blockeduser: {
                ...elem.blockeduser,
                isSelected: false,
              },
            };
          }
        });

        payload.setUserData(updatedSelectedBlockedUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
      }

			if (payload?.pageType === "customListUserType") {
				const updatedSelectedBlockedUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.user?.user_id)) {
            return {
              ...elem,
              user: {
                ...elem.user,
                isSelected: false,
								is_fav_user: payload.is_fav === "yes" ? 1 : elem?.user?.is_fav_user,
              },
            };
          } else {
            return {
              ...elem,
              user: {
                ...elem.user,
                isSelected: false,
              },
            };
          }
        });

        payload.setUserData(updatedSelectedBlockedUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
			}

			if (payload?.pageType === "favouriteType") {
				const updatedSelectedFavouriteUser = payload.userData.map((elem) => {
          if (payload.users_ids.includes(elem.fav_user?.user_id)) {
            return {
              ...elem,
              fav_user: {
                ...elem.fav_user,
                isSelected: false,
								is_fav_user: payload.is_fav === "yes" ? 1 : elem?.fav_user?.is_fav_user,
              },
            };
          } else {
            return {
              ...elem,
              fav_user: {
                ...elem.fav_user,
                isSelected: false,
              },
            };
          }
        });

        payload.setUserData(updatedSelectedFavouriteUser);
        payload.setIsSelected(false);
        payload.setCheckedUserId([]);
        return payload.handleCloseAddUserModal();
			}
    } else {
      
      yield put(addUserCustomFavouriteListFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));

      payload.setIsSelected(false);
      payload.setCheckedUserId([]);
      payload.handleCloseAddUserModal();
    }
  } catch (error) {
    yield put(addUserCustomFavouriteListFailure(error))
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));

    payload.setIsSelected(false);
    payload.setCheckedUserId([]);
    payload.handleCloseAddUserModal();
  }
}

function* getUserColorsTagApi({payload}) {
	try {
	
		const response = yield api.postMethod("get_user_tag_color");

		if (response.data?.success) {
		
			yield put(getUserAllColorTagSuccess(response.data?.data));

    }
  } catch (error) {
	
    yield put(getUserAllColorTagFailure(error))
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* searchUserListAPI() {
	try {
		// debugger;
	  const inputData = yield select((state) => state.customList.userSearchList.inputData);
	  const response = yield api.postMethod("filter_list_users", inputData);
	  if (response.data.success) {
		yield put(searchListUserSuccess(response.data.data));
	  } else {
		yield put(searchListUserSFailure(response.data.error));
		const notificationMessage = getErrorNotificationMessage(
		  response.data.error
		);
		yield put(checkLogoutStatus(response.data));
		yield put(createNotification(notificationMessage));
	  }
	} catch (error) {
	  yield put(searchListUserSFailure(error));
	  const notificationMessage = getErrorNotificationMessage(error.message);
	  yield put(createNotification(notificationMessage));
	}
  }

function* searchSingleUserListAPI() {
	try {
		// debugger;
	  const inputData = yield select((state) => state.customList.singleUserListSerach.inputData);
	  console.log("🚀 ~ function*searchSingleUserListAPI ~ inputData:", inputData)
	  const response = yield api.postMethod("get_filter_list_user_details", inputData);
	  if (response.data.success) {
		yield put(getSingleSearchListUserSuccess(response.data.data));
	  } else {
		
		// yield put(isListUtilsIsList(true));
		yield put(getSingleSearchListUserFailure(response.data.error));
		const notificationMessage = getErrorNotificationMessage(response.data.error);
		yield put(checkLogoutStatus(response.data));
		yield put(createNotification(notificationMessage));
	  }
	} catch (error) {
	//   yield put(isListUtilsIsList(true));
	  yield put(getSingleSearchListUserFailure(error));
	  const notificationMessage = getErrorNotificationMessage(error.message);
	  yield put(createNotification(notificationMessage));
	}
  }

  function* getFriendRequestSeenApi({payload}) {
    try {
      
      const response = yield api.postMethod("get_friend_request_seen_status");
  
      if (response.data?.success) {
        yield put(isListFriendRequestUpdate(response.data?.data?.friend_request_seen_status));
      }
    } catch (error) {
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }

    
function* friendRequestSeenApi({payload}) {
	try {

		const response = yield api.postMethod("friend_request_seen_status");

		if (response.data?.success) {
			yield put(isListFriendRequestUpdate(0));
		}
	} catch (error) {
		const notificationMessage = getErrorNotificationMessage(error.message);
		yield put(createNotification(notificationMessage));
	}
}

function* getCustomList({ payload }) {

  try {
    const response = yield api.postMethod("all_custom_list_for_owner");

    if (response.data?.success) {
      yield put(getCustomListSuccess(response.data?.data));

    }
  } catch (error) {
    yield put(getCustomListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteCustomLists({ payload }) {
  try {
    const response = yield api.postMethod(
      "delete_custom_list_for_owner",
      payload.list_id
    );

    if (response.data?.success) {
      yield put(deleteCustomListSuccess(response.data?.data));

      const filterCustomList = payload.customList?.filter((elem) => {
        return elem.CID !== payload.list_id.custom_list_id;
      });

      yield put(getCustomListSuccess(filterCustomList));

      const notificationMessage = getSuccessNotificationMessage(
        response.data?.message
      );
      yield put(createNotification(notificationMessage));
    }
    payload.handleClose();
  } catch (error) {
    payload.handleClose();
    yield put(deleteCustomListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getUserCheckCustomList({ payload }) {
  try {
    const response = yield api.postMethod("check_user_custom_list", {
      user_id: payload.user_id,
    });

    if (response.data?.success) {
      yield put(getCheckUserCustomListSuccess(response.data?.data));
    }
  } catch (error) {
    yield put(getCheckUserCustomListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* addUserCustomLists({ payload }) {
  try {
    const response = yield api.postMethod("add_user_custom_list", {
      user_id: payload.user_id,
      cusom_list_id: payload.cusom_list_id,
    });

    if (response.data?.success) {
      yield put(addUserCustomListSuccess(response.data?.data));

      if (payload?.OtherUser === "otherUser") {
        const checkCustomList = yield select(
          (state) => state.customList.getCheckUserCustomList.data
        );

        const responseIdArr = response.data.data.map((item) =>
          Number(item.cusom_list_id)
        );

        const updateCheckCustomList = checkCustomList?.filter(
          (item) => !responseIdArr.includes(item.CID)
        );

        yield put(getCheckUserCustomListSuccess(updateCheckCustomList));
      }
      const notificationMessage = getSuccessNotificationMessage(
        response.data?.message
      );
      yield put(createNotification(notificationMessage));

      payload.handleClose();
    } else {
      yield put(addUserCustomListFailure(response.data?.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data?.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    payload.handleClose();
    yield put(addUserCustomListFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* getAllUserCustomList({ payload }) {
  try {
    if (payload.setIsLoadingList !== undefined) {
      payload.setIsLoadingList(true);
    }

    const filterSortPayload = payload?.filterSort ? payload?.filterSort : {
			filter: '',
			sort: '',
			tag_title: '',
			tag_colour: '',
			category: "",
      custom_list_id: '',
		  }
    const response = yield api.postMethod("list_user_custom_list", filterSortPayload);

    if (response.data?.success) {
      if (payload.setIsLoadingList !== undefined) {
        payload.setIsLoadingList(false);
      }

			const updatedCustomUserData = response.data.data.map((item) => {
				return {
					...item,
					user: {
						...item.user,
						isSelected: false
					}
				}
			})
      yield put(getAllUserCustomListSuccess(updatedCustomUserData));

      // yield put(getAllUserCustomListSuccess(response.data?.data));

      if (payload !== undefined && payload.allListData !== undefined) {
        const updatedData = payload.allListData.map((ele) => {
          if (Number(ele.CID) === Number(payload.id)) {
            const updatedD = response.data?.data.map((ele) => {
              return {
                ...ele,
                customListFlag: "customListF",
              };
            });

            return {
              ...ele,
              data: updatedD,
              total: response.data?.data?.length,
            };
          } else {
            return ele;
          }
        });

              yield put(addUserListSuccessD(updatedData));

			 }

		}
	} catch (error) {
		if(payload.setIsLoadingList !== undefined){
			payload.setIsLoadingList(false);
		}
		yield put(getAllUserCustomListFailure(error))
		const notificationMessage = getErrorNotificationMessage(error.message);
		yield put(createNotification(notificationMessage));
	}
}


function* allUserCustomList2({payload}) {
	try {
	
		const response = yield api.postMethod("list_details");

		if (response.data?.success) {
		
			yield put(allUserListSuccess(response.data?.data));

		}
	} catch (error) {
	
		yield put(allUserListFailure(error))
		const notificationMessage = getErrorNotificationMessage(error.message);
		yield put(createNotification(notificationMessage));
	}
}

function* deleteAllUserCustomLists({payload}) {
	try {
		const response = yield api.postMethod("delete_user_custom_list", {custom_list_user_id: payload.custom_list_user_id})

		if (response.data?.success) {

			yield put(deleteCustomUserListSuccess(response.data?.data))
			
			const filterCustomList = payload.isAllUserCustomList?.filter(elem => {
				return elem.id !== payload.custom_list_user_id
			})

			yield put(getAllUserCustomListSuccess(filterCustomList))

			const notificationMessage = getSuccessNotificationMessage(response.data?.message);
			yield put(createNotification(notificationMessage));

		}
		// payload.handleClose()

	} catch (error) {
		// payload.handleClose();
		yield put(deleteCustomUserListFailure(error))
		const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
	yield all([yield takeLatest(ADD_CUSTOM_LIST_START, addCustomLists)]);
  yield all([
    yield takeLatest(
      POST_CUSTOM_FAVOURITE_LIST_START,
      addUserCustomFavouriteList
    ),
  ]);
	yield all([yield takeLatest(GET_CUSTOM_LIST_START, getCustomList)]);
	yield all([yield takeLatest(DELETE_CUSTOM_LIST_START, deleteCustomLists)]);
	yield all([yield takeLatest(GET_CHECK_USER_CUSTOM_LIST_START, getUserCheckCustomList)]);
	yield all([yield takeLatest(ADD_USER_CUSTOM_LIST_START, addUserCustomLists)]);
	yield all([yield takeLatest(GET_USER_CUSTOM_LIST_START, getAllUserCustomList)]);
	yield all([yield takeLatest(ALL_USER_CUSTOM_LIST_START, allUserCustomList2)]);
  yield all([yield takeLatest(FRIREND_REQUEST_SEEN_START, friendRequestSeenApi)]);
  yield all([
    yield takeLatest(DELETE_USER_CUSTOM_LIST_START, deleteAllUserCustomLists)
  ]);
  yield all([yield takeLatest(GET_FRIREND_REQUEST_SEEN_START, getFriendRequestSeenApi)]);
  yield all([yield takeLatest(GET_SINGLE_SEARCH_USER_LIST_START, searchSingleUserListAPI)]);
  yield all([yield takeLatest(GET_USER_ALL_COLOR_TAG_START, getUserColorsTagApi)]);
  yield all([yield takeLatest(SEARCH_USER_LIST_START, searchUserListAPI)]);
}
