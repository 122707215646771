import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import { t } from 'react-multi-lang'
import { useDispatch, useSelector } from 'react-redux'
import {Link , useHistory} from "react-router-dom"
import { fetchDraftPostsStart } from '../../store/actions/PostAction'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'
import ProfileSinglePost from '../helper/ProfileSinglePost'
import NoDataFound from '../NoDataFound/NoDataFound'

const DraftPostsIndex = () => {

    const { draftPosts } = useSelector((state)=> state.post)
    // console.log("🚀 ~ DraftPostsIndex ~ draftPosts:", draftPosts)


    const [activeSection , setActiveSection] = useState("all");
    const [take, setTake] = useState(12)    
 
    const dispatch = useDispatch();

    const history = useHistory()



    useEffect(()=>{
        
        dispatch(fetchDraftPostsStart({ type: activeSection, skip: 0, take: take }))

    },[activeSection])

  return (
     <div className='lists'>
        <Container>
        <Row>
          <Col sm={12} md={12} xs={12}>
            <div className="profile-post-area">
              <div className="bookmarkes-list bookmarks-right-side">
                <div className="pull-left">
                  <h3>
                    <Link
                      className="bookmarkes-list"
                      to={"/home"}
                      onClick={() => history.goBack()}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/back.svg"
                        }
                        className="svg-clone"
                      />
                      {t("draft_posts")}
                    </Link>
                  </h3>
                </div>
              </div>
            </div>
              
            <div className="profile-tab-sec">
                                <Tab.Container id="left-tabs-example" defaultActiveKey="all">
                                    <Row>
                                        <Col sm={12}>
                                            <Nav variant="pills" className="grid-four-col">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="all"
                                                        onClick={(event) =>{ 
                                                            setActiveSection("all")
                                                        }}>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/all-post-1.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("all")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="image"
                                                        onClick={(event) =>
                                                            setActiveSection("image")
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/image-post-1.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("images")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="video"
                                                        onClick={(event) =>
                                                            setActiveSection("video")
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/video-post-1.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("videos")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="audio"
                                                        onClick={(event) =>
                                                            setActiveSection("audio")
                                                        }>
                                                        <span>
                                                            <Image
                                                                className="profile-post-tab-icon"
                                                                src={
                                                                    window.location.origin + "/assets/images/new-home/icon/audio-post-1.svg"
                                                                }
                                                            />
                                                        </span>
                                                        <span className="resp-display-none">{t("musics")}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            {draftPosts.loading ?
                                                <div className="profile-all-post-box">
                                                    {
                                                        [...Array(8)].map(() =>
                                                            <Skeleton
                                                                className="profile-post-card-loader" />
                                                        )
                                                    }
                                                </div>
                                                : <>
                                                    {draftPosts?.data?.posts?.length > 0 ?
                                                        <InfiniteScroll
                                                            dataLength={draftPosts.data?.posts?.length}
                                                            // next={fetchMorePost}
                                                            hasMore={draftPosts.data?.posts?.length < draftPosts?.data?.total}
                                                            loader={
                                                                <div className="profile-all-post-box">
                                                                    {[...Array(4)].map((ele) =>
                                                                        <Skeleton
                                                                            key={ele}
                                                                            className="profile-post-card-loader" />
                                                                    )}
                                                                </div>
                                                            }
                                                            style={{ height: 'auto', overflow: 'hidden' }}
                                                        >
                                                            <div className="profile-all-post-box">
                                                                {draftPosts.data?.posts?.map((post) => {

                                                                    return(
                                                                        post?.postFiles &&
                                                                            post?.postFiles?.length > 0 &&
                                                                            // post.postFiles.map((postFile, index) =>
                                                                            <ProfileSinglePost post={post} isDraft={true} key={post?.id} />
                                                                        

                                                                    )
                                                                             

                                                                 })
                                                                }
                                                            </div>
                                                        </InfiniteScroll>
                                                        : (<NoDataFound />)
                                                    }
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>

          </Col>
        </Row>
        </Container>

     </div>
  )
}

export default DraftPostsIndex