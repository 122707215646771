import { Field, Formik } from "formik";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { t } from "react-multi-lang";
import "./Profile.css";
import AccessContentW from "./AccessContentW";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionBadgeStart, subscriptionBadgeSuccess } from "../../../store/actions/SubscriptionAction";
import * as Yup from "yup";
import { createNotification } from "react-redux-notify";
import { getErrorNotificationMessage } from "../../helper/NotificationMessage";

const accessContentData = [
  {
    id: 0,
    name: {
      mainToogle: "fullAccess",
    },
    text: "Full access",
  },
  {
    id: 1,
    name: {
      mainToogle: "posts",
      limitName: "postsLimit",
      inputName: "postsInput",
    },
    text: "Posts",
  },
  {
    id: 2,
    name: {
      mainToogle: "liveStreaming",
      limitName: "liveStreamingLimit",
      inputName: "liveStreamingInput",
    },
    text: "Live streaming",
  },
  {
    id: 3,
    name: {
      mainToogle: "ticketedEvent",
      limitName: "ticketedEventLimit",
      inputName: "ticketedEventInput",
    },
    text: "Ticketed event",
  },
  {
    id: 4,
    name: {
      mainToogle: "proposeCall",
      limitName: "proposeCallLimit",
      inputName: "proposeCallInput",
    },
    text: "Propose call",
  },
  {
    id: 5,
    name: {
      mainToogle: "bidCall",
      limitName: "bidCallLimit",
      inputName: "bidCallInput",
    },
    text: "Bid a call",
  },
  {
    id: 6,
    name: {
      mainToogle: "sendMessage",
      limitName: "sendMessageLimit",
      inputName: "sendMessageInput",
    },
    text: "Send message",
  },
  {
    id: 7,
    name: {
      mainToogle: "ppvRequest",
      limitName: "ppvRequestLimit",
      inputName: "ppvRequestInput",
    },
    text: "PPV request",
  },
];

const addSubLevelSchema = Yup.object().shape({
  alias_of_level: Yup.string().required("Filed is required"),
  accumulate_spending: Yup.number().required("Filed is required"),
  badgeVal: Yup.string().required("Badge is required"),
  // description_level : Yup.string().required("Filed is required")
});

const AddLevelModal = ({
  isAddLevelModalOpen,
  handleAddLModalClose,
  handleAddSubLModalOpen,
  isLevelEditData,
  levelData,
  selectedLeveleBtn,
  handleSelectInherit,
  selectedInheritValue
}) => {

  const [textAreaValue, setTextAreaValue] = useState("")

  const { subLevelData , allSubLevelList } = useSelector((state) => state.subscriptions);
  const dispatch = useDispatch();
   
  const isLevel_alias_of_level = levelData.previousData !== null ? levelData.previousData?.alias_of_level : "";
  const isLevel_accumulate_spending = levelData.previousData !== null ? levelData.previousData?.accumulate_spending : "" ;
  const isLevel_badgeVal = levelData.previousData !== null ? levelData.previousData?.badge : "" ;
  const isLevel_description_level = levelData.previousData !== null ? levelData.previousData?.description_level : "" ;
  const isLevel_full_access = levelData.previousData !== null ? levelData.previousData?.full_access == 1 ? true : false : false ;
  const isLevel_post_status = levelData.previousData !== null ? levelData.previousData?.post_status == 1 ? true : false : false ;
  const isLevel_post_limitName = levelData.previousData !== null ? levelData.previousData?.post_value === "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_post_inputName = levelData.previousData !== null ? levelData.previousData?.post_value == "No Limit" ? "No Limit" : levelData.previousData?.post_value : "";
  const isLevel_live_streming_status = levelData.previousData !== null ? levelData.previousData?.live_streming_status == 1 ? true : false : false
  const isLevel_live_streming_limitName = levelData.previousData !== null ? levelData.previousData?.live_streming_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_live_streming_inputName = levelData.previousData !== null ? levelData.previousData?.live_streming_value == "No Limit" ? "No Limit" : levelData.previousData?.live_streming_value : "";
  const isLevel_ticked_status = levelData.previousData !== null ? levelData.previousData?.ticked_status == 1 ? true : false : false
  const isLevel_ticked_limitName = levelData.previousData !== null ? levelData.previousData?.ticked_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_ticked_inputName = levelData.previousData !== null ? levelData.previousData?.ticked_value == "No Limit" ? "No Limit" : levelData.previousData?.ticked_value : "";
  const isLevel_propose_status = levelData.previousData !== null ? levelData.previousData?.propose_status == 1 ? true : false : false;
  const isLevel_propose_limitName = levelData.previousData !== null ? levelData.previousData?.propose_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_propose_inputName = levelData.previousData !== null ? levelData.previousData?.propose_value == "No Limit" ? "No Limit" : levelData.previousData?.propose_value : ""
  const isLevel_bid_status = levelData.previousData !== null ? levelData.previousData?.bid_status == 1 ? true : false : false;
  const isLevel_bid_limitName = levelData.previousData !== null ? levelData.previousData?.bid_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_bid_inputName = levelData.previousData !== null ? levelData.previousData?.bid_value == "No Limit" ? "No Limit" : levelData.previousData?.bid_value : "";
  const isLevel_send_msg_status = levelData.previousData !== null ? levelData.previousData?.send_msg_status == 1 ? true : false : false;
  const isLevel_send_msg_limitName = levelData.previousData !== null ? levelData.previousData?.send_msg_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_send_msg_inputName = levelData.previousData !== null ? levelData.previousData?.send_msg_value == "No Limit" ? "No Limit" : levelData.previousData?.send_msg_value : "";
  const isLevel_ppv_status = levelData.previousData !== null ? levelData.previousData?.ppv_status == 1 ? true : false : false;
  const isLevel_ppv_limitName = levelData.previousData !== null ? levelData.previousData?.ppv_value == "No Limit" ? "No Limit" : "Limit" : "No Limit";
  const isLevel_ppv_inputName = levelData.previousData !== null ? levelData.previousData?.ppv_value == "No Limit" ? "No Limit" : levelData.previousData?.ppv_value : "";

  const initialvalues = {
    alias_of_level: selectedLeveleBtn === "add" ?  isLevel_alias_of_level  :  isLevelEditData?.id ? isLevelEditData?.alias_of_level : "",
    accumulate_spending: selectedLeveleBtn === "add" ?  isLevel_accumulate_spending  : isLevelEditData?.id ? isLevelEditData?.accumulate_spending : "",
    badgeVal: selectedLeveleBtn === "add" ? isLevel_badgeVal : isLevelEditData?.id ? isLevelEditData?.badge : "",
    description_level : selectedLeveleBtn === "add" ?  isLevel_description_level : isLevelEditData?.id ? isLevelEditData?.description_level: "", 
    accessContentData: [
      {
        type: "fullAccess",
        name: "full access",
        mainToogle: selectedLeveleBtn === "add" ? isLevel_full_access  : isLevelEditData.id ? isLevelEditData.full_access == 1 ? true : false : false,
      },

      {
        type: "posts",
        name: "posts",
        mainToogle: selectedLeveleBtn === "add" ?   isLevel_post_status   : isLevelEditData.id ? isLevelEditData.post_status == 1? true : false : false,
        limitName: selectedLeveleBtn === "add" ?  isLevel_post_limitName : isLevelEditData.id ? isLevelEditData.post_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName:  selectedLeveleBtn === "add" ? isLevel_post_inputName : isLevelEditData.id ? isLevelEditData.post_value == "No Limit" ? "No Limit" : isLevelEditData.post_value : "",
        inputValueName : "post_value"
      },

      {
        type: "liveStreaming",
        name: "live streaming",
        mainToogle: selectedLeveleBtn === "add" ? isLevel_live_streming_status : isLevelEditData.id ? isLevelEditData.live_streming_status == 1? true : false : false,
        limitName:  selectedLeveleBtn === "add" ? isLevel_live_streming_limitName : isLevelEditData.id ? isLevelEditData.live_streming_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName: selectedLeveleBtn === "add" ? isLevel_live_streming_inputName   :isLevelEditData.id ? isLevelEditData.live_streming_value == "No Limit" ? "No Limit" : isLevelEditData.live_streming_value : "",
        inputValueName : "live_streming_value"
      },

      {
        type: "ticketedEvent",
        name: "ticket event",
        mainToogle: selectedLeveleBtn === "add" ?  isLevel_ticked_status  : isLevelEditData.id ? isLevelEditData.ticked_status == 1 ? true : false : false,
        limitName: selectedLeveleBtn === "add" ?  isLevel_ticked_limitName  :  isLevelEditData.id ? isLevelEditData.ticked_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName: selectedLeveleBtn === "add" ?  isLevel_ticked_inputName  :  isLevelEditData.id ? isLevelEditData.ticked_value == "No Limit" ? "No Limit" : isLevelEditData.ticked_value : "",
        inputValueName : "ticked_value" 
      },
      {
        type: "proposeCall",
        name: "propose call",
        mainToogle: selectedLeveleBtn === "add" ?  isLevel_propose_status : isLevelEditData.id ? isLevelEditData.propose_status == 1 ? true : false : false,
        limitName: selectedLeveleBtn === "add" ?  isLevel_propose_limitName  : isLevelEditData.id ? isLevelEditData.propose_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName: selectedLeveleBtn === "add" ? isLevel_propose_inputName  : isLevelEditData.id ? isLevelEditData.propose_value == "No Limit" ? "No Limit" : isLevelEditData.propose_value : "",
        inputValueName : "propose_value"
      },

      {
        type: "bidCall",
        name: "bid call",
        mainToogle: selectedLeveleBtn === "add" ? isLevel_bid_status  : isLevelEditData.id ? isLevelEditData.bid_status == 1 ? true : false : false,
        limitName: selectedLeveleBtn === "add" ? isLevel_bid_limitName   : isLevelEditData.id ? isLevelEditData.bid_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName: selectedLeveleBtn === "add" ?  isLevel_bid_inputName : isLevelEditData.id ? isLevelEditData.bid_value == "No Limit" ? "No Limit" : isLevelEditData.bid_value : "",
        inputValueName : "bid_value"
      },

      {
        type: "sendMessage",
        name: "send message",
        mainToogle: selectedLeveleBtn === "add" ?  isLevel_send_msg_status  : isLevelEditData.id ? isLevelEditData.send_msg_status == 1 ? true : false : false,
        limitName:  selectedLeveleBtn === "add" ?  isLevel_send_msg_limitName: isLevelEditData.id ? isLevelEditData.send_msg_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName:  selectedLeveleBtn === "add" ?  isLevel_send_msg_inputName  :  isLevelEditData.id ? isLevelEditData.send_msg_value == "No Limit" ? "No Limit" : isLevelEditData.send_msg_value : "",
        inputValueName : "send_msg_value"
      },

      {
        type: "ppvRequest",
        name: "ppv request",
        mainToogle: selectedLeveleBtn === "add" ?  isLevel_ppv_status  : isLevelEditData.id ? isLevelEditData.ppv_status == 1 ? true : false : false,
        limitName: selectedLeveleBtn === "add" ?  isLevel_ppv_limitName  : isLevelEditData.id ? isLevelEditData.ppv_value == "No Limit" ? "No Limit" : "Limit" : "No Limit",
        inputName: selectedLeveleBtn === "add" ?  isLevel_ppv_inputName  :  isLevelEditData.id ? isLevelEditData.ppv_value == "No Limit" ? "No Limit" : isLevelEditData.ppv_value : "",
        inputValueName : "ppv_value"
      },
    ],
  };

  const handleChangeBadge = (e, setFieldValue) => {
    const file = e.target.files[0];

    if (file) {
        dispatch(subscriptionBadgeStart({ file: file, setFieldValue: setFieldValue ,  level_id : isLevelEditData.id ? isLevelEditData.id : null }) );
    }
  };

  const handleChangeTextarea = (e) => {

    setTextAreaValue(e.target.value)
  }



  return (
    <Modal
      show={isAddLevelModalOpen}
      onHide={handleAddLModalClose}
      backdrop="static"
      keyboard={false}
      centered
      className={`${
        localStorage.getItem("theme") !== "" &&
        localStorage.getItem("theme") !== null &&
        localStorage.getItem("theme") !== undefined &&
        localStorage.getItem("theme") === "dark"
          ? "dark-theme-modal"
          : ""
      }
        `}
    >
      <Formik
        initialValues={initialvalues}
        validationSchema={addSubLevelSchema}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};
          if (
            values?.accessContentData?.filter((ele) => ele.mainToogle === true)
              .length <= 0
          ) {
            errors.accessContentData = "Select atleast one switch";
          }
          
          return errors;
        }}
        onSubmit={(values) => {
          

          if(selectedLeveleBtn === "add" && levelData.previousData !== null){

                if(values.alias_of_level === levelData.previousData.alias_of_level){
              const notificationMessage = getErrorNotificationMessage(`cannot set the same level list name`);
              dispatch(createNotification(notificationMessage));
              }else if(values.accumulate_spending <= levelData.previousData.accumulate_spending){
              const notificationMessage = getErrorNotificationMessage(`please set price above ${levelData.previousData.accumulate_spending}`);
              dispatch(createNotification(notificationMessage));
              }else if(values.accessContentData[1].limitName !== "No Limit" && values.accessContentData[1].inputName !== "No Limit" &&  values.accessContentData[1].inputName <= levelData.previousData?.post_value){
              const notificationMessage = getErrorNotificationMessage(`set post limit above ${levelData.previousData?.post_value}`);
              dispatch(createNotification(notificationMessage));
              }else if(values.accessContentData[2].limitName !== "No Limit" && values.accessContentData[2].inputName !== "No Limit" && values.accessContentData[2].inputName <= levelData.previousData?.live_streming_value){
                const notificationMessage = getErrorNotificationMessage(`set live streaming limit above ${levelData.previousData?.live_streming_value}`);
                dispatch(createNotification(notificationMessage));
               }else if(values.accessContentData[3].limitName !== "No Limit" && values.accessContentData[3].inputName !== "No Limit" && values.accessContentData[3].inputName <= levelData.previousData?.ticked_value){
                const notificationMessage = getErrorNotificationMessage(`set ticket event limit above ${levelData.previousData?.ticked_value}`);
                dispatch(createNotification(notificationMessage));
                
              }else if(values.accessContentData[4].limitName !== "No Limit" && values.accessContentData[4].inputName !== "No Limit" && values.accessContentData[4].inputName <= levelData.previousData?.propose_value){
                const notificationMessage = getErrorNotificationMessage(`set propose call limit above ${levelData.previousData?.propose_value}`);
                dispatch(createNotification(notificationMessage));
              }else if(values.accessContentData[5].limitName !== "No Limit" && values.accessContentData[5].inputName !== "No Limit" && values.accessContentData[5].inputName <= levelData.previousData?.bid_value){
                const notificationMessage = getErrorNotificationMessage(`set bid a call limit above ${levelData.previousData?.bid_value}`);
                dispatch(createNotification(notificationMessage));
                
              }else if(values.accessContentData[6].limitName !== "No Limit" && values.accessContentData[6].inputName !== "No Limit" && values.accessContentData[6].inputName <= levelData.previousData?.send_msg_value){
            
                const notificationMessage = getErrorNotificationMessage(`set send message limit above ${levelData.previousData?.send_msg_value}`);
                dispatch(createNotification(notificationMessage));
                
              }else if(values.accessContentData[7].limitName !== "No Limit" && values.accessContentData[7].inputName !== "No Limit" && values.accessContentData[7].inputName <= levelData.previousData?.ppv_value){
            
                const notificationMessage = getErrorNotificationMessage(`set PPV request limit above ${levelData.previousData?.ppv_value}`);
                dispatch(createNotification(notificationMessage));
              }else {

                const filterValue = {
                  alias_of_level: values.alias_of_level,
                  accumulate_spending: values.accumulate_spending,
                  level_id: selectedLeveleBtn === "add" && levelData.previousData !== null  ?   subLevelData?.data?.level_id :  isLevelEditData.id ? isLevelEditData.id : subLevelData?.data?.level_id,
                  description_level : document.getElementById('description_level_id').value,
                  full_access : values.accessContentData.find((ele)=> ele.type === "fullAccess").mainToogle === true ? 1 : 0,
                  post_status : values.accessContentData.find((ele)=> ele.type === "posts").mainToogle === true ? 1 : 0,
                  live_streming_status : values.accessContentData.find((ele)=> ele.type === "liveStreaming").mainToogle === true ? 1 : 0,
                  ticked_status : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").mainToogle === true ? 1 : 0,
                  propose_status : values.accessContentData.find((ele)=> ele.type === "proposeCall").mainToogle === true ? 1 : 0,
                  bid_status : values.accessContentData.find((ele)=> ele.type === "bidCall").mainToogle === true ? 1 : 0,
                  send_msg_status : values.accessContentData.find((ele)=> ele.type === "sendMessage").mainToogle === true ? 1 : 0,
                  ppv_status : values.accessContentData.find((ele)=> ele.type === "ppvRequest").mainToogle === true ? 1 : 0,
                  post_value : values.accessContentData.find((ele)=> ele.type === "posts").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "posts").inputName,
                  live_streming_value : values.accessContentData.find((ele)=> ele.type === "liveStreaming").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "liveStreaming").inputName,
                  ticked_value : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").inputName,
                  propose_value : values.accessContentData.find((ele)=> ele.type === "proposeCall").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "proposeCall").inputName,
                  bid_value : values.accessContentData.find((ele)=> ele.type === "bidCall").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "bidCall").inputName,
                  send_msg_value : values.accessContentData.find((ele)=> ele.type === "sendMessage").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "sendMessage").inputName,
                  ppv_value : values.accessContentData.find((ele)=> ele.type === "ppvRequest").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "ppvRequest").inputName,
                };
    
                handleAddLModalClose();
                setTimeout(()=>{
                  handleAddSubLModalOpen(filterValue);
                }, 400)

              }

            }  else{

              const filterValue = {
              alias_of_level: values.alias_of_level,
              accumulate_spending: values.accumulate_spending,
              level_id: selectedLeveleBtn === "add" && levelData.previousData !== null ?  subLevelData?.data?.level_id : isLevelEditData.id ? isLevelEditData.id : subLevelData?.data?.level_id,
              description_level : document.getElementById('description_level_id').value,
              full_access : values.accessContentData.find((ele)=> ele.type === "fullAccess").mainToogle === true ? 1 : 0,
              post_status : values.accessContentData.find((ele)=> ele.type === "posts").mainToogle === true ? 1 : 0,
              live_streming_status : values.accessContentData.find((ele)=> ele.type === "liveStreaming").mainToogle === true ? 1 : 0,
              ticked_status : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").mainToogle === true ? 1 : 0,
              propose_status : values.accessContentData.find((ele)=> ele.type === "proposeCall").mainToogle === true ? 1 : 0,
              bid_status : values.accessContentData.find((ele)=> ele.type === "bidCall").mainToogle === true ? 1 : 0,
              send_msg_status : values.accessContentData.find((ele)=> ele.type === "sendMessage").mainToogle === true ? 1 : 0,
              ppv_status : values.accessContentData.find((ele)=> ele.type === "ppvRequest").mainToogle === true ? 1 : 0,
              post_value : values.accessContentData.find((ele)=> ele.type === "posts").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "posts").inputName,
              live_streming_value : values.accessContentData.find((ele)=> ele.type === "liveStreaming").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "liveStreaming").inputName,
              ticked_value : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "ticketedEvent").inputName,
              propose_value : values.accessContentData.find((ele)=> ele.type === "proposeCall").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "proposeCall").inputName,
              bid_value : values.accessContentData.find((ele)=> ele.type === "bidCall").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "bidCall").inputName,
              send_msg_value : values.accessContentData.find((ele)=> ele.type === "sendMessage").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "sendMessage").inputName,
              ppv_value : values.accessContentData.find((ele)=> ele.type === "ppvRequest").inputName === "" ? "No Limit" : values.accessContentData.find((ele)=> ele.type === "ppvRequest").inputName,
            };

            handleAddLModalClose();
            setTimeout(()=>{
              handleAddSubLModalOpen(filterValue);
            }, 400)

          }


          // handleAddLModalClose();
          // setTimeout(()=>{
          //   handleAddSubLModalOpen(filterValue);
          // }, 400)
        }}
      >
        {({
          handleChange,
          values,
          setValues,
          setFieldValue,
          errors,
          handleSubmit,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{isLevelEditData?.id ? "Edit Subscription level" : 'Add Subscription level'} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              {
                levelData.data.length > 0 && selectedLeveleBtn === "add" && (
              <div className="inherit-level-data-container">
                  <label style={{ fontSize: "13px" }} htmlFor="inherit-level-id">
                    Inherit level
                  </label>

  
                  <select
                     value={selectedInheritValue}
                     name="inherit-level"
                     id="inherit-level-id"
                     onChange={handleSelectInherit}
                     >
                    {
                      levelData.data.map((ele)=>{
                        return  <option value={ele.alias_of_level}>{ele.alias_of_level}</option>
                      })
                    }
                  </select>

              </div>

                )
              }
              

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <div
                  className="inputLevel-label-wrapper"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0px",
                    width: "50%",
                  }}
                >
                  <label style={{ fontSize: "13px" }} htmlFor="subLevelId">
                    Alias of this level{" "}
                    <span style={{ color: "red", fontSize: "15px" }}>
                      &#42;
                    </span>
                  </label>
                  {
                    selectedLeveleBtn === "add"
                    ? (
                      <input
                        style={{
                          fontSize: "13px",
                          border: "1px solid #d2d6de",
                          borderRadius: "3px",
                        }}
                        type="text"
                        name="alias_of_level"
                        id="subLevelId"
                        onChange={handleChange}
                        value={values.alias_of_level}
                      />
                    ) : (
                      <input
                        style={{
                          fontSize: "13px",
                          border: "1px solid #d2d6de",
                          borderRadius: "3px",
                        }}
                        type="text"
                        name="alias_of_level"
                        id="subLevelId"
                        onChange={handleChange}
                        disabled={values.alias_of_level === "Level 0"}
                        value={values.alias_of_level}
                      />
                    )
                  }
                  

                  {errors.alias_of_level && touched.alias_of_level && (
                    <p style={{ color: "red" }}>{errors.alias_of_level}</p>
                  )}
                </div>
                <div
                  className="inputLevel-label-wrapper"
                  style={{
                    width: "50%",
                  }}
                >
                  <label style={{ fontSize: "13px" }} htmlFor="subAmount">
                    Accumulate spending coins
                    <span style={{ color: "red", fontSize: "15px" }}>
                      &#42;
                    </span>
                  </label>
                  {
                    selectedLeveleBtn === "add"
                    ? (
                      <input
                        style={{
                          fontSize: "13px",
                          border: "1px solid #d2d6de",
                          borderRadius: "3px",
                          width: "100%",
                        }}
                        type="number"
                        name="accumulate_spending"
                        onChange={handleChange}
                        value={values.accumulate_spending}
                        id="subAmount"
                      />
                    ) : (
                      <input
                        style={{
                          fontSize: "13px",
                          border: "1px solid #d2d6de",
                          borderRadius: "3px",
                          width: "100%",
                        }}
                        type="number"
                        name="accumulate_spending"
                        onChange={handleChange}
                        disabled={values.alias_of_level === "Level 0"}
                        value={values.accumulate_spending}
                        id="subAmount"
                      />
                    )
                  }
                  
                  {errors.accumulate_spending &&
                    touched.accumulate_spending && (
                      <p style={{ color: "red" }}>
                        {errors.accumulate_spending}
                      </p>
                    )}
                </div>
              </div>

              <div className="perks-wrapper">
                {/* <h3 style={{ marginTop: "10px" }}>Perks</h3> */}

                <div className="perks-badge-wrapper">
                  <p style={{ fontSize: "13px", fontWeight: "700" }}>
                    Badge
                    <span style={{ color: "red", fontSize: "15px" }}>
                      &#42;
                    </span>
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>

                    {subLevelData?.data?.file ? (
                    
                        <img
                          style={{ height: "110px", width: "110px" }}
                          src={ subLevelData?.data?.file}
                          alt="badge-img"
                        />

                    ) : (

                      levelData.previousData !== null && selectedLeveleBtn === "add" ? (

                          levelData.previousData !== null && selectedLeveleBtn === "add" ?  <img
                          style={{ height: "110px", width: "110px" }}
                          src={levelData.previousData?.badge ? levelData.previousData?.badge : ""}
                          alt="badge-img"
                        />     : 
                            
                         null
                             
                      ) : 
                      

                      selectedLeveleBtn === "edit" && isLevelEditData?.id ? (
                        <img
                          style={{ height: "110px", width: "110px" }}
                          src={isLevelEditData?.id ? isLevelEditData?.badge : ""}
                          alt="badge-img"
                        />
                      ) : null
  
                    )}

                    <label
                      htmlFor="perk-badge-id"
                      style={{
                        fontSize: "11px",
                        background: "#6661e7",
                        color: "#fff",
                        padding: "8px 30px",
                        borderRadius: "5px",
                        alignSelf: "end",
                      }}
                    >
                      Browse
                    </label>
                    <input
                      type="file"
                      name="perk-badge"
                      onChange={(e) => handleChangeBadge(e, setFieldValue)}
                      id="perk-badge-id"
                      accept=".gif, .png, .jpg, .jpeg"
                      style={{ display: "none" }}
                    />
                  </div>
                  {errors.badgeVal && touched.badgeVal && (
                    <p style={{ color: "red" }}>{errors.badgeVal}</p>
                  )}
                </div>

                <div className="perks-access-content-wrapper">
                  <p
                    style={{
                      fontSize: "13px",
                      fontWeight: "700",
                      marginTop: "10px",
                    }}
                  >
                    Access to content
                    <span style={{ color: "red", fontSize: "15px" }}>
                      &#42;
                    </span>
                  </p>

                  {errors.accessContentData && touched.accessContentData && (
                    <p style={{ color: "red" }}>{errors.accessContentData}</p>
                  )}

                  <div className="access-content-switch-container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      {accessContentData.map((accessT, index) => {
                        return (
                          <AccessContentW
                            values={values}
                            setValues={setValues}
                            accessContentData={accessContentData}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            key={accessT.id}
                            accessT={accessT}
                            levelData={levelData}
                            levelsListData={allSubLevelList.data.data}
                            selectedLeveleBtn={selectedLeveleBtn}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                  
                  <div className="level-description-wraper">
                         
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "7px"
                    }}
                  >
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "700",
                          marginTop: "10px",
                        }}
                      >Description level (optional)
                      </p>
                    {/* <span style={{ color: "red", fontSize: "15px" }}>
                      &#42;
                    </span> */}
                    {textAreaValue &&
                      <button
                         type="button" 
                         onClick={() => {
                          textAreaValue !== "" && setTextAreaValue("")
                          const textValue = document.getElementById('description_level_id')

                          if (textValue) {
                            textValue.value = "";
                          }
                         }}
                         style={{
                          fontSize: "11px",
                          background: "#6661e7",
                          color: "#fff",
                          padding: "8px 30px",
                          borderRadius: "5px",
                          border: "none",
                          fontWeight: "700"
                        }}
                      >
                        Discard Changes
                      </button>
                    }
                  </div>


                                  <textarea

                                      id="description_level_id"
                                      // className="form-control"
                                      placeholder={"Enter description..."}
                                      name="description_level"
                                      style={{border: "1px solid rgb(210, 214, 222)", padding: "8px", width: "100%", height: '70px', outline: "none" , fontSize:"12px"}}
                                      value={
                                        textAreaValue === "" 
                                          ?
                                          values.alias_of_level !== "" && values.accumulate_spending !== "" 
                                            ? [
                                              `==> Spend ${values.accumulate_spending} coin and get ${values.alias_of_level} benefit <==`,
                                              ...values.accessContentData.filter(input => input.mainToogle)
                                              .map((input,i) => {
                                                if (input.limitName == "Limit") {
                                                  return `${i+1}. get limited ${input.inputName} free ${input.name}`
                                                } else {
                                                  return `${i+1}. get no limit ${input.name}`
                                                }
                                              })
                                              ].join('\n')
                                            : 
                                            null
                                          : 
                                          textAreaValue
                                      }
                                      onChange={handleChangeTextarea}
                                  >{
                                    textAreaValue === "" 
                                      ?
                                      values.alias_of_level !== "" && values.accumulate_spending !== "" 
                                        ? [
                                          `==> Spend ${values.accumulate_spending} coin and get ${values.alias_of_level} benefit <==`,
                                          ...values.accessContentData.filter(input => input.mainToogle)
                                          .map((input,i) => {
                                            if (input.limitName == "Limit") {
                                              return `${i+1}. get limited ${input.inputName} free ${input.name}`
                                            } else {
                                              return `${i+1}. get no limit ${input.name}`
                                            }
                                          })
                                          ].join('\n')
                                        : 
                                        null
                                      : 
                                      textAreaValue
                                  }</textarea>


                  {/* {errors.description_level && touched.description_level && (
                    <p style={{ color: "red" }}>{errors.description_level}</p>
                  )} */}

                     
                  </div>

              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                size="lg"
                onClick={() => {
                   (subscriptionBadgeSuccess({}));
                   handleAddLModalClose()
                  }}
              >
                {/* {t("close")} */} Back
              </Button>
              <Button size="lg" className="ftr-btn" type="submit">
                {t("save")}
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddLevelModal;
