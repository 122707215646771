import { FETCH_FORMER_SUBSCRIBER_FAILURE, FETCH_FORMER_SUBSCRIBER_START, FETCH_FORMER_SUBSCRIBER_SUCCESS } from "./ActionConstant"

export function fetchFormerSubscriberStart(data) {
  return {
    type: FETCH_FORMER_SUBSCRIBER_START,
    data
  }
}

export function fetchFormerSubscriberSuccess(data) {
  return {
    type: FETCH_FORMER_SUBSCRIBER_SUCCESS,
    data,
  }
}

export function fetchFormerSubscriberFailure(error) {
  return {
    type: FETCH_FORMER_SUBSCRIBER_FAILURE,
    error,
  }
}