import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import "./Wallet.css";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import { fetchAllTransactionStart } from "../../store/actions/TransactionAction";
import WithdrawModal from "../helper/WithdrawModal";
import AddWalletAmountModal from "../Model/PaymentModal/AddWalletAmountModal";
import NoDataFound from "../NoDataFound/NoDataFound";
import WalletLoader from "../Loader/WalletLoader";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import PaymentTableModal from "./PaymentTableModal";
import ExchangeRateModel from "./ExchangeRateModel";

const Wallet = (props) => {
  const [colVal, setColValue] = useState(4);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [cancelWithdrawModal, setCancelWithdrawModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectTab, setSelectTab] = useState("earnings");
  console.log("🚀 ~ Wal ~ selectTab:", selectTab);
  const [selecteTransaction, setSelecteTransaction] = useState("all");
  console.log("🚀 ~ Wal ~ selecteTransaction:", selecteTransaction);
  const { profile_ranking } = useSelector((state) => state.users.profile.data);
  const [userRankData, setUserRankData] = useState(null);
  console.log("🚀 ~ Wal ~ userRankData:", userRankData);
  const [isExchangeModelOpen, setIsExchangeModelOpen] = useState(false);

  const closeWithdrawModal = () => {
    setWithdrawModal(false);
  };



  const { data } = useSelector((state) => state.users.profile);

  const handleOpenExchnageModel = () => {
    setIsExchangeModelOpen(true);
  };
  const handleCloseExchnageModel = () => {
    setIsExchangeModelOpen(false);
  };

  console.log(data, "Looking data inside wallet");
  const closeCancelWithdrawModal = () => {
    setCancelWithdrawModal(false);
    setIsLoading(false);
  };

  const coinFilterdCurrentBalance = Math.round(
    Number(props?.wallet?.data?.user_wallet?.remaining)
  );
  const coinFilterdAvailableBalance =
    (Math.round(Number(props?.wallet?.data?.user_wallet?.remaining)) - Math.round(Number(props?.wallet?.data?.total_hold_coin_without_format)));
  console.log(
    "🚀 ~ Wal ~ coinFilterdCurrentBalance:",
    coinFilterdCurrentBalance
  );
  console.log(
    "🚀 ~ Wal ~ coinFilterdAvailableBalance:",
    coinFilterdAvailableBalance
  );

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
    setColValue(
      configuration.get("configData.is_referral_enabled") == 1 ? 3 : 4
    );
  }, []);

  const [addWalletAmountModal, setAddWalletAmountModal] = useState(false);

  const closeAddWalletAmountModal = () => {
    setAddWalletAmountModal(false);
  };

  const handleSelectChangeTransaction = (e) => {
    setSelecteTransaction(e.target.value);
  };

  useEffect(() => {
    if (selecteTransaction === "all") {
      console.log("all called");
      props.dispatch(
        fetchAllTransactionStart({ type: "all", skip: 0, take: 12 })
      );
    } else if (selecteTransaction === "expenditure") {
      console.log("expenditure called");
      props.dispatch(
        fetchAllTransactionStart({ type: "expenditure", skip: 0, take: 12 })
      );
    } else {
      console.log("earnings called");
      props.dispatch(
        fetchAllTransactionStart({ type: "earnings", skip: 0, take: 12 })
      );
    }
  }, [selecteTransaction]);

  useEffect(() => {
    const findUserRankData = configuration
      .get("configData.dimond_conversional")
      ?.find((ele) => {
        return (
          Number(profile_ranking) >= Number(ele.form_level) &&
          Number(profile_ranking) <= Number(ele.to_level)
        );
      });

    setUserRankData(
      findUserRankData ? Number(findUserRankData.dimond_conversion) : Number(25)
    );
  }, [profile_ranking]);

  return (
    <>
      <div className="wallet-sec">
        {props.wallet.loading ? (
          <WalletLoader></WalletLoader>
        ) : (
          <Container>
            <Row>
              <Col sm={12} md={12}>
                <div className="wallet-header-sec">
                  <Row style={{ alignItems: "center", gap: "16px" }}>
                    {/* <Col sm={12} md={6} xl={3}>
											<div className="wallet-header-card">
												<Image
														src={
															window.location.origin +
															"/assets/images/icons/wallet-active.svg"
														}
														className="credit-img"
													/>
												<div className="wallet-header-details">
													<h5>{t("total_balance")}</h5>
													<h3>
														{props.wallet.data.user_wallet.total_formatted}
													</h3>
												</div>
											</div>
										</Col> */}

                    <div
                      className="coin_diamond_wallet_container"
                      style={{
                        display: "flex",
                        gap: "19px",
                        alignItems: "center",
                      }}
                    >
                      <div className="coin_wallet_contrainer">
                        <h3 style={{ color: "#404040" }}>Coins</h3>

                        <div className="coin_wallet_wrapper">
                          {/* <Col sm={12} md={6} xl={3}> */}
                          <div className="wallet-header-card">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/icons/wallet-dollar.svg"
                              }
                              className="credit-img"
                            />
                            <div className="wallet-header-details">
                              {/* <h5>{t("wallet_balance")}</h5> */}
                              <h5>Wallet</h5>
                              <h3>
                                {
                                  props.wallet.data.user_wallet
                                    .remaining_formatted
                                }
                                {/* <span className="amount-decimal">.76500293</span>
				                      <span className="amount-abb">BTC</span> */}
                              </h3>
                            </div>
                          </div>
                          {/* </Col> */}
                          {data?.is_certified_content_creator === 2 &&
                          configuration.get("configData.is_referral_enabled") ==
                            1 ? (
                            //   <Col sm={12} md={6} xl={3}>
                            <div className="wallet-header-card">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/wallet-dollar.svg"
                                }
                                className="credit-img"
                              />
                              <div className="wallet-header-details">
                                {/* <h5>{t("referral_balance")}</h5> */}
                                <h5>Refferal</h5>
                                <h3>
                                  {
                                    props.wallet.data.user_wallet
                                      .referral_amount_formatted
                                  }
                                  {/* <span className="amount-decimal">.76500293</span>
					                 <span className="amount-abb">BTC</span> */}
                                </h3>
                              </div>
                            </div>
                          ) : (
                            //   </Col>
                            ""
                          )}
                          {
                            data?.is_certified_content_creator === 2 && (

                          <div className="wallet-header-card">
                            <Image
                              src={
                                window.location.origin +
                                "/assets/images/icons/wallet-dollar.svg"
                              }
                              className="credit-img"
                            />
                            <div className="wallet-header-details">
                              {/* <h5>{t("wallet_balance")}</h5> */}
                              <h5>Hold</h5>
                              <h3 style={{ display: "flex", gap: "5px" }}>
                                {props.wallet.data?.total_hold_coin}
                                <span>Coin</span>

                                {/* <span className="amount-decimal">.76500293</span>
				                      <span className="amount-abb">BTC</span> */}
                              </h3>
                            </div>
                          </div>
                            )
                          }
                        </div>
                      </div>

                      {data?.is_certified_content_creator === 2 && (
                        <div className="coin_wallet_contrainer">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h3 style={{ color: "#404040" }}>Diamonds</h3>

                            <svg
                              style={{
                                color: "rgb(102 97 231)",
                                cursor: "pointer",
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-info-circle"
                              viewBox="0 0 16 16"
                              onClick={() => {
                                // setIsDescriptionData(item);
                                // handleDiscritptionOpen();
                                handleOpenExchnageModel();
                              }}
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                            </svg>
                          </div>

                          <div className="coin_wallet_wrapper">
                            {/* <Col sm={12} md={6} xl={3}> */}
                            <div className="wallet-header-card">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/diamond.png"
                                }
                                className="credit-img"
                              />
                              <div className="wallet-header-details">
                                {/* <h5>{t("diamond_balance")}</h5> */}
                                <h5>{t("current_balance")}</h5>
                                <h3>
                                  {/* {
                                      props.wallet.data.user_wallet
                                        .remaining_formatted
                                    } */}
                                  {Math.round(
                                    Number(
                                      props.wallet.data.user_wallet.remaining
                                    )
                                  )
                                    ? Math.round(
                                        Number(
                                          props.wallet.data.user_wallet
                                            .remaining
                                        )
                                      ) > 9
                                      ? `${(
                                          coinFilterdCurrentBalance /
                                          userRankData
                                        ).toFixed(2)} Diamonds`
                                      : `${(
                                          coinFilterdCurrentBalance /
                                          userRankData
                                        ).toFixed(2)} Diamond`
                                    : `${0} Diamond`}
                                  {/* <span className="amount-decimal">.76500293</span>
                              <span className="amount-abb">BTC</span> */}
                                </h3>
                              </div>
                            </div>

                            <div className="wallet-header-card">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/images/icons/diamond_available.png"
                                }
                                className="credit-img"
                              />
                              <div className="wallet-header-details">
                                <h5>{t("available_balance")}</h5>
                                <h3>
                                  {/* {
                                      props.wallet.data.user_wallet
                                        .remaining_formatted
                                    } */}
                                  {Math.round(
                                    Number(
                                      props.wallet.data.user_wallet.remaining
                                    )
                                  )
                                    ? Math.round(
                                        Number(
                                          props.wallet.data.user_wallet
                                            .remaining
                                        )
                                      ) > 9
                                      ? `${(
                                          coinFilterdAvailableBalance /
                                          userRankData
                                        ).toFixed(2)} Diamonds`
                                      : `${(
                                          coinFilterdAvailableBalance /
                                          userRankData
                                        ).toFixed(2)} Diamond`
                                    : `${0} Diamond`}
                                  {/* <span className="amount-decimal">.76500293</span>
                              <span className="amount-abb">BTC</span> */}
                                </h3>
                              </div>
                            </div>
                          </div>
                          {/* </Col> */}
                        </div>
                      )}
                    </div>

                    <Col sm={12} md={6} xl={3}>
                      <div className="edit-save">
                        <Button
                          className="send-btn-white mb-3"
                          onClick={() => setAddWalletAmountModal(true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                            justifyContent: "center",
                            margin: "0",
                          }}
                        >
                          <img
                            style={{ height: "23px", width: "23px" }}
                            src={
                              window.location.origin +
                              "/assets/images/icons/dollarBadge.png"
                            }
                            alt="Coin badge"
                          />{" "}
                          {t("buy_coin")}
                          {/* {configuration.get("configData.is_only_wallet_payment") == 1 ? t("add_wallet_token") : t("add_wallet_amount")} */}
                        </Button>
                        {data?.is_certified_content_creator === 2
                          ? localStorage.getItem("is_content_creator") == 2 && (
                              <Button
                                className="send-btn-white"
                                disabled={
                                  (
                                    Math.round(
                                      Math.trunc(
                                        Number(
                                          props.wallet.data.user_wallet
                                            .remaining
                                        )
                                      )
                                    ) / userRankData
                                  ).toFixed(2) <
                                  Number(
                                    props.wallet.data
                                      .user_withdrawals_min_amount
                                  )
                                }
                                onClick={() => setWithdrawModal(true)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  justifyContent: "center",
                                  margin: "0",
                                }}
                              >
                                <img
                                  style={{ height: "23px", width: "23px" }}
                                  src={
                                    window.location.origin +
                                    "/assets/images/icons/diamondBadge.png"
                                  }
                                  alt="Diamond badge"
                                />{" "}
                                {t("withdraw_diamond")}
                              </Button>
                            )
                          : ""}
                        {data?.is_certified_content_creator === 2 &&
                          (
                            Math.round(
                              Math.trunc(
                                Number(props.wallet.data.user_wallet.remaining)
                              )
                            ) / userRankData
                          ).toFixed(2) <
                            Number(
                              props.wallet.data.user_withdrawals_min_amount
                            ) && (
                            <p
                              style={{
                                color: "red",
                                textAlign: "center",
                                marginLeft: "50px",
                                marginTop: "1px",
                              }}
                            >{`Min ${Number(
                              props.wallet.data.user_withdrawals_min_amount
                            )} diamond to withdraw`}</p>
                          )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
      <div className="trans-table-sec" style={{ padding: "0px" }}>
        <PaymentTableModal
          handleSelectChange={handleSelectChangeTransaction}
          setSelecteTransaction={setSelecteTransaction}
          selecteTransaction={selecteTransaction}
          setSelectTab={setSelectTab}
          selectTab={selectTab}
          certifiedCreator={data}
        />
      </div>

      <WithdrawModal
        withdrawModal={withdrawModal}
        availableBalance={coinFilterdAvailableBalance}
        closeWithdrawModal={closeWithdrawModal}
        payments={props.wallet}
        diamondConversional={userRankData}
      />
      {addWalletAmountModal ? (
        <AddWalletAmountModal
          paymentsModal={addWalletAmountModal}
          closepaymentsModal={closeAddWalletAmountModal}
          payments={props.wallet}
        />
      ) : null}

      <ExchangeRateModel
        userRankData={userRankData}
        profile_ranking={profile_ranking}
        handleClose={handleCloseExchnageModel}
        isOpen={isExchangeModelOpen}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  transaction: state.transaction.allTransaction,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Wallet);
