import React from 'react'
import { Row } from "react-bootstrap";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import UserCard from '../FansFollowing/UserCard';
import CustomListCard from './CustomListCard';

const CustomListActiveSec = (props) => {

	return (
		<>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.allUserCustomList.loading ? (
           <FollowingLoader/>
        ) : (
          <>
            <Row>
              {props.userData && props.userData.length > 0 ? (
                props.userData.map((item) => (
                  <CustomListCard 
                    user={item?.user} 
                    id={item?.id}
                    customListType={props?.pageType === "customList"? true : false}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId} 
                    isSelectedData={props?.userData}
                    isSelected={props?.isSelected}
                  />
                ))
              ) : (
                <NoDataFound></NoDataFound>
                
              )}
            </Row>
          </>
        )}
      </div>
    </>
	)
}

export default CustomListActiveSec