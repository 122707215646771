import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Modal,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
  Form,
  Image,
  InputGroup,
  FormControl,
  Media,
  Tab,
  Nav,
} from "react-bootstrap";
import "./NewHome.css";
import { Link } from "react-router-dom";
import NewFeatureStoryIndex from "./NewFeatureStoryIndex";
import NewFeedIndex from "./NewFeedIndex";
import NewFeedRightIndex from "./NewFeedRightIndex";
import {
  fetchHomeFollowingPostsStart,
  fetchHomePostsStart,
  fetchHomeSubscribingPostsStart,
  fetchMoreHomePostsStart,
  fetchTrendingUsersStart,
  searchUserStart,
} from "../../store/actions/HomeAction";
import useInfiniteScroll from "../helper/useInfiniteScroll";
import {
  fetchCommentRepliesStart,
  fetchCommentsStart,
  fetchMoreCommentRepliesStart,
  fetchMoreCommentsStart,
  saveCommentStart,
} from "../../store/actions/CommentsAction";
import { saveBookmarkStart } from "../../store/actions/BookmarkAction";
import { connect, useSelector } from "react-redux";
import { translate, t } from "react-multi-lang";
import CommonCenterLoader from "../Loader/CommonCenterLoader";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import NewHomeSearch from "./NewSingleView/NewHomeSearch";
import HomeLoader from "../Loader/HomeLoader";
import NoDataFound from "../NoDataFound/NoDataFound";
import NewFeedDisplayCard from "./NewFeedDisplayCard";
import InfiniteScroll from "react-infinite-scroll-component";
import NewFeedSuggestionCard from "./NewFeedSuggestionCard";
import NewFeedTrendingCard from "./NewFeedTrendingCard";
import configuration from "react-global-configuration";
import Skeleton from "react-loading-skeleton";
import PostAddCommentModal from "../Home/PostAddCommentModal";
import PostForwardModel from "./PostForwardModel";
import "./postForward.css";
import { Multiselect } from "multiselect-react-dropdown";

const subscriberFilters = [
  {
    name: "All",
    id: 2,
    label: "All",
    payload: "all_creator",
  },
  { name: "Favourite", label: "Favourite", payload: ""},
  // { name: "Creator", id: 5, label: "Creator", payload: "creator" },
  // { name: "Star creator", label: "Star creator", payload: "" },
  {
    name: "Certified creator",
    id: 4,
    label: "Certified creator",
    payload: "all_certified",
  },
];
const followingFilters = [
  {
    name: "All",
    id: 2,
    label: "All",
    payload: "all_creator",
  },
  { name: "Favourite", label: "Favourite", payload: ""},
  { name: "Creator", id: 5, label: "Creator", payload: "creator" },
  // { name: "Star creator", label: "Star creator", payload: "" },
  {
    name: "Certified creator",
    id: 4,
    label: "Certified creator",
    payload: "all_certified",
  },
];

const NewHomeIndex = (props) => {
  // useEffect(() => {
  //   props.dispatch(fetchHomePostsStart({
  //     skip: 0,
  //     take: 12,
  //   }));
  // }, []);

  const fetchMoreData = () => {
    props.dispatch(
      fetchMoreHomePostsStart({
        skip: props.posts.data.posts.length,
        take: 12,
      })
    );
  };

  // const fetchHomeData = () => {
  //   setTimeout(() => {
  //     if (props.posts.length !== 0) {
  //       props.dispatch(fetchHomePostsStart());
  //       setIsFetching(false);
  //     } else {
  //       setNoMoreData(true);
  //     }
  //   }, 3000);
  // };

  // useEffect(() => {
  //   const installGoogleAds = () => {
  //     const elem = document.createElement("script");
  //     elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
  //     elem.async = true;
  //     elem.defer = true;
  //     document.body.insertBefore(elem, document.body.firstChild);
  //   };
  //   installGoogleAds();
  // }, []);

  // const [isFetching, setIsFetching] = useInfiniteScroll(fetchHomeData);

  const [noMoreData, setNoMoreData] = useState(false);

  const [isOpenAddCommentModal, setIsOpenAddCommentModal] = useState(false);
  const [singlePostData, setSinglePostData] = useState({});
  const [selectedComment, setSelectedComment] = useState(null);
  const { comments, commentReplies } = useSelector((state) => state.comment);
  const [postUniqueId, setPostUniqueId] = useState("");
  const [isForwardModelOpen, setIsForwardModelOpen] = useState(false);
  const [isPostId, setIsPostId] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);

  const [sortByLatestLiked, setSortByLatestLiked] = useState("latest");
  const [sortByAscDes, setSortByAscDes] = useState("Descending");

  // const [sortOptions, setSortOptions] = useState(["latest", "Most Liked"])

  // const [sortAscDesc, setSortAscDesc] = useState(["⬇ Descending", "⬆ Ascending"]);

  // const handleLatestLikedChange = (e) => {
  //   const {value} = e.target
  //   const filterOption = sortOptions.filter(item => item !== value)

  //   setSortOptions([value, ...filterOption])
  //   setSortByLatestLiked(value)
  //   setIsShowDropDown(false);
  // }

  // const handleAscDesChange = (e) => {
  //   const {value} = e.target
  //   const filterOption = sortAscDesc.filter(item => item !== value)

  //   setSortAscDesc([value, ...filterOption])
  //   setSortByAscDes(value.split(' ')[1])
  //   setIsShowDropDown(false);
  // }
  // const ref = useRef(null)

  // const handleClick = () => ref.current?.scrollIntoView({behavior: 'smooth'});

  const handleForwardModelOpen = () => setIsForwardModelOpen(true);
  const handleForwardModelClose = () => setIsForwardModelOpen(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [isActiveTab, setIsActiveTab] = useState("all");

  const [sendTip, setSendTip] = useState(false);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const handleCloseAddCommentModal = () => {
    setIsOpenAddCommentModal(false);
  };

  const handleOpenAddCommentModal = (postData) => {
    setPostUniqueId(postData?.post_unique_id);

    setIsOpenAddCommentModal(true);

    props.dispatch(
      fetchCommentsStart({
        post_id: postData?.post_id,
        skip: 0,
        take: 4,
      })
    );

    setSinglePostData(postData);
  };

  const handleModifiyFilter = isSelectedUserCate.map((ele) => {
    return ele.payload;
  });

  useEffect(() => {
    if (isActiveTab === "all") {
      props.dispatch(
        fetchHomePostsStart({
          skip: 0,
          take: 12,
          sortBy: sortByLatestLiked,
          orderBy: sortByAscDes,
        })
      );
    } else if (isActiveTab === "subscribing") {
      props.dispatch(
        fetchHomeSubscribingPostsStart({
          skip: 0,
          take: 12,
          sortBy: sortByLatestLiked,
          orderBy: sortByAscDes,
          filter: handleModifiyFilter.toString(),
        })
      );
    } else if (isActiveTab === "following") {
      props.dispatch(
        fetchHomeFollowingPostsStart({
          skip: 0,
          take: 12,
          sortBy: sortByLatestLiked,
          orderBy: sortByAscDes,
          filter: handleModifiyFilter.toString(),
        })
      );
    } else {
      props.dispatch(
        fetchHomePostsStart({
          skip: 0,
          take: 12,
          sortBy: sortByLatestLiked,
          orderBy: sortByAscDes,
        })
      );
    }
  }, [isActiveTab, sortByLatestLiked, sortByAscDes, isSelectedUserCate]);

  return (
    <>
      <div className="new-home-page-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div style={{ display: "flex" }}>
                <div className="new-home-page-left">
                  <div className="profile-tab-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="all"
                    >
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills" className="grid-three-col">
                            <Nav.Item>
                              <Nav.Link
                                eventKey="all"
                                onClick={(event) => {
                                  setIsActiveTab("all");
                                  setIsSelectedUserCate([]);
                                  // setSortByLatestLiked("latest");
                                  // setSortByAscDes("Descending");
                                  // setSortOptions(["latest", "Most Liked"])
                                  // setSortAscDesc(["⬇ Descending", "⬆ Ascending"]);
                                  // handleClick()
                                  scrollToTop();
                                }}
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/all-post-1.svg"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("all")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="image"
                                onClick={(event) => {
                                  setIsActiveTab("subscribing");
                                  setIsSelectedUserCate([]);
                                  // setSortByLatestLiked("latest");
                                  // setSortByAscDes("Descending");
                                  // setSortOptions(["latest", "Most Liked"]);
                                  // setSortAscDesc(["⬇ Descending", "⬆ Ascending"]);
                                  // handleClick();
                                  scrollToTop();
                                }}
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/subscription.png"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("subscribing")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link
                                eventKey="video"
                                onClick={(event) => {
                                  setIsActiveTab("following");
                                  setIsSelectedUserCate([]);
                                  // setSortByLatestLiked("latest");
                                  // setSortByAscDes("Descending");
                                  // setSortOptions(["latest", "Most Liked"]);
                                  // setSortAscDesc(["⬇ Descending", "⬆ Ascending"]);
                                  // handleClick()
                                  scrollToTop();
                                }}
                              >
                                <span>
                                  <Image
                                    className="profile-post-tab-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/images/new-home/icon/add.png"
                                    }
                                  />
                                </span>
                                <span className="resp-display-none">
                                  {t("following")}
                                </span>
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
                {/* <div className="new-home-page-right">
                <NewHomeSearch desktop />
                </div> */}
              </div>
              <div className="new-home-page-box">
                <div className="new-home-page-left">
                  {/* <div className="mobile-visible">
                    <div className="new-feed-search-sec">
                      <InputGroup className="mb-0">
                        <FormControl
                          placeholder="Search"
                          aria-describedby="basic-addon2"
                          onChange={handleSearch}
                        />
                        <InputGroup.Text id="basic-addon2">
                          <Image
                            className="new-feeds-search-icon"
                            src={
                              window.location.origin +
                              "/assets/images/feed-story/search-icon.svg"
                            }
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    
                    </div>
                  </div> */}
                  <NewHomeSearch />
                  {configuration.get("configData.is_story_feature_enable") ==
                  1 ? (
                    <NewFeatureStoryIndex />
                  ) : null}
                  {/* <NewFeedIndex /> */}

                  {props.posts.loading ? (
                    <HomeLoader />
                  ) : props.posts.data.posts.length > 0 ? (
                    <>
                      <div
                        style={{
                          marginTop: "45px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {isActiveTab === "subscribing" ||
                        isActiveTab === "following" ? (
                          <Multiselect
                            name="category_ids"
                            id="selectedCategories"
                            selectedValues={isSelectedUserCate}
                            options={isActiveTab === "subscribing" ? subscriberFilters : followingFilters}
                            displayValue="name"
                            avoidHighlightFirstOption="true"
                            placeholder={t("Filter")}
                            onSelect={(selectedList) =>
                              setIsSelectedUserCate(selectedList)
                            }
                            onRemove={(selectedList) => {
                              setIsSelectedUserCate(selectedList);
                            }}
                          />
                        ) : (
                          ""
                        )}
                        {/* <Dropdown
                          className="feed-post-dropdown "
                          show={isShowDropDown}
                          onToggle={(isOpen) => setIsShowDropDown(isOpen)}
                        >
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="feed-post-dropdown-btn feed__post__dropdown__hover"
                          >
                            <span class="tooltiptext">Sort</span>
                            <Image
                              className="sort__icon"
                              src={
                                window.location.origin +
                                "/assets/images/icons/sort.svg"
                              }
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <div
                              style={{
                                padding: "15px 0 0 16px"
                              }}
                            >
                              <h3 style={{margin: 0}}>Sort by</h3>
                            </div>
                            <div className="dropdown-container">
                              <select
                                name="sortOption"
                                id="sort-dropdown"
                                className=""
                                style={{
                                  paddingLeft: "3px"
                                }}
                                onChange={handleLatestLikedChange}
                              >
                                {
                                  sortOptions.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                            <Media
                              as="li"
                              className="divider"
                            ></Media>
                            <div className="dropdown-container">
                              <select
                                name="sortOrderOption"
                                id="sort-dropdown"
                                className=""
                                onChange={handleAscDesChange}
                              >
                                {
                                  sortAscDesc.map((option, index) => (
                                    <option key={index} value={option}>
                                      {option}
                                    </option>
                                  ))
                                }
                              </select>
                            </div>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>
                      <InfiniteScroll
                        dataLength={props.posts.data.posts.length}
                        next={fetchMoreData}
                        hasMore={
                          props.posts.data.posts.length < props.posts.data.total
                        }
                        
                        loader={<HomeLoader />}
                        style={{ height: "auto", overflow: "hidden" }}
                      >
                        <div className="new-feed-sec">
                          {props.posts.data.posts.map((post, index) => (
                            <NewFeedDisplayCard
                              handleOpenPostForwardModel={
                                handleForwardModelOpen
                              }
                              setIsPostId={setIsPostId}
                              post={post}
                              key={index}
                              index={index}
                              fromType={"Home"}
                              isActiveTab={isActiveTab}
                              handleOpenAddCommentModal={
                                handleOpenAddCommentModal
                              }
                            />
                          ))}
                        </div>
                      </InfiniteScroll>
                    </>
                  ) : (
                    <NoDataFound />
                  )}

                  {isForwardModelOpen && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <PostForwardModel
                        isOpenModal={isForwardModelOpen}
                        handleCloseModal={handleForwardModelClose}
                        isPostId={isPostId}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        setInputValue={setInputValue}
                        inputValue={inputValue}
                      />
                    </div>
                  )}

                  {isOpenAddCommentModal && (
                    <PostAddCommentModal
                      isOpenModal={isOpenAddCommentModal}
                      handleCloseModal={handleCloseAddCommentModal}
                      postUniqueId={postUniqueId}
                      singlePostData={singlePostData}
                      isActiveTab={isActiveTab}
                      setSinglePostData={setSinglePostData}
                      handleForwardModelOpen={handleForwardModelOpen}
                    />
                  )}
                </div>
                {/* <div className="new-home-page-right"> */}
                {/* <div className="new-feed-right-sec"> */}
                {/* <div className="new-feed-suggestions-trending-sec"> */}
                {/* <NewFeedSuggestionCard /> */}
                {/* <NewFeedTrendingCard /> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  posts: state.home.homePost,
  searchUser: state.home.searchUser,
  trendingUsers: state.home.trendingUsers,
  postSug: state.home.postSug,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(NewHomeIndex));
