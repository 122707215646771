import React, { useState, useEffect } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

const SketchExample = (props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(props.color)

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${color }`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  useEffect(() => {
    setColor(props.color)
  },[props.color]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker )
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color) => {
    setColor(color.hex)
    props.colorChange(color.hex)
  };

    

    return (
      <div className='miz'>
        <div style={ styles.swatch } onClick={ handleClick } className='prince'>
          <div style={ styles.color } className='rwsr' />
        </div>
        { displayColorPicker ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ handleClose } className='picker-color' />
          <SketchPicker color={ color } onChange={ handleChange } className='picker-color' />
        </div> : null }

      </div>
    )
  }

export default SketchExample;