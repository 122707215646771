import React, { useEffect } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Image,
  Media,
  Dropdown,
  Modal,
} from "react-bootstrap";
import NoDataFound from "../../NoDataFound/NoDataFound";

import FollowingLoader from "../../Loader/FollowingLoader";
import RestrictedCard from "./RestrictedCard";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import { useDispatch } from "react-redux";

const RestrictActiveSec = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  return (
    <div
      role="tabpanel"
      className={
        props.activeSec === "active-sec"
          ? "tab-pane fade in active"
          : "tab-pane fade"
      }
      id="active"
    >
      {props.followers.loading ? (
        <FollowingLoader />
      ) : (
        <Row>
          {(props.userData && props.userData.length) > 0 ? (
            props.userData.map((restrict_user) => (
              <RestrictedCard
								restrict_user={restrict_user}
								key={restrict_user?.restricteduser?.ruser_id}
                restrictedType={props?.pageType === "restricted"? true : false}
                setUserData={props?.setUserData}
                setCheckedUserId={props?.setCheckedUserId}
                checkedUserId={props?.checkedUserId} 
                isSelectedData={props?.userData}
                isSelected={props?.isSelected}
							/>
            ))
          ) : (
            <NoDataFound></NoDataFound>
          )}
        </Row>
      )}
    </div>
  );
};

export default RestrictActiveSec;
