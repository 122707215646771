import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
    getSuccessNotificationMessage,
    getErrorNotificationMessage,
  } from "../../components/helper/NotificationMessage";

import { checkLogoutStatus } from "../actions/ErrorAction";
import { FETCH_SUBSCRIBERS_START, GET_USER_SUBSCRIBERS_START } from "../actions/ActionConstant";
import { fetchSubscribersFailure, fetchSubscribersSuccess, getUserSubscriberFailure, getUserSubscriberSuceess } from "../actions/SubscriberAction";
import { addUserListSuccessD } from "../actions/CustomListAction";


function* getSubscriptionLevels({payload}) {
    try { 

      const response = yield api.postMethod("user_subscriber_list");

      const followers_response = yield api.postMethod("active_followers");
     
      const friends_response = yield api.postMethod("active_friends");
      
       console.log("🚀 ~ function*getSubscriptionLevels ~ friends_response:", friends_response)
       
      console.log("🚀 ~ function*getSubscriptionLevels ~ followers_response:", followers_response)


      if (response.data?.success) {
        yield put(getUserSubscriberSuceess(response.data?.data));
  
        if(payload?.setSubListsOptions !== undefined){
  
            const filtredData = response.data?.data?.map((ele) => {
              return {value :  ele?.from_user_unique_id , label : ele?.subscription_level !== "" ?  `${ele?.from_user_unique_id}     (${ele?.subscription_level})` : ele?.from_user_unique_id , id : ele?.from_user_id , type : "subscriber" , avatar : ele?.from_user_picture ? ele?.from_user_picture : ""}
              
            })
            console.log("🚀 ~ filtredData ~ filtredData:", filtredData)
          
           const filtreFollowers = followers_response?.data?.data?.followers?.map((ele) => {
             return {value : ele?.username , label : ele?.username, id : ele?.follower_id , type : "follower" , avatar : ele?.picture ? ele?.picture : "" }
            });

            const filterFriends = friends_response?.data?.data?.friends?.map((ele)=>{
              
              return {value : ele?.user?.username , label : ele?.user?.username, id : ele?.user?.user_id , type : "friend" , avatar : ele?.user?.picture ? ele?.user?.picture : "" }
              
            });

            console.log("voo", friends_response?.data?.data?.friends);
            
            console.log("🚀 ~ filterFriends ~ filterFriends:", filterFriends);
            // const getAllSubscriberAndFollowers = [...filtredData, ...filtreFollowers];
            
            console.log("🚀 ~ filtreFollowers ~ filtreFollowers:", filtreFollowers)
      
            // let idCounts  = {}
            
            // getAllSubscriberAndFollowers.forEach(obj => {
            //      idCounts[obj.id] = (idCounts[obj.id] || 0) + 1;
            // });
            
            
          //  const filtredAllSubAndFollowerData =  getAllSubscriberAndFollowers.filter(obj => idCounts[obj.id] === 1);
           const filtredAllSubAndFollowerData =  filtreFollowers?.filter((follower)=> {
                return filtredData?.findIndex((sub) => sub.id == follower.id) == -1
           });

           const filterSubAndFriends = filterFriends?.filter((friend)=> {
             return filtredData?.findIndex((sub) => sub.id == friend.id) == -1
            });
    
            const filterdFinalFollowerData = filtredAllSubAndFollowerData?.filter((follower)=>{ 
               return filterSubAndFriends?.find((subEle)=> subEle.id == follower.id)?.id !== follower.id;
            } );
            console.log("🚀 ~ filterdFinalFollowerData ~ filterdFinalFollowerData:", filterdFinalFollowerData)
           
            
            console.log("🚀 ~ filterSubAndFriends ~ filterSubAndFriends:", filterSubAndFriends);

            console.log("🚀 ~ function*getSubscriptionLevels ~ filtredAllSubAndFollowerData:", filtredAllSubAndFollowerData)
            

            if(payload.setListGroupedOp !== undefined){
                   
                payload.setListGroupedOp(
                  {

                   accessRestrictGropOpt : [
                    {
                      label: 'Subscribers',
                      options: filtredData,
                    },
                    {
                     label: "Friends",
                     options: filterSubAndFriends,
                    },
                    {
                      label: 'Followers',
                      options: filterdFinalFollowerData,
                    },
          
                  ],

                  downloadRestrictGropOpt : [
                    {
                      label: 'Subscribers',
                      options: filtredData,
                    },
                    {
                      label: "Friends",
                      options: filterSubAndFriends,
                     },

                    {
                      label: 'Followers',
                      options: filterdFinalFollowerData,
                    },

                  ],



                  forwardRestrictGropOpt : [
                    {
                      label: 'Subscribers',
                      options: filtredData,
                    },
                    {
                      label: "Friends",
                      options: filterSubAndFriends,
                     },
                    {
                      label: 'Followers',
                      options: filterdFinalFollowerData,
                    },
                  ],


                  commentRestrictGropOpt : [
                    {
                      label: 'Subscribers',
                      options: filtredData,
                    },
                    {
                      label: "Friends",
                      options: filterSubAndFriends,
                     },
                    {
                      label: 'Followers',
                      options: filterdFinalFollowerData,
                    },
                  ],

                  leaveCommentChatRestrictGropOpt: [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ],

                  fixedDownloadGropOpt: [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ],
              
                   discountGropOpt: [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ],
              
                  fixedDiscountDownloadGropOpt: [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ],

                  votingGropOpt : [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ],

                  fixedVotingGropOpt : [
                    {
                      label: "Subscribers",
                      options: filtredData,
                    },
                    {
                      label: "Followers",
                      options: filtredAllSubAndFollowerData,
                    },
                  ]

                  }
              )
   
            }
            
            payload.setSubListsOptions(filtredData);
        }
         
        if(payload.setFileUploadStatus !== undefined){
          payload.setFileUploadStatus(true);

        }
      } 
      
    } catch (error) {

      yield put(getUserSubscriberFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
  }



function* featchAllSubscribers({payload}) {
    try { 
      
       if(payload !== undefined && payload.setIsLoadingList !== undefined){

         payload.setIsLoadingList(true);
       }
       const filterSortPayload = payload?.filterSort ? payload?.filterSort : {
        filter: '',
        sort: '',
        tag_title: '',
        tag_colour: '',
        category: "",
      }
      console.log("payload", payload);
      const response = yield api.postMethod("active_subscribers", filterSortPayload);
  
      if (response.data?.success) {
        if(payload !== undefined && payload.setIsLoadingList !== undefined){

          payload.setIsLoadingList(false);
        }

        const updatedSubscribersData = response.data?.data?.subscriber?.map((item) => {
          return {
            ...item,
            isSelected: false
          }
        })
  
        yield put(fetchSubscribersSuccess({subscriber: updatedSubscribersData, total: response.data?.data?.total}));

        if(payload !== undefined && payload.allListData !== undefined){

          const updatedData = payload.allListData.map((ele)=>{
             if(ele.list_name === payload.typeList){

              const subscriberFlagData = response?.data?.data?.subscriber.map((ele)=> {
                return {
                  ...ele,
                    customListFlag : "SubsciberFromList"
                }
              })
              
              return {
                   ...ele,
                   data : subscriberFlagData,
                   total : response?.data?.data?.total
              }
    
             }else{
              return ele;
             }
    
          })
          yield put(addUserListSuccessD(updatedData));
      
         }

      } else {
        if(payload !== undefined && payload.setIsLoadingList !== undefined){

          payload.setIsLoadingList(false);
        }
        
        yield put(fetchSubscribersFailure(response.data.error));
        const notificationMessage = getErrorNotificationMessage(
          response.data.error
        );
        yield put(checkLogoutStatus(response.data));
        yield put(createNotification(notificationMessage));
      }
    } catch (error) {
      if(payload !== undefined && payload.setIsLoadingList !== undefined){

        payload.setIsLoadingList(false);
      }

      yield put(fetchSubscribersFailure(error));
      const notificationMessage = getErrorNotificationMessage(error.message);
      yield put(createNotification(notificationMessage));
    }
}

export default function* pageSaga() {
    yield all([yield takeLatest(GET_USER_SUBSCRIBERS_START, getSubscriptionLevels)]);  
    yield all([yield takeLatest(FETCH_SUBSCRIBERS_START, featchAllSubscribers)]);  
}
