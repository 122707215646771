import React from 'react'

const LiveAccessControlSelectMain = ({handleAccessChange , accessMainValue, type , isInput , inputType , inputValue , handleInputC , isSelectDropDown, selectType, handleSelectValue , selecteValue}) => {
 
    return (
        <div style={{display: "flex" , flexDirection : "column", gap: "6px"}}>

    
        <div
        className="accessControlContainer-radio-wrapper"
        style={{
          display: "flex",
          width: isInput ? "310px" : "210px",
          justifyContent: "space-between",
          gap : isInput ? "19px" : ""
        }}
      >
        <div style={{ display: "flex", gap: isInput ? "7px" : "5px", flexDirection : "row-reverse" , alignItems : "center"}}>
          

    
          <label
            className="radio-btn"
            htmlFor={`${type}-id-1`}
            style={{ marginBottom: "0px", fontSize: "13px" }}
          >
            All
          </label>
        
    
          <input
            style={{ margin: "0px" }}
            type="radio"
            id={`${type}-id-1`}
            name={type}
            onChange={(e)=> handleAccessChange(e, type)}
            value="public"
            checked={accessMainValue === "public"}
            // disabled={
            //   values?.accessContentData[0].mainToogle ===
            //     true &&
            //   accessT?.name?.mainToogle !== "fullAccess"
            // }
            />
    
        
    
          
        </div>
        <div style={{ display: "flex", gap: "5px", flexDirection : "row-reverse", alignItems : "center" }}>
          <label
            className="radio-btn"
            htmlFor={`${type}-id-2`}
            style={{ marginBottom: "0px", fontSize: "13px" }}
          >
            Restrict user
          </label>
          <input
            style={{ margin: "0px" }}
            type="radio"
            id={`${type}-id-2`}
            name={type}
            onChange={(e)=> handleAccessChange(e, type)}
            value="restrict_user"
            checked={accessMainValue === "restrict_user"}
            // disabled={
            //   values?.accessContentData[0].mainToogle ===
            //     true &&
            //   accessT?.name?.mainToogle !== "fullAccess"
            // }
          />
        </div>


      </div>
      <div className='labelInputSelect-wrapper'>

{isSelectDropDown && accessMainValue !== "restrict_user" &&  (
   <select style={{height: "21px", fontWeight: "600", border: "1px solid silver", borderRadius: '4px'}} value={selecteValue} onChange={handleSelectValue} name={`${selectType}-1`} id={`${selectType}-id-1`}>
      <option value="free_for_vote">Free for vote</option>
      <option value="free_for_first_vote">Free for first vote</option>
      <option value="pay_for_vote">Pay for vote</option>
   </select>
 )}

      {isSelectDropDown && selecteValue !== "free_for_vote" &&  accessMainValue !== "restrict_user" && (
         <input required min={1} style={{border :"1px solid silver" , borderRadius: "4px", fontSize: "11px" , padding :"2px 7px"}} type="number" name={inputType} id={`${inputType}-id`} value={inputValue} onChange={(e)=> handleInputC(e, inputType)} />
       )} 
      </div>
      </div>
      )

}

export default LiveAccessControlSelectMain