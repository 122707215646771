import React, { useState } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import { components } from "react-select";
import { useSelector } from 'react-redux';
import { translate, t } from "react-multi-lang";
import makeAnimated from "react-select/animated";


const Option = (props) => {
	console.log("🚀 ~ Option ~ props:", props);
	
return (
	<div className="post-level-list-option-wrpapper">
		<components.Option {...props}>
				<div className="search-user-list-option-label-wrapper" style={{}}>
					 <div style={{backgroundColor: `${props.data?.color_tag_code}`, height: "10px", width: "10px", borderRadius: "50%"}}></div>
					 <label style={{marginBottom: "0px"}}>{props.label}</label>
				</div>
		</components.Option>
	</div>
);
};

const FilterSortingListUsers = ({
	isSelectedUserCate,
	setIsSelectedUserCate,
	selectedColorTags,
	setSelectedColorTags,
	sortUsers,
	setSortUsers,
	sortingToogle,
	setSortingToogle,
	usersFilters
}) => {
  // const [isSelectedUserCate, setIsSelectedUserCate] = useState([]);
  // const [selectedColorTags, setSelectedColorTags] = useState(null);
  // const [sortUsers, setSortUsers] = useState("");
	// const [sortingToogle, setSortingToogle] = useState({
  //   aToz : false,
  //   upToDown : false
  // })

	const { data: colorTagData, loading: colorTagLoader } = useSelector(
    (state) => state.customList.userAllColorTag
  );

	return (
		<div className="list-filter-search-sort-container">
		<div className="list-filter-search-container">
			{/* {
				reloading 
					? null  */}
					{/* :  */}
					<Multiselect
							name="category_ids"
							id="selectedCategories"
							selectedValues={isSelectedUserCate}
							options={usersFilters}
							displayValue="name"
							avoidHighlightFirstOption="true"
							placeholder={t("Filter")}
							onSelect={(selectedList) =>
								setIsSelectedUserCate(selectedList)
							}
							onRemove={(selectedList) => { 
								console.log("🚀 ~ ListIndex ~ selectedList:", selectedList)
								const isByColor = selectedList.some((ele)=> ele.id === 9);
								if(!isByColor){
									setSelectedColorTags(null);
								}
								setIsSelectedUserCate(selectedList);
							
							}}  
						/>
				
			{/* } */}
			{isSelectedUserCate.some((ele) => ele.id === 9) ? (
				<div style={{display: "flex", flexDirection: "column"}}>
				
				<Select
					className="basic-single"
					classNamePrefix="select"
					// defaultValue={usersFilters[0]}
					isDisabled={colorTagLoader}
					isLoading={colorTagLoader}
					isClearable={false}
					isRtl={false}
					isSearchable={false}
					components={{ Option , animatedComponents: makeAnimated()}}
					placeholder="Color tag"
					name="color"
					options={colorTagData?.map((ele) => {
						return {
							name: ele.tag_title,
							label: ele.tag_title,
							color_tag_code: ele.tag_colour_code,
						};
					})}
					onChange={(selected) => {
						setSelectedColorTags(selected);
					}}
				/>
				 
		 </div>
			) : (
				""
			)}
		</div>  

		<div className="list-filter-search-sort-container">
			<select
				name="color-tag"
				id="color-tag-id"
				className="color-tag-list-select"
				onChange={(e) => { 
					setSortUsers(e.target.value);
					setSortingToogle({
						aToz : false,
						upToDown : false
					})
				}}
			>
				<option value="">Sort</option>
				<option value="username">Name</option>
				<option value="latest">Latest</option>
			</select>

			{sortUsers !== "" ? (
				sortUsers === "username" ? (
					<>
						 {
							!sortingToogle.aToz ? (
								<img
								onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
								className="list-sort-arrow-up"
								style={{cursor : "pointer"}}
								src={
									window.location.origin +
									"/assets/images/icons/sort-up.png"
								}
								alt="arrow-up"
							/>
							) : (
								<img
								onClick={()=> setSortingToogle({ ...sortingToogle, aToz : !sortingToogle.aToz })}
								style={{cursor : "pointer"}}
								className="list-sort-arrow-down"
								src={
									window.location.origin +
									"/assets/images/icons/sort.png"
								}
								alt="arrow-down"
							/>
							)
						 }
													 
					</>
				) : (
					<>
						{
							!sortingToogle.upToDown ? (
								<img
								onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
								style={{cursor : "pointer"}}
								className="same-arrow"
								src={
									window.location.origin +
									"/assets/images/icons/down-arrow.png"
								}
								alt="arrow-down"
							/>
							 

							) : (
								<img
								onClick={()=> setSortingToogle({ ...sortingToogle, upToDown : !sortingToogle.upToDown })}
								style={{cursor : "pointer"}}
								className="same-arrow"
								src={
									window.location.origin +
									"/assets/images/icons/up-arrow.png"
								}
								alt="arrow-up"
							/>
							)
						}
					 
				 
					</>
				)
			) : (
				""
			)}
		</div>
	</div>
	)
}

export default FilterSortingListUsers