import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserCard from "../FansFollowing/UserCard";
import { translate, t } from "react-multi-lang";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import {
  Button,
  Container,
  Row,
  Col,
  Table,
  Image,
  Media,
  Dropdown,
  Modal,
} from "react-bootstrap";
import SendTipModal from "../../helper/SendTipModal";
import { deleteFavStart, saveFavStart } from "../../../store/actions/FavAction";
import {
  saveBlockUserStart,
  saveRestrictUserStart,
  saveTagUntagUserStart,
} from "../../../store/actions/UserAction";
import {
  unFollowUserStart,
  unFollowUserSubStart,
} from "../../../store/actions/FollowAction";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";

import useInfiniteScroll from "../../helper/useInfiniteScroll";
import { fetchFavStart } from "../../../store/actions/FavAction";
import { useDispatch, useSelector, connect } from "react-redux";
import { getCheckUserCustomListStart } from "../../../store/actions/CustomListAction";
import AddUserCustomUserList from "../AddUserList/AddUserCustomUserList";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import {
  addFriendRequestStart,
  removeRejectFriendRequestStart,
} from "../../../store/actions/FriendRequestAction";
import { saveChatUserStart } from "../../../store/actions/ChatAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SubscriptionPaymentModal from "../../Model/PaymentModal/SubscriptionPaymentModal";
import { subscriptionPaymentStripeStart } from "../../../store/actions/SubscriptionAction";
import ReportUserModel from "../ReportModel/ReportUserModel";
import TagUntagUser from "../TagUntagUser/TagUntagUser";

const FavouriteCard = (props) => {

	const [filterUser, setFilterUser] = useState(props?.userData);
  const [badgeStatus, setBadgeStatus] = useState(
    props?.userData.is_verified_badge
  );
  const [isShowDropDown, setIsShowDropDown] = useState(false);

  const [sendTip, setSendTip] = useState(false);
  const [blockUserStatus, setBlockUserStatus] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState("");

  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });

  const [subscrptionPayment, setPaymentModal] = useState(false);

  const [skipRender, setSkipRender] = useState(true);
  const [userUniqueId, setUserUniqueId] = useState("");
  const [showUnfollow, setShowUnfollow] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [tagUntagMode, setTagUntagMode] = useState(false);

  const handleMoveModalClose = () => setIsMoveModalOpen(false);
  const handleMoveModalOpen = () => setIsMoveModalOpen(true);

  const closeReportModeModal = () => setReportMode(false);

  const isCheckUserCustom = useSelector(
    (state) => state.customList.getCheckUserCustomList.data
  );

	const {fav_user} = props

  useEffect(() => {
    setFilterUser(props?.userData);
  }, [props?.userData]);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  const customListCount = useSelector((state) => state.home.lists.data);

  const handleStar = (e, id) => {
    e.preventDefault();

    dispatch(
      saveFavStart({
        user_id: id,
      })
    );
    const filterUserFavorites = props.userData.map((favorite) => {
      if (favorite?.fav_user?.user_id === id) {
        return {
          ...favorite,
          fav_user: {
            ...favorite.fav_user,
            is_fav_user: favorite?.fav_user?.is_fav_user == 1 ? 0 : 1,
          },
        };
      } else {
        return favorite;
      }
    });
		setTimeout(()=> {
			props.setUserData(filterUserFavorites)
		}, 10)
    // setFilterUser(filterUserFavorites);
  };

  const handleRemoveFavorite = (e, id) => {
    e.preventDefault();

    const removeFavoriteUser = props.userData.filter((favorite) => {
      return favorite?.fav_user?.user_id !== id;
    });

		setTimeout(()=> {
			props.setUserData(removeFavoriteUser)
		}, 10)
  };

  const handleBlockUser = (event, id, status) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: id,
      })
    );
  };

  const handleRestrictUser = (event, user_id) => {
    event.preventDefault();
    dispatch(
      saveRestrictUserStart({
        user_id: user_id,
      })
    );
  };

  const handleUnfollowUser = (event, id, status) => {
    event.preventDefault();
    setSubscribeStatus(status);
    if (window.confirm(t("unfollowing")))
      props.dispatch(
        unFollowUserStart({
          user_id: id,
        })
      );
  };

  const handleCheckUserList = (event) => {
    event.preventDefault();

    handleMoveModalOpen();
  };

  function handleCheckToast(event) {
    event.preventDefault();
    const notificationMessage = getSuccessNotificationMessage(
      "User is already present in all custom list"
    );
    dispatch(createNotification(notificationMessage));
  }

  const handleSetUserId = (e, id) => {
    e.preventDefault();
    setUserId(id);

    dispatch(
      getCheckUserCustomListStart({
        user_id: id,
      })
    );
  };

  const handleReportModel = (event) => {
    event.preventDefault();
    setReportMode(true);
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    dispatch(createNotification(notificationMessage));
  };

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const openSendTipModel = () => {
    setSendTip(true);
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveChatUser.loading &&
      Object.keys(props.saveChatUser.data).length > 0
    ) {
      history.push("/inbox");
    }
    setSkipRender(false);
  }, [props.saveChatUser]);

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const checkUserUniqueId = (id) => {
    setUserUniqueId(id);
  };

  const handleUnSubscriber = (event, user_id) => {
    console.log(user_id, "Checking the user id")
    event.preventDefault();
    props.dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };
  const untagColor = (props?.pagePath === "favorite" &&
  fav_user.fav_user?.tag_info &&
  Object.keys(fav_user.fav_user?.tag_info).length > 0) ? true : false

  const handleRemoveTag = (event) => {
    event.preventDefault();

    if (props?.pagePath === "favorite") {
      props.dispatch(
        saveTagUntagUserStart({
          user_id: userId,
          untag: true,
          favouriteType: props?.pagePath === "favorite" ? true : false,
          tag_colour_code: untagColor ? fav_user.fav_user?.tag_info?.colour : undefined,
          tag_title:  untagColor ? fav_user.fav_user?.tag_info?.tag_title : undefined
        })
      );
    }
  };

  const handleTagUntagModel = (event) => {
    event.preventDefault();

    setTagUntagMode(true);
  };

  const closeTagUntagModel = () => setTagUntagMode(false);

  const handleUserChecked = (e, id) => {
    const {setUserData, setCheckedUserId,checkedUserId } = props;
    const updatedUserSelected = props?.userData?.map(elem => {
      
      if (elem?.fav_user?.user_id === id) {
        return {
          ...elem,
          fav_user: {
            ...elem.fav_user,
            isSelected: e.target.checked
          }
        }
      } else {
        return elem
      }
    }) 
    
    setTimeout(() => {
      setUserData(updatedUserSelected)
    }, 20)

    if (e.target.checked === true) {
      setTimeout(() => {
        setCheckedUserId((prev) => [...prev, id])
      }, 10)
    }

    if (e.target.checked === false) {
      const filterCheckUserID = checkedUserId?.filter(elem => elem !== id)
      setTimeout(() => {
        setCheckedUserId(filterCheckUserID)
      }, 10)
    }
  }

  return (
    <Col sm={12} md={6} lg={4} xs={12} key={fav_user.fav_user.user_unique_id}>
      <div className="follower-lists">
        <div className="follower-subscription-lists">
          <div className="follower-subscription-inner">
            <Link to={`/` + fav_user.fav_user.user_unique_id}>
              <div className="follower-wrapper">
                {props?.isSelected && (
                  <input
                    type="checkbox"
                    className="tick-box"
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "8px",
                      zIndex: "1",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    checked={fav_user?.fav_user?.isSelected}
                    onChange={(e) =>
                      handleUserChecked(e, fav_user?.fav_user?.user_id)
                    }
                  />
                )}
                <div
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "absolute",
                    top: "10px",
                    right: "1px",
                    borderRadius: "5px",
                    zIndex: "1",
                    color: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0 12px",
                  }}
                >
                  <p style={{ margin: 0, fontSize: "11px" }}>
                    {fav_user?.fav_user?.user_account_type_formatted}
                  </p>
                </div>
                <Image
                  className="follower-cover"
                  src={fav_user.fav_user.cover}
                />
              </div>
            </Link>
            <div className="follower-profile-header">
              <Link to={`/` + fav_user.fav_user.user_unique_id}>
                <span className="follower-profile-avatar">
                  <Image src={fav_user.fav_user.picture} className="" />
                </span>
              </Link>
              <div className="follower-info">
                <div className="follower-profile-status">
                  <div
                    className="follower-status-text"
                    style={{ display: "none" }}
                  >
                    {t("last_seen")}
                    <span title="User Updated">
                      {fav_user.fav_user.updated}
                    </span>
                  </div>
                  <div className="follower-profile-toggle-dropdown">
                    {/* <Link to="#" className="btn dropdown-toggle btn-link">
                                        <Image
                                          src="assets/images/icons/vertical-dots.svg"
                                          className="svg-clone vertical-dots"
                                        />
                                      </Link> */}

                    <Dropdown
                      className="btn dropdown-toggle btn-link"
                      onClick={(e) =>
                        handleSetUserId(e, fav_user.fav_user.user_id)
                      }
                      id="dropdown-toggle-card-id"
                    >
                      <Dropdown.Toggle
                        className="user-dropdown-dots dropdown-toggle"
                        type="button"
                        id="dropdown-basic"
                      >
                        <Image
                          src={
                            window.location.origin +
                            "/assets/images/icons/vertical-dots-white.svg"
                          }
                          className="svg-clone vertical-dots"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        {customListCount?.total_Custom_list !==
                          Number(false) && (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) => {
                                if (isCheckUserCustom.length > 0) {
                                  handleCheckUserList(event);
                                } else {
                                  handleCheckToast(event);
                                }
                              }}
                            >
                              Move to custom list
                            </Link>
                          </Media>
                        )}

                        <Media as="li">
                          <Link
                            to="#"
                            onClick={(event) => handleReportModel(event)}
                          >
                            {t("report")}
                          </Link>
                        </Media>
                        {fav_user.fav_user?.tag_info &&
                        Object.keys(fav_user.fav_user?.tag_info).length > 0 ? (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) => handleRemoveTag(event)}
                            >
                              {/* {t("report")} */}
                              Untag
                            </Link>
                          </Media>
                        ) : (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) => handleTagUntagModel(event)}
                            >
                              {/* {t("report")} */}
                              Tag
                            </Link>
                          </Media>
                        )}
                        {fav_user.fav_user.is_friend == 1 && (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={() => {
                                dispatch(
                                  removeRejectFriendRequestStart({
                                    from: "card",
                                    type: "remove",
                                    user_id: fav_user.fav_user.user_id,
                                    pagePath: props.pagePath,
                                    activeFavories: props.favorites,
                                  })
                                );

                                setIsShowDropDown(false);

                                // props.dispatch(addFriendRequestStart({user_id : props.user.user_id}))
                              }}
                            >
                              {" "}
                              {t("Unfriend")}{" "}
                            </Link>
                          </Media>
                        )}

                        {fav_user.fav_user.is_friend == 1 ||
                        fav_user.fav_user.is_request_pending == 1 ||
                        props.page === "friendLists" ? (
                          ""
                        ) : (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={() => {
                                dispatch(
                                  addFriendRequestStart({
                                    activeFavories: props.favorites,
                                    user_id: fav_user.fav_user.user_id,
                                    // activeFollowers : activeFollowers.data,
                                    // activeFollowing : activeFollowing.data,
                                    // activeFriends : allActiveFriends.data
                                  })
                                );

                                setIsShowDropDown(false);
                              }}
                            >
                              {" "}
                              {t("add_friend")}{" "}
                            </Link>
                          </Media>
                        )}

                        <CopyToClipboard
                          text={fav_user.fav_user.share_link}
                          onCopy={onCopy}
                        >
                          <Media as="li">
                            <Link to="#"> {t("copy_link_to_profile")} </Link>
                          </Media>
                        </CopyToClipboard>

                        {blockUserStatus != "" ? (
                          blockUserStatus == "unblocked" ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(
                                    event,
                                    fav_user.fav_user.user_id,
                                    "blocked"
                                  )
                                }
                              >
                                {t("block_the_user")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(
                                    event,
                                    fav_user.fav_user.user_id,
                                    "unblocked"
                                  )
                                }
                              >
                                {t("unblock_the_user")}
                              </Link>
                            </Media>
                          )
                        ) : fav_user.fav_user.is_block_user == 1 ? (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) =>
                                handleBlockUser(
                                  event,
                                  fav_user.fav_user.user_id,
                                  "unblocked"
                                )
                              }
                            >
                              {t("unblock_the_user")}
                            </Link>
                          </Media>
                        ) : (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) =>
                                handleBlockUser(
                                  event,
                                  fav_user.fav_user.user_id,
                                  "blocked"
                                )
                              }
                            >
                              {t("block_the_user")}
                            </Link>
                          </Media>
                        )}
                        {fav_user.fav_user?.is_resticted === 0 ? (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) =>
                                handleRestrictUser(
                                  event,
                                  fav_user.fav_user.user_id
                                )
                              }
                            >
                              {t("restrict_the_user")}
                            </Link>
                          </Media>
                        ) : (
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) =>
                                handleRestrictUser(
                                  event,
                                  fav_user.fav_user.user_id
                                )
                              }
                            >
                              {t("unrestrict_the_user")}
                            </Link>
                          </Media>
                        )}

                        {/* {subscribeStatus != "" ? (
                                            subscribeStatus ==
                                            "unsubscribed" ? (
                                              <Media as="li">
                                                <Link
                                                  to={
                                                    `/` +
                                                    fav_user.fav_user
                                                      .user_unique_id
                                                  }
                                                >
                                                  {t("subscribe")}
                                                </Link>
                                              </Media>
                                            ) : (
                                              <Media as="li">
                                                <Link
                                                  to="#"
                                                  onClick={(event) =>
                                                    handleUnfollowUser(
                                                      event,
                                                      fav_user.fav_user.user_id,
                                                      "unsubscribed"
                                                    )
                                                  }
                                                >
                                                  {t("unsubscribe")}
                                                </Link>
                                              </Media>
                                            )
                                          ) : fav_user.fav_user.show_follow ? (
                                            <Media as="li">
                                              <Link
                                                to={
                                                  `/` +
                                                  fav_user.fav_user
                                                    .user_unique_id
                                                }
                                              >
                                                {t("subscribe")}
                                              </Link>
                                            </Media>
                                          ) : (
                                            <Media as="li">
                                              <Link
                                                to="#"
                                                onClick={(event) =>
                                                  handleUnfollowUser(
                                                    event,
                                                    "unsubscribed"
                                                  )
                                                }
                                              >
                                                {t("unsubscribe")}
                                              </Link>
                                            </Media>
                                          )} */}
                      </Dropdown.Menu>
                    </Dropdown>
                    {reportMode && (
                      <ReportUserModel
                        reportMode={reportMode}
                        closeReportModeModal={closeReportModeModal}
                        userId={userId}
                      />
                    )}
                    {tagUntagMode && (
                      <TagUntagUser
                        tagUntagMode={tagUntagMode}
                        closeTagUntagModel={closeTagUntagModel}
                        userId={userId}
                        favouriteType={
                          props?.pagePath === "favorite" ? true : false
                        }
                      />
                    )}
                    {isMoveModalOpen && (
                      <AddUserCustomUserList
                        isMoveModalOpen={isMoveModalOpen}
                        handleMoveModalClose={handleMoveModalClose}
                        handleMoveModalOpen={handleMoveModalOpen}
                        isCheckUserCustom={isCheckUserCustom}
                        user_id={userId}
                      />
                    )}
                  </div>
                </div>
                <div className="follower-wrapper-name">
                  <div className="follower-profile-names">
                    {/* <div className="follower-name-row">
                                      <Link
                                        to={fav_user.fav_user.user_unique_id}
                                      >
                                        <div className="follower-user-name">
                                          {fav_user.fav_user.name}{" "}
                                          {badgeStatus == 1 ? (
                                            <VerifiedBadgeNoShadow />
                                          ) : null}
                                        </div>
                                      </Link>
                                    </div> */}
                    {/* <div className="follower-name-row">
                                      <Link
                                        to={
                                          `/` + fav_user.fav_user.user_unique_id
                                        }
                                        className="g-user-realname__wrapper"
                                      >
                                        <div className="follower-user-id">
                                          @{fav_user.fav_user.username}
                                        </div>
                                      </Link>
                                    </div> */}
                    <div className="follower-name-row">
                      <Link
                        to={`/` + fav_user.fav_user.user_unique_id}
                        className="g-user-realname__wrapper"
                      >
                        <div className="follower-user-name">

                          <span style={{display: "flex", gap: "7px"}}>
                            {fav_user.fav_user.username}

                            {fav_user.fav_user.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}

                            </span>
                          
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="group-follower-btns">
                    <CopyToClipboard
                      text={fav_user.fav_user.share_link}
                      onCopy={onCopy}
                    >
                      <Button
                        type="button"
                        className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                      >
                        <Image
                          src="assets/images/icons/share.svg"
                          className="svg-clone "
                        />
                      </Button>
                    </CopyToClipboard>
                  </div>
                  {fav_user.fav_user?.is_resticted === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "15px",
                        height: "48px",
                      }}
                      className="message-icon"
                    >
                      <Media as="li">
                        <Link
                          to="#"
                          onClick={(event) =>
                            handleChatUser(event, fav_user.fav_user.user_id)
                          }
                        >
                          <span>
                            <Image
                              className="sidebar-links-icon"
                              src={
                                window.location.origin +
                                "/assets/images/new-home/icon/message2-icon.svg"
                              }
                            />
                          </span>
                        </Link>
                      </Media>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div
              className="add-to-lists-button"
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div className="swiper-favorite">
                  <Link
                    type="button"
                    className="swiper-btn-fav"
                    onClick={(event) => {
                      handleStar(event, fav_user.fav_user.user_id);
                      handleRemoveFavorite(event, fav_user.fav_user.user_id);
                    }}
                  >
                    {fav_user.fav_user.is_fav_user == 1 ? (
                      <Image
                        src="assets/images/icons/star-active.svg"
                        className="svg-clone my-p-icons"
                        width="12"
                      />
                    ) : (
                      <Image
                        src="assets/images/icons/star.svg"
                        className="svg-clone my-p-icons"
                        width="12"
                      />
                    )}
                  </Link>
                </div>
                {fav_user.fav_user?.is_friend == 1 && (
                  <div
                    style={{
                      background: "rgb(1 11 23 / 8%)",
                      padding: "0 10px",
                      borderRadius: "6px",
                    }}
                  >
                    Friend
                  </div>
                )}
              </div>
              {props?.pagePath === "favorite" &&
                fav_user.fav_user?.tag_info &&
                Object.keys(fav_user.fav_user?.tag_info).length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      padding: "0 7px",
                      margin: "0 12px",
                      background: "rgba(138, 150, 163, 0.08)",
                      borderRadius: "6px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        background: fav_user.fav_user?.tag_info?.colour,
                      }}
                    ></div>
                    <p
                      style={{
                        margin: "0",
                      }}
                    >
                      {fav_user.fav_user?.tag_info?.tag_title}
                    </p>
                  </div>
                )}
              {fav_user.fav_user?.subscription_level !== "" &&
                fav_user.fav_user?.subscription_level_badge !== "" && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      padding: "0 12px",
                    }}
                  >
                    <p
                      style={{
                        margin: "0",
                        borderRadius: "6px",
                        background: "rgba(138, 150, 163, 0.08)",
                        padding: "0 10px",
                      }}
                    >
                      {fav_user.fav_user?.subscription_level}
                    </p>
                    <Image
                      src={fav_user.fav_user?.subscription_level_badge}
                      // className="svg-clone my-p-icons"
                      // width="12"
                      style={{ width: "20px" }}
                    />
                  </div>
                )}
            </div>
            {fav_user.fav_user?.payment_info?.unsubscribe_btn_status == 0 &&
            fav_user.fav_user?.payment_info?.subscription_info
              ?.monthly_amount ? (
              <div>
                <div className="user-subscription-btn-sec">
                  <div
                    className="subscription-outline-btn"
                    onClick={(event) => {
                      subscriptionPayment(
                        event,
                        "months",
                        fav_user.fav_user?.payment_info.subscription_info
                          .monthly_amount,
                        fav_user.fav_user?.payment_info.subscription_info
                          .monthly_amount_formatted
                      );
                      checkUserUniqueId(fav_user.fav_user?.user_unique_id);
                    }}
                    style={{ fontSize: "1.0em" }}
                  >
                    {`Subscribe ${
                      fav_user.fav_user?.payment_info.subscription_info
                        .monthly_amount_formatted
                    } / ${t("month")}`}
                  </div>
                </div>
              </div>
            ) : null}
            {fav_user.fav_user?.payment_info?.unsubscribe_btn_status == 1 && (
              <>
                <div className="user-subscription-btn-sec">
                  <div
                    className="subscription-btn"
                    onClick={() => handleUnfollowModalShow()}
                    style={{ fontSize: "1em", backgroundColor: "#dc3545", border: "none"}}
                  >
                    {t("Subscribing")}
                  </div>
                </div>
								{
									showUnfollow
										&&
									<Modal
									show={showUnfollow}
									onHide={handleUnfollowModalClose}
									backdrop="static"
									keyboard={false}
									centered
									className={`${
										localStorage.getItem("theme") !== "" &&
										localStorage.getItem("theme") !== null &&
										localStorage.getItem("theme") !== undefined &&
										localStorage.getItem("theme") === "dark"
											? "dark-theme-modal"
											: ""
									}
													`}
								>
									<Modal.Header closeButton>
										<Modal.Title>{t("unsubscribe")}</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										{t("cancel_subscription_conformation")}
									</Modal.Body>
									<Modal.Footer>
										<Button
											variant="secondary"
											size="lg"
											onClick={handleUnfollowModalClose}
										>
											{t("close")}
										</Button>
										<Button
											variant="primary"
											size="lg"
											onClick={(event) =>
												handleUnSubscriber(event, fav_user.fav_user_id)
											}
										>
											{t("yes")}
										</Button>
									</Modal.Footer>
									</Modal>	
								}
                
              </>
            )}
            {fav_user.fav_user?.show_follow === 1 && (
              <div className="user-subscription-btn-sec">
                <div
                  className="subscription-btn"
                  style={{ fontSize: "1em" }}
                  onClick={(event) => {
                    if (localStorage.getItem("userId")) {
                      props.dispatch(
                        subscriptionPaymentStripeStart({
                          user_unique_id: fav_user.fav_user?.user_unique_id,
                          plan_type: "months",
                          is_free: 0,
                          pageType: "userCard",
                        })
                      );
                    } else {
                      const notificationMessage = getErrorNotificationMessage(
                        t("login_to_continue")
                      );
                      props.dispatch(createNotification(notificationMessage));
                    }
                  }}
                >
                  {t("subscribe_for_free")}
                </div>
              </div>
            )}

            {subscrptionPayment && (
              <SubscriptionPaymentModal
                paymentsModal={subscrptionPayment}
                closepaymentsModal={closePaymentModal}
                name={fav_user.fav_user?.name}
                user_unique_id={userUniqueId}
                subscriptionData={subscriptionData}
                pageType={"userCard"}
              />
            )}

            {fav_user.fav_user?.show_unfollow === 1 && (
              <div className="user-subscription-btn-sec">
                <div
                  className="subscription-btn"
                  style={{fontSize: "1em", backgroundColor: "#17a2b8", border: "none"}}
                  // onClick={() => handleUnfollowModalShow()}
                  onClick={(event) =>
                    handleUnfollow(event, fav_user.fav_user?.user_id)
                  }
                >
                  {t("following")}
                </div>
              </div>
            )}

            {fav_user.fav_user?.is_certified_content_creator === 2 && (
              <div className="lists-button-group post-icons">
                <Button
                  type="button"
                  className="btn gradient-btn gradientcolor flexAlignCenter"
                  onClick={() => setSendTip(true)}
                >
                  <Image
                    src="assets/images/icons/tip.svg"
                    className="svg-clone"
                  />
                  <span className="b-btn-text">{t("tip")}</span>
                </Button>
              </div>
            )}
            <div className="lists-button-group" style={{ display: "none" }}>
              <Link
                type="button"
                className="btn gradient-btn gradientcolor flexAlignCenter"
              >
                <Image
                  src="assets/images/icons/subscribe.svg"
                  className="svg-clone"
                />
                <span className="b-btn-text">{t("subscribed_for_free")}</span>
              </Link>
            </div>

            {sendTip && (
              <SendTipModal
                sendTip={sendTip}
                closeSendTipModal={closeSendTipModal}
                username={fav_user.fav_user.username}
                userPicture={fav_user.fav_user.picture}
                name={fav_user.fav_user.usernamee}
                post_id={null}
                user_id={fav_user.fav_user.user_id}
              />
            )}
          </div>
        </div>
      </div>
    </Col>
  );
};

const mapStateToPros = (state) => ({
  saveChatUser: state.chat.saveChatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(FavouriteCard));

// export default FavouriteCard;
