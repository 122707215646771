import React from 'react'
import { useSelector } from 'react-redux';

const AccessControlSubMain = ({handleAccessRestrictChange, accessRestrictValue, type , isDisble}) => {
  const { data } = useSelector((state) => state.users.profile);
  return (
    <div
    className="accessControlContainer-radio-wrapper"
    style={{
      display: "flex",
      width: "100%",
      rowGap: "10px",
      columnGap: "25px",
      marginTop: "7px",
      flexWrap: "wrap"
    }}
  >
  
    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-0`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Follower
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-0`}
        name={`${type}-0`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "follower")}
        value="follower"
        checked={accessRestrictValue.follower}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-1`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Following
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-1`}
        name={`${type}-1`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "following")}
        value="following"
        checked={accessRestrictValue.following}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>


    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-2`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Friends
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-2`}
        name={`${type}-2`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "friends")}
        value="friends"
        checked={accessRestrictValue.friends}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-3`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Subscriber Only
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-3`}
        name={`${type}-3`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_only")}
        value="subscriber_only"
        checked={ accessRestrictValue.subscriber_only}
        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
    {
      data?.is_certified_content_creator === 2 && (

    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-4`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        Subscriber Level
      </label>
      <input
        style={{ margin: "0px" }}
        type="checkbox"
        id={`${type}-id-4`}
        name={`${type}-4`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_level")} 
        value="subscriber_level"
        checked={accessRestrictValue.subscriber_level}

        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
      />
    </div>
      )
    }
    <div style={{ display: "flex", gap: "5px" ,flexDirection : "row-reverse" }}>
      <label
        className="radio-btn"
        htmlFor={`${type}-id-5`}
        style={{
          marginBottom: "0px",
          fontSize: "13px",
        }}
      >
        From List
      </label>
      <input
        style={{ margin: "0px"}}
        type="checkbox"
        id={`${type}-id-5`}
        name={`${type}-5`}
        onChange={(e)=> handleAccessRestrictChange(e, type, "subscriber_list")} 
        value="select_from_list"
        checked={isDisble ? true : accessRestrictValue.subscriber_list}

        // disabled={
        //   values?.accessContentData[0].mainToogle ===
        //     true &&
        //   accessT?.name?.mainToogle !== "fullAccess"
        // }
        disabled={isDisble}
      />
    </div>
  </div>
  )
}

export default AccessControlSubMain