import { ADD_CUSTOM_LIST_FAILURE, ADD_CUSTOM_LIST_START, ADD_CUSTOM_LIST_SUCCESS, ADD_USER_CUSTOM_LIST_FAILURE, ADD_USER_CUSTOM_LIST_START, ADD_USER_CUSTOM_LIST_SUCCESS, ALL_USER_CUSTOM_LIST_FAILURE, ALL_USER_CUSTOM_LIST_START, ALL_USER_CUSTOM_LIST_SUCCESS, DELETE_CUSTOM_LIST_FAILURE, DELETE_CUSTOM_LIST_START, DELETE_CUSTOM_LIST_SUCCESS, DELETE_USER_CUSTOM_LIST_FAILURE, DELETE_USER_CUSTOM_LIST_START, DELETE_USER_CUSTOM_LIST_SUCCESS, GET_CHECK_USER_CUSTOM_LIST_FAILURE, GET_CHECK_USER_CUSTOM_LIST_SUCCESS, GET_CUSTOM_LIST_FAILURE, GET_CUSTOM_LIST_SUCCESS, GET_SINGLE_SEARCH_USER_LIST_FAILURE, GET_SINGLE_SEARCH_USER_LIST_START, GET_SINGLE_SEARCH_USER_LIST_SUCCESS, GET_USER_ALL_COLOR_TAG_FAILURE, GET_USER_ALL_COLOR_TAG_START, GET_USER_ALL_COLOR_TAG_SUCCESS, GET_USER_CUSTOM_LIST_FAILURE, GET_USER_CUSTOM_LIST_START, GET_USER_CUSTOM_LIST_SUCCESS, LIST_UTILS_IS_FRIEND_REQUEST, LIST_UTILS_IS_LIST_UPDATE, SEARCH_USER_LIST_FAILURE, SEARCH_USER_LIST_START, SEARCH_USER_LIST_SUCCESS, SET_ADD_USER_VALUE_LIST_SUCCESS , POST_CUSTOM_FAVOURITE_LIST_FAILURE, POST_CUSTOM_FAVOURITE_LIST_START, POST_CUSTOM_FAVOURITE_LIST_SUCCESS,  } from "../actions/ActionConstant"

const initialState = {
	customeListUtils : {
      isList : true,
	  isFriendRequest : 0,
		isListFriendRequest: 0
	},
	customeListResponse: {
		data: {},
		loading: false,
		error: false
	},
	allCustomList: {
		data: {},
		error: false
	},
	deleteCustomList: {
		data: {},
		loading: false,
		error: false
	},
	getCheckUserCustomList: {
		data: {},
		error: false
	},
	addAllUserCustomList: {
		data: {},
		loading: false,
		error: false
	},
	getAllUserCustomList: {
		data: [],
		loading: false,
		error: false
	},
	deleteAllUserCustomList: {
		data: {},
		loading: false,
		error: false
	},

	addUser : {
		allListData : [
			// {CID : 1, list_name : "Friends" , data : []},
			// {CID : 2, list_name : "Subscribing", data : []},
			// {CID : 3, list_name : "Following", data : []},
			// {CID : 4, list_name : "Blocked", data: []},
			// {CID : 5, list_name : "Restricted", data :[]},
			// {CID : 6, list_name : "Subscribers", data :[]},
			// {CID : 7, list_name : "Followers",  data :[]},
			// {CID : 8, list_name : "Former Subscriber",  data :[]},
			// {CID : 9, list_name : "Unsubscribed",  data :[]},
			// {CID : 10, list_name : "Favorites",  data :[]},
		],

		loading : false,
		error : null
	},

	userAllColorTag: {
		data: [],
		loading: false,
		error: false
	},

	userSearchList:{
			data: {},
			loading: true,
			error: false,
			inputData: {},
			loadingButtonContent: null,
			buttonDisable: false,
	},

	singleUserListSerach: { 
		data: {},
		loading: true,
		error: false,
		inputData: {}
	},
	addUserCustomFavouriteList: {
		data: {},
		loading: false,
		error: false,
	   }
}

const CustomListReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADD_CUSTOM_LIST_START:
			return {
				...state,
				customeListResponse: {
					data: {},
					loading: true,
					error: false
				}
			}

		case ADD_CUSTOM_LIST_SUCCESS:
			return {
				...state,
				customeListResponse: {
					data: action.payload,
					loading: false,
					error: false
			  }
			}

		case ADD_CUSTOM_LIST_FAILURE:
			return {
				...state,
				customeListResponse: {
					data: {},
					loading: false,
					error: action.error
			  }
			}

		case GET_CUSTOM_LIST_SUCCESS:
			return {
				...state,
				allCustomList: {
					...state.allCustomList,
					data: action.payload,
				}
			}

		case GET_CUSTOM_LIST_FAILURE:
			 return {
				...state,
				allCustomList: {
					...state.allCustomList,
					error: action.error
				}
			 }

		case DELETE_CUSTOM_LIST_START: 
			 return {
				...state,
				deleteCustomList: {
					data: {},
					loading: true,
					error: false
				}
			 }
		
		case DELETE_CUSTOM_LIST_SUCCESS: 
			return {
				...state,
				deleteCustomList: {
					data: action.payload,
					loading: false,
					error: false
				}
			}

		case DELETE_CUSTOM_LIST_FAILURE: 
			return {
				...state,
				deleteCustomList: {
					data: {},
					loading: false,
					error: action.error
				}
			}	
			
		case GET_CHECK_USER_CUSTOM_LIST_SUCCESS:
			return {
				...state,
				getCheckUserCustomList: {
					...state.getCheckUserCustomList,
					data: action.payload
				}
			}

		case GET_CHECK_USER_CUSTOM_LIST_FAILURE:
			return {
				...state,
				getCheckUserCustomList: {
					...state.getCheckUserCustomList,
					error: action.error
				}
			}

		case ADD_USER_CUSTOM_LIST_START:
			return {
				...state,
				addAllUserCustomList: {
					data: {},
					loading: true,
					error: false
				}
			}

		case ADD_USER_CUSTOM_LIST_SUCCESS:
			return {
				...state,
				addAllUserCustomList: {
					data: action.payload,
					loading: false,
					error: false
				}
			}
	
		case ADD_USER_CUSTOM_LIST_FAILURE:
			return {
				...state,
				addAllUserCustomList: {
					data: {},
					loading: false,
					error: action.error
				}
		}

		case GET_USER_CUSTOM_LIST_START:
		return {
			...state,
			getAllUserCustomList: {
				data: [],
				loading: true,
				error: false
			}
		}

		case GET_USER_CUSTOM_LIST_SUCCESS:
			return {
				...state,
				getAllUserCustomList: {
					...state.getAllUserCustomList,
					data: action.payload,
					loading: false
				}
			}

		case GET_USER_CUSTOM_LIST_FAILURE:
			return {
				...state,
				getAllUserCustomList: {
					...state.getAllUserCustomList,
					error: action.error,
					loading: false
				}
			}

		case DELETE_USER_CUSTOM_LIST_START: 
			return {
			 ...state,
			 deleteAllUserCustomList: {
				 data: {},
				 loading: true,
				 error: false
			 }
			}
	 
	  case DELETE_USER_CUSTOM_LIST_SUCCESS: 
		 return {
			 ...state,
			 deleteAllUserCustomList: {
				 data: action.payload,
				 loading: false,
				 error: false
			 }
		 }

	  case DELETE_USER_CUSTOM_LIST_FAILURE: 
		 return {
			 ...state,
			 deleteAllUserCustomList: {
				 data: {},
				 loading: false,
				 error: action.error
			 }
		 }	

	  case SET_ADD_USER_VALUE_LIST_SUCCESS: 

		 return {
			 ...state,
			 addUser: {
				...state.addUser,
				allListData : [...action.payload]
			 }
		 }
		 
		 case ALL_USER_CUSTOM_LIST_START : 

		 return {
			...state,
			addUser : {
				...state.addUser,
				loading : true,
				error : null
			}
		 }


		 case ALL_USER_CUSTOM_LIST_SUCCESS : 

		 return {
			...state,
			addUser : {
				...state.addUser,
				allListData : [...action.payload],
				loading : false,
				error : null
			}
		 }

		 case ALL_USER_CUSTOM_LIST_FAILURE : 

		 return {
			...state,
			addUser : {
				...state.addUser,
				loading : false,
				error : action.error
			}
		 }

		 case POST_CUSTOM_FAVOURITE_LIST_START:
			 
		 	return {
				...state,
				addUserCustomFavouriteList: {
					data: {},
					loading: true,
					error: false,
				}
			}

		case POST_CUSTOM_FAVOURITE_LIST_SUCCESS: 
			
			return {
				...state,
				addUserCustomFavouriteList: {
					data: action.payload,
					loading: false,
					error: false
				}
			}

		case POST_CUSTOM_FAVOURITE_LIST_FAILURE:

			return {
				...state,
				addUserCustomFavouriteList: {
					data: {},
					loading: false,
					error: action.error
				}
			}


		 case GET_USER_ALL_COLOR_TAG_START: 

		 return {
			...state,
			userAllColorTag : {
				...state.userAllColorTag,
				loading : true,
				error : null
			}
		 }


		 case GET_USER_ALL_COLOR_TAG_SUCCESS : 

		 return {
			...state,
			userAllColorTag : {
				...state.userAllColorTag,
				data : [...action.payload],
				loading : false,
				error : null
			}
		 }

		 case GET_USER_ALL_COLOR_TAG_FAILURE : 

		 return {
			...state,
			userAllColorTag : {
				...state.userAllColorTag,
				loading : false,
				error : action.error
			}
		 }

		 case SEARCH_USER_LIST_START:
			return {
			  ...state,
			  userSearchList: {
				data: {},
				loading: true,
				error: false,
				inputData: action.data,
				loadingButtonContent: "Loading...",
				buttonDisable: true,
			  },
			};
		  case SEARCH_USER_LIST_SUCCESS:
			return {
			  ...state,
			  userSearchList: {
				data: action.data,
				loading: false,
				error: false,
				inputData: {},
				loadingButtonContent: null,
				buttonDisable: false,
			  },
			};
		  case SEARCH_USER_LIST_FAILURE:

		  return {
			...state,
			userSearchList: {
			  data: {},
			  loading: false,
			  error: action.error,
			 
			  loadingButtonContent: null,
			  buttonDisable: false,
			},
		  };

		 case GET_SINGLE_SEARCH_USER_LIST_START:
			return {
			  ...state,
			  singleUserListSerach: {
				data: {},
				loading: true,
				error: false,
				inputData: action.payload,
			  },
			};
		  case GET_SINGLE_SEARCH_USER_LIST_SUCCESS:
			return {
			  ...state,
			  singleUserListSerach: {
				data: action.payload,
				loading: false,
				error: false,
				inputData: {},
			  },
			};

		  case GET_SINGLE_SEARCH_USER_LIST_FAILURE:
		  return {
			...state,
			singleUserListSerach: {
			  data: {},
			  loading: false,
			  error: action.error,
			  inputData: {},
			},
		  };

		  case LIST_UTILS_IS_LIST_UPDATE:
			return {
				...state,
				customeListUtils : {
					...state.customeListUtils,
					isList : action.payload
				}
			  };

		  case LIST_UTILS_IS_FRIEND_REQUEST:
			return {
				...state,
				customeListUtils : {
					...state.customeListUtils,
					isFriendRequest : action.payload,
					isisListFriendRequest: action.payload
					}
			  };
		  
		default:
			return state
	}
}

export default CustomListReducer