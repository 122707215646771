import React, { useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import UserCard from "../UserCard";
import { useDispatch } from "react-redux";
import FollowingLoader from "../../../Loader/FollowingLoader";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import { fetchListsDetailsStart } from "../../../../store/actions/HomeAction";
import { translate } from "react-multi-lang";

const FanActiveSec = (props) => {
  const dispatch = useDispatch();
  const [sortedUserData, setSortedUserData] = useState([]);

  // Fetch list details on mount
  useEffect(() => {
    dispatch(fetchListsDetailsStart());
  }, []);

  // Sorting logic based on props.sortingToogle
  useEffect(() => {
    if (props.userData && props.userData.length > 0) {
      const sortedData = [...props.userData];
      if (props.sortingToogle?.aToz) {
        // Sort A to Z by username
        sortedData.sort((a, b) => a.otherUser?.username.localeCompare(b.otherUser?.username));
      } else if (!props.sortingToogle?.aToz) {
        // Sort Z to A by username
        sortedData.sort((a, b) => b.otherUser?.username.localeCompare(a.otherUser?.username));
      }
      if (props.sortingToogle?.upToDown) {
        // Sort latest to oldest by updated_at
        sortedData.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
      }
      setSortedUserData(sortedData);
    } else {
      setSortedUserData([]);
    }
  }, [props.userData, props.sortingToogle]);

  return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.followers.loading ? (
          <FollowingLoader />
        ) : (
          <>
            <Row>
              {sortedUserData && sortedUserData.length > 0 ? (
                sortedUserData.map((follower) => (
                  <UserCard
                    key={follower.id}
                    pagePath={props.pagePath}
                    user={follower.otherUser}
                    followersType={props.pagePath === "followers" ? true : false}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId}
                    isSelected={props?.isSelected}
                    isSelectedData={props?.userData}
                  />
                ))
              ) : (
                <NoDataFound />
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

export default translate(FanActiveSec);
