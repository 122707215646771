import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Image, Media, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { translate, t } from "react-multi-lang";
import { useHistory } from "react-router-dom";
import "../Profile/NewProfile.css";
import { fetchListsDetailsStart } from "../../../store/actions/HomeAction";
import { deleteFavStart, saveFavStart } from "../../../store/actions/FavAction";
import { unFollowUserStart, unFollowUserSubStart } from "../../../store/actions/FollowAction";
import { saveBlockUserStart, saveRestrictUserStart, saveTagUntagUserStart } from "../../../store/actions/UserAction";
import { getCheckUserCustomListStart } from "../../../store/actions/CustomListAction";
import { getErrorNotificationMessage, getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { saveChatUserStart } from "../../../store/actions/ChatAction";
import { acceptFriendRequestStart, addFriendRequestStart, removeRejectFriendRequestStart } from "../../../store/actions/FriendRequestAction";
import ReportUserModel from "../ReportModel/ReportUserModel";
import TagUntagUser from "../TagUntagUser/TagUntagUser";
import AddUserCustomUserList from "../AddUserList/AddUserCustomUserList";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import { subscriptionPaymentStripeStart } from "../../../store/actions/SubscriptionAction";
import SubscriptionPaymentModal from "../../Model/PaymentModal/SubscriptionPaymentModal";
import SendTipModal from "../../helper/SendTipModal";



const SingleListUserCard = (props) => {
  const [sendTip, setSendTip] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const [userId, setUserId] = useState("")

  const handleMoveModalClose = ()=> setIsMoveModalOpen(false);
  const handleMoveModalOpen = ()=> setIsMoveModalOpen(true);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const [addFav, setAddFav] = useState(false);
  const [favStatus, setFavStatus] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const [badgeStatus, setBadgeStatus] = useState(props.user.is_verified_badge);
  const [blockUserStatus, setBlockUserStatus] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState("");
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });
  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [tagUntagMode, setTagUntagMode] = useState(false);

  const {removeFriend , acceptFriend, allActiveFriends} = useSelector((state)=> state.friendRequest)
  const {activeFollowers , activeFollowing} = useSelector((state)=> state.follow)
  const [isId, setIsId] = useState(null);
  const [showUnfollow, setShowUnfollow] = useState(false);

  const dispatch = useDispatch()

  const isCheckUserCustom = useSelector(state => state.customList.getCheckUserCustomList.data)

  const history = useHistory()

  const customListCount = useSelector(state => state.home.lists.data)
  
  const closeAddFavModal = () => {
    setAddFav(false);
  };

  const removeFav = () => {
    props.dispatch(deleteFavStart({ user_id: props.user.user_id }));
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const handleUnSubscriber = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };

  const handleStar = (event, status) => {
    event.preventDefault();
    setFavStatus(status);
    props.dispatch(
      saveFavStart({
        user_id: props.user.user_id,
      })
    );
  };
  const handleBlockUser = (event, status) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleRestrictUser = (event) => {
    event.preventDefault();

    props.dispatch(
      saveRestrictUserStart({
        user_id: props.user.user_id
      })
    )
  }
  
  const handleSetUserId = (e,id) => {
    e.preventDefault()
    setUserId(id)
    
    dispatch(getCheckUserCustomListStart({
      user_id: id
    }))
  }

  const handleCheckUserList = (event) => {
    event.preventDefault();
    handleMoveModalOpen() 
  }

  const handleReportModel = (event) => {
    event.preventDefault()
    setReportMode(true)
  }

  function handleCheckToast(event) {
    event.preventDefault();
    const notificationMessage = getSuccessNotificationMessage("User is already present in all custom list")
    props.dispatch(createNotification(notificationMessage))
  } 

  const handleUnfollowUser = (event, status) => {
    event.preventDefault();
    setSubscribeStatus(status);
    if (window.confirm(t("unfollowing")))
      props.dispatch(
        unFollowUserStart({
          user_id: props.user.user_id,
        })
      );
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveChatUser.loading &&
      Object.keys(props.saveChatUser.data).length > 0
    ) {
      history.push("/inbox");
    }
    setSkipRender(false);
  }, [props.saveChatUser]);

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );  
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const closeReportModeModal = () => setReportMode(false);

  const handleTagUntagModel = (event) => {
    event.preventDefault();

    setTagUntagMode(true);
  }

  const handleRemoveTag = (event) => {
    event.preventDefault();


          props.dispatch(saveTagUntagUserStart({user_id: userId, untag: true, singleListUserType : "SingleListUserType" }))

  }

  const closeTagUntagModel = () => setTagUntagMode(false)

  return (
    <>
      <Col sm={12} md={6} lg={4} xs={12}>
        <div className="follower-lists">
          <div className="follower-subscription-lists">
            <div className="follower-subscription-inner">
              <Link to={`/` + props.user.user_unique_id}>
                <div className="follower-wrapper">
                  <div 
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      top: "10px",
                      right: "1px",
                      borderRadius: "5px",
                      zIndex: "1",
                      color: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px 12px",
                    }}
                  >
                    <p style={{margin: 0, fontSize: "11px"}}>{props?.user?.user_account_type_formatted}</p>
                  </div>
                  <Image className="follower-cover" src={props.user.cover} />
                </div>
              </Link>
              <div className="follower-profile-header">
                <Link to={`/` + props.user.user_unique_id}>
                  <span className="follower-profile-avatar">
                    <Image src={props.user.picture} className="" />
                  </span>
                </Link>
                <div className="follower-info">
                  <div className="follower-profile-status">
                    <div
                      className="follower-status-text"
                      style={{ display: "none" }}
                    >
                      {t("last_seen")}
                      <span title="User Updated">{props.user.updated}</span>
                    </div>
                    <div className="follower-profile-toggle-dropdown">
                      {/* <Link to="#" className="btn dropdown-toggle btn-link">
                        <Image
                          src="assets/images/icons/vertical-dots.svg"
                          className="svg-clone vertical-dots"
                        />
                      </Link> */}

                      <Dropdown
                        show={isShowDropDown}
                        onToggle={(isOpen)=> setIsShowDropDown(isOpen)}
                        
                        className="btn dropdown-toggle btn-link" 
                        onClick={(e) => handleSetUserId(e,props.user.user_id)}
                        id="dropdown-toggle-card-id"
                      >
                        <Dropdown.Toggle
                          className="user-dropdown-dots dropdown-toggle"
                          type="button"
                          id="dropdown-basic"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/icons/vertical-dots-white.svg"
                            }
                            
                            className="svg-clone vertical-dots"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                            {
                              customListCount?.total_Custom_list !== Number(false)
                                &&
                              (
                                  <Media as="li">
                                    <Link
                                      to="#"
                                      onClick={(event) => {
                                        if (isCheckUserCustom.length > 0) {
                                          handleCheckUserList(event) 
                                        } else {
                                          handleCheckToast(event)
                                        }
                                      }}
                                    >
                                      Move to custom list
                                    </Link>
                                  </Media>
                              )
                            }
                              
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => handleReportModel(event)}
                              >
                                {t("report")}
                              </Link>
                            </Media>

                            {
                              ((props.user?.tag_info && Object.keys(props.user?.tag_info).length > 0)
                               ||
                              (props?.friendExtra?.tag_info && Object.keys(props?.friendExtra?.tag_info).length > 0))
                                ?
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) => handleRemoveTag(event)}
                                >
                                  {/* {t("report")} */}
                                  Untag
                                </Link>
                              </Media>
                                :
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) => handleTagUntagModel(event)}
                                >
                                  {/* {t("report")} */}
                                  Tag
                                </Link>
                              </Media>
                            }
                          {(props.user.is_friend == 1 || props.page === "friendLists") && (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={() => {

                                  if(props.page === "friendLists"){
                                    props.dispatch(
                                      removeRejectFriendRequestStart({
                                        request_id: props?.friendListID,
                                        friendData: props?.friendData, 
                                        from : "friend",
                                        user_id : props.user.user_id,
                                        type:  "remove"
                                         })
                                     );

                                  }else{

                                  props.dispatch(
                                  removeRejectFriendRequestStart({
                                      request_id: props?.friendListID,
                                      friendData: props?.friendData, 
                                      from : "card",
                                      user_id : props.user.user_id,
                                      type:  "remove",
                                      pagePath : "SingleListUserSearch",
                                      activeFollowers : activeFollowers.data,
                                      activeFollowing : activeFollowing.data,
                                     })
                                   );
                                  }


                                  setIsShowDropDown(false)

                                
                                  // props.dispatch(addFriendRequestStart({user_id : props.user.user_id}))
                                }}
                              >
                                {" "}
                                {t("Unfriend")}{" "}
                              </Link>
                            </Media>
                          )}

                      {props.user.is_friend == 1 || props.user.is_request_pending == 1 || props.page === "friendLists" || props.page === "friendRequest" ? (
                            ""
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={() => {
                                  props.dispatch(
                                    addFriendRequestStart({
                                      user_id: props.user.user_id,
                                      fromType : "singleListUserSearch"
                                    })
                                  );

                                  setIsShowDropDown(false)
                                }}
                              >
                                {" "}
                                {t("add_friend")}{" "}
                              </Link>
                            </Media>
                          )}

                          <CopyToClipboard
                            text={props.user.share_link}
                            onCopy={onCopy}
                          >
                            <Media as="li">
                              <Link to="#"> {t("copy_link_to_profile")} </Link>
                            </Media>
                          </CopyToClipboard>


                          {blockUserStatus != "" ? (
                            blockUserStatus == "unblocked" ? (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "blocked")
                                  }
                                >
                                  {t("block_the_user")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "unblocked")
                                  }
                                >
                                  {t("unblock_the_user")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.is_block_user == 1 ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "unblocked")
                                }
                              >
                                {t("unblock_the_user")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "blocked")
                                }
                              >
                                {t("block_the_user")}
                              </Link>
                            </Media>
                          )}

                          {
                            (props.user?.is_resticted === 0
                              ||
                             props?.isRestricted === 0
                            )
                              ?
                            (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleRestrictUser(event)
                                  }
                                >
                                  {t("restrict_the_user")}
                                </Link>
                              </Media>
                            )
                              :
                            (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleRestrictUser(event)
                                  }
                                >
                                  {t("unrestrict_the_user")}
                                </Link>
                              </Media>
                            )
                          }

                          {/* {subscribeStatus != "" ? (
                            subscribeStatus == "unsubscribed" ? (
                              <Media as="li">
                                <Link to={`/` + props.user.user_unique_id}>
                                  {t("subscribe")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleUnfollowUser(event, "unsubscribed")
                                  }
                                >
                                  {t("unsubscribe")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.show_follow ? (
                            <Media as="li">
                              <Link to={`/` + props.user.user_unique_id}>
                                {t("subscribe")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleUnfollowUser(event, "unsubscribed")
                                }
                              >
                                {t("unfollow")}
                              </Link>
                            </Media>
                          )} */}
                        </Dropdown.Menu>
                      </Dropdown>
                        {
                          reportMode
                            &&
                          <ReportUserModel
                            reportMode={reportMode}
                            closeReportModeModal={closeReportModeModal}
                            userId={userId}
                          />  
                        }
                        {
                          tagUntagMode
                            &&
                          <TagUntagUser
                            tagUntagMode = {tagUntagMode}
                            closeTagUntagModel ={closeTagUntagModel}
                            userId={userId}
                            friendType={props?.friendType}
                            followingType={props?.followingType}
                            followersType={props?.followersType}
                            singleListUserType={"SingleListUserType"}
                          />
                        }
                        {
                          isMoveModalOpen
                            &&
                          <AddUserCustomUserList
                            isMoveModalOpen={isMoveModalOpen}
                            handleMoveModalClose={handleMoveModalClose}
                            handleMoveModalOpen={handleMoveModalOpen}
                            isCheckUserCustom={isCheckUserCustom}
                            user_id={userId}
                          />
                        }
                    </div>
                  </div>
                  <div className="follower-wrapper-name">
                    <div className="follower-profile-names">
                      {/* <div className="follower-name-row">
                        <Link to={props.user.user_unique_id}>
                          <div className="follower-user-name">
                            {props.user.name}{" "}
                            {badgeStatus == 1 ? (
                              <VerifiedBadgeNoShadow />
                            ) : null}
                          </div>
                        </Link>
                      </div> */}
                      {/* <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-id">
                            @{props.user.username}
                          </div>
                        </Link>
                      </div> */}
                      <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-name">
                            <span style={{display: "flex", gap: "7px"}}>
                            {props.user.username}
                            {props.user?.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}

                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="group-follower-btns">
                      <CopyToClipboard
                        text={props.user.share_link}
                        onCopy={onCopy}
                      >
                        <Button
                          type="button"
                          className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                        >
                          <Image
                            src={window.location.origin + "/assets/images/icons/share.svg"}
                            className="svg-clone"
                          />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    {
                      (props.user?.is_resticted === 0
                        ||
                       props?.isRestricted === 0)
                        ?
                      (<div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "15px",
                          height: "48px",
                        }}
                        className="message-icon"
                      >
                        <Media as="li">
                          <Link
                            to="#"
                            onClick={(event) =>
                              handleChatUser(event, props.user.user_id)
                            }
                          >
                            <span>
                              <Image
                                className="sidebar-links-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/new-home/icon/message2-icon.svg"
                                }
                              />
                            </span>
                          </Link>
                        </Media>
                      </div>)
                        : ""
                    }
                    
                  </div>
                </div>
              </div>

              {props?.isFriendC !== true ? (
                <>
                  <div className="add-to-lists-button favorite-friend" style={{justifyContent: "space-between"}}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                    <div className="swiper-favorite">
                      {favStatus !== "" ? (
                        <>
                          <>
                            {favStatus === "added" ? (
                              <Link
                                type="button"
                                className="swiper-btn-fav"
                                onClick={(event) =>
                                  handleStar(event, "removed")
                                }
                              >
                                <Image
                                  src={window.location.origin + "/assets/images/icons/star-active.svg"}
                                  className="svg-clone my-p-icons"
                                  width="12"
                                />
                                {/* {t("remove_from_favorites")} */}
                              </Link>
                            ) : null}
                          </>
                          <>
                            {favStatus === "removed" ? (
                              <Link
                                type="button"
                                className="swiper-btn-fav"
                                onClick={(event) => handleStar(event, "added")}
                              >
                                <Image
                                  src={window.location.origin + "/assets/images/icons/star.svg"}
                                  className="svg-clone my-p-icons"
                                  width="12"
                                />
                                {/* {t("add_to_favorites")} */}
                              </Link>
                            ) : null}
                          </>
                        </>
                      ) : props.user.is_fav_user == 1 || props.friendExtra?.is_fav_user == 1 ? (
                        <Link
                          type="button"
                          className="swiper-btn-fav"
                          onClick={(event) => handleStar(event, "removed")}
                        >
                          <Image
                            src={window.location.origin + "/assets/images/icons/star-active.svg"}
                            className="svg-clone my-p-icons"
                            width="12"
                          />
                          {/* {t("remove_from_favorites")} */}
                        </Link>
                      ) : (
                        <Link
                          type="button"
                          className="swiper-btn-fav"
                          onClick={(event) => handleStar(event, "added")}
                        >
                          <Image
                            src={window.location.origin + "/assets/images/icons/star.svg"}
                            className="svg-clone my-p-icons"
                            width="12"
                          />
                          {/* {t("add_to_favorites")} */}
                        </Link>
                      )}
                      
                    </div>

                    {
                      (props.user.is_friend == 1 || props.page === "friendLists")
                       &&
                       (
                        <div
                          style={{
                            background: "rgb(1 11 23 / 8%)",
                            padding: "0 10px",
                            borderRadius: "6px",
                            fontSize: "15px",
                            lineHeight: "30px",
                            color: "#7a9181"
                          }}
                        >
                          Friend
                        </div>
                       )
                    }
                    </div>
                    
                    {
                      (
                        (props?.friendType && props?.friendExtra?.tag_info && Object.keys(props?.friendExtra?.tag_info).length > 0)
                        ||
                        (props.user?.tag_info && Object.keys(props.user?.tag_info).length > 0)
                      )
                        &&
                      (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            padding: "0 7px",
                            margin: "0 12px",
                            background: "rgba(138, 150, 163, 0.08)",
                            borderRadius: "6px",
                          }}
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              borderRadius: "50%",
                              background: props?.friendExtra?.tag_info?.colour || props.user?.tag_info?.colour
                            }}
                          >
                          </div>
                          <p
                          className="certified_creator_label"
                            style={{
                              margin: "0", 
                              fontSize: "15px",
                              lineHeight: "30px",
                              color: "#7a9181"
                            }}
                          > 
                            {
                              props?.friendExtra?.tag_info?.tag_title
                                ||
                              props.user?.tag_info?.tag_title  
                            }
                          </p>
                        </div>
                      )
                    }
                    {
                      (props?.friendExtra !== null || (props?.user?.subscription_level !== ""
                       && props?.user?.subscription_level_badge !== "")) &&
                      (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "8px",
                            padding: "0 12px"
                          }}
                        >
                          <p 
                            style={{
                              margin: "0", 
                              borderRadius: "6px", 
                              background: "rgba(138, 150, 163, 0.08)",
                              padding: "0 10px"
                            }}
                          >{props?.friendExtra?.subscription_level || props?.user?.subscription_level}</p>
                          <Image
                            src={props?.friendExtra?.subscription_level_badge || props?.user?.subscription_level_badge}
                            // className="svg-clone my-p-icons"
                            // width="12"
                            style={{width: "20px"}}
                          />
                        </div>
                      )
                    }
                  </div>

                  {
                    (props?.friendExtra?.payment_info?.unsubscribe_btn_status == 0
                    || props?.user?.payment_info?.unsubscribe_btn_status == 0) && (props?.friendExtra?.payment_info?.subscription_info?.monthly_amount || props?.user?.payment_info?.subscription_info?.monthly_amount) ? (
                      <div>
                        <div className="user-subscription-btn-sec">
                          <div
                            className="subscription-outline-btn"
                            onClick={(event) =>
                              subscriptionPayment(
                                event,
                                "months",
                                props.friendExtra?.payment_info.subscription_info
                                  .monthly_amount
                                  ||
                                props?.user?.payment_info?.subscription_info?.monthly_amount
                                ,
                                props.friendExtra?.payment_info.subscription_info
                                  .monthly_amount_formatted
                                  ||
                                  props?.user?.payment_info?.subscription_info?.monthly_amount_formatted
                              )
                            }
                            style={{fontSize: "1.0em", padding: "11px 30px", fontSize: "14px"}} 
                          >
                            
                            {`Subscribe ${props.friendExtra?.payment_info.subscription_info.monthly_amount_formatted || props?.user?.payment_info?.subscription_info?.monthly_amount_formatted} / ${t("month")}`}
                          </div> 
                        

                          {/* <div
                            className="subscription-btn"
                            onClick={(event) =>
                              subscriptionPayment(
                                event,
                                "years",
                                userDetails.data.payment_info.subscription_info
                                  .yearly_amount,
                                userDetails.data.payment_info.subscription_info
                                  .yearly_amount_formatted
                              )
                            }
                          >
                            {
                              userDetails.data.payment_info.subscription_info
                                .yearly_amount_formatted
                            }{" "}
                            /{t("year")}
                          </div> */}
                        </div>

                      </div>
                    ) : null
                  }
                  {(props?.friendExtra?.payment_info?.unsubscribe_btn_status === 1
                    ||
                    props?.user?.payment_info?.unsubscribe_btn_status === 1
                  ) && (
                    <>
                      <div className="user-subscription-btn-sec">
                        <div
                          className="subscription-btn"
                          onClick={() => handleUnfollowModalShow()}
                          style={{ backgroundColor: "#dc3545", border: "none", padding: "11px 30px", fontSize: "14px"}}
                        >
                          {t("Subscribing")}  
                        </div>
                      </div>

                      <Modal
                        show={showUnfollow}
                        onHide={handleUnfollowModalClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        className={`${
                          localStorage.getItem("theme") !== "" &&
                          localStorage.getItem("theme") !== null &&
                          localStorage.getItem("theme") !== undefined &&
                          localStorage.getItem("theme") === "dark"
                            ? "dark-theme-modal"
                            : ""
                        }
                              `}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>{t("unsubscribe")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {t("cancel_subscription_conformation")}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            size="lg"
                            onClick={handleUnfollowModalClose}
                          >
                            {t("close")}
                          </Button>
                          <Button
                            variant="primary"
                            size="lg"
                            onClick={(event) =>
                              handleUnSubscriber(event, props.user.user_id)
                            }
                          >
                            {t("yes")}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </>
                  )}
                  {
                    props?.user?.show_follow === 1 &&
                    (
                      <div className="user-subscription-btn-sec" >
                        <div
                          className="subscription-btn"
                          style={{fontSize: "1em",padding: "11px 30px", fontSize: "14px"}}
                          onClick={(event) => {
                            if (localStorage.getItem("userId")) {
                              props.dispatch(
                                subscriptionPaymentStripeStart({
                                  user_unique_id:
                                    props?.user.user_unique_id,
                                  plan_type: "months",
                                  is_free: 0,
                                  pageType: "userCard"
                                })
                              );
                            } else {
                              const notificationMessage =
                                getErrorNotificationMessage(
                                  t("login_to_continue")
                                );
                              props.dispatch(
                                createNotification(notificationMessage)
                              );
                            }
                          }}
                        >
                          {t("subscribe_for_free")}
                        </div>
                      </div>
                    )
                  }
                  {
                    props?.user?.show_unfollow === 1 &&
                    (
                      <div className="user-subscription-btn-sec">
                        <div
                          className="subscription-btn"
                          style={{backgroundColor: "#17a2b8", border: "none", padding: "11px 30px", fontSize: "14px"}}
                          // onClick={() => handleUnfollowModalShow()}
                          onClick={(event) =>
                            handleUnfollow(
                              event,
                              props?.user?.user_id
                            )
                          }
                        >
                          {t("following")}
                        </div>
                      </div>
                    )
                  }
                  {
                    subscrptionPayment ? (
                      <SubscriptionPaymentModal
                        paymentsModal={subscrptionPayment}
                        closepaymentsModal={closePaymentModal}
                        name={props.user.name}
                        user_unique_id={props.user.user_unique_id}
                        subscriptionData={subscriptionData}
                        pageType={"userCard"}
                      />
                    ) : null
                  }
                  {
                    props.user.is_certified_content_creator === 2
                     &&
                    (
                      <div className="lists-button-group post-icons" style={{padding: "10px 0 0"}}>
                        <Button
                          type="button"
                          className="btn gradient-btn gradientcolor flexAlignCenter"
                          onClick={() => setSendTip(true)}
                        >
                          <Image
                            src={window.location.origin + "/assets/images/icons/tip.svg"}
                            className="svg-clone"
                          />
                          <span className="b-btn-text">{t("tip")}</span>
                        </Button>
                      </div>
                    )
                  }
                  <div
                    className="lists-button-group"
                    style={{ display: "none" }}
                  >
                    <Link
                      type="button"
                      className="btn gradient-btn gradientcolor flexAlignCenter"
                    >
                      <Image
                        src={window.location.origin + "/assets/images/icons/subscribe.svg"}
                        className="svg-clone"
                      />
                      <span className="b-btn-text">
                        {t("subscribed_for_free")}
                      </span>
                    </Link>
                  </div>
                </>
              ) : (
                <div
                  className="add-friend-btns-wrapper"
                  style={{ display: "flex", gap: "12px", marginTop: "37px" }}
                >
                  <button
                  disabled={acceptFriend.loading && isId === props.friendListID}
                    style={{
                      padding: "6px 14px",
                      fontWeight: "500",
                      border: "1px solid transparent",
                      background: "green",
                      color: "white",
                      borderRadius: "7px",
                      fontSize: "12px",
                      flex: "1",
                    }}
                    onClick={()=> {
                      setIsId(props.friendListID);
                      props.dispatch(acceptFriendRequestStart({
                      request_id: props.friendListID,
                      friendData: props.friendData, 
                    }))}}
                  >
                   {acceptFriend.loading && isId === props.friendListID ? "Loading..." : "Accept"}
                  </button>
                  <button
                    disabled={removeFriend?.loading && isId === props.friendListID}
                    style={{
                      padding: "6px 14px",
                      fontWeight: "500",
                      border: "1px solid transparent",
                      background: "crimson",
                      color: "white",
                      borderRadius: "7px",
                      fontSize: "12px",
                      flex: "1",
                    }}
                    onClick={() => {
                      setIsId(props.friendListID);
                      props.dispatch(
                        removeRejectFriendRequestStart({
                          request_id: props.friendListID,
                          friendData: props.friendData,
                          from : "friend" ,
                          user_id : props.user.user_id,
                          type:  "reject",
                          key:"friend_list"
                        })
                      );
                    }}
                  >
                    {removeFriend.loading && isId === props.friendListID ? "Loading..." : "Reject"} 
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Col>
      <SendTipModal
        sendTip={sendTip}
        closeSendTipModal={closeSendTipModal}
        username={props.user.username}
        userPicture={props.user.picture}
        name={props.user.usernamee}
        post_id={null}
        user_id={props.user.user_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  saveChatUser: state.chat.saveChatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(translate(SingleListUserCard));
