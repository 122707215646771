import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Image, Media } from "react-bootstrap";
import SendTipModal from "../../helper/SendTipModal";
import { deleteFavStart, saveFavStart } from "../../../store/actions/FavAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { saveBlockUserStart } from "../../../store/actions/UserAction";
import { unFollowUserStart } from "../../../store/actions/FollowAction";
import VerifiedBadgeNoShadow from "../../Handlers/VerifiedBadgeNoShadow";
import { translate, t } from "react-multi-lang";
import AddUserCustomUserList from "../AddUserList/AddUserCustomUserList";
import {
  deleteCustomUserListStart,
  getCheckUserCustomListStart,
} from "../../../store/actions/CustomListAction";

const CustomListCard = (props) => {
  const [sendTip, setSendTip] = useState(false);
  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const [userId, setUserId] = useState("");

  const [showDropdown, setShowDropdown] = useState(false);

  const handleMoveModalClose = () => setIsMoveModalOpen(false);
  const handleMoveModalOpen = () => setIsMoveModalOpen(true);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const [addFav, setAddFav] = useState(false);
  const [favStatus, setFavStatus] = useState("");

  const [badgeStatus, setBadgeStatus] = useState(props.user.is_verified_badge);
  const [blockUserStatus, setBlockUserStatus] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState("");

  const dispatch = useDispatch();

  const isCheckUserCustom = useSelector(
    (state) => state.customList.getCheckUserCustomList.data
  );

  const isAllUserCustomList = useSelector(
    (state) => state.customList.getAllUserCustomList.data
  );

  const closeAddFavModal = () => {
    setAddFav(false);
  };

  const removeFav = () => {
    props.dispatch(deleteFavStart({ user_id: props.user.user_id }));
  };

  const handleStar = (event) => {
    event.preventDefault();
    // setFavStatus(status);
    props.dispatch(
      saveFavStart({
        user_id: props.user.user_id,
        customListType: props?.customListType,
        setUserData: props?.setUserData,
        userCardData: props?.isSelectedData,
        customListCard: true,
      })
    );
  };

  const handleBlockUser = (event, status) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleRemoveUserList = (event) => {
    event.preventDefault();

    dispatch(
      deleteCustomUserListStart({
        custom_list_user_id: props?.id,
        isAllUserCustomList: isAllUserCustomList,
      })
    );

    setShowDropdown(false);
  };

  const handleUnfollowUser = (event, status) => {
    event.preventDefault();
    setSubscribeStatus(status);
    if (window.confirm(t("unfollowing")))
      props.dispatch(
        unFollowUserStart({
          user_id: props.user.user_id,
        })
      );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleUserChecked = (e, id) => {
    const { setUserData, setCheckedUserId, checkedUserId } = props;
    const updatedUserSelected = props?.isSelectedData?.map((elem) => {
      if (elem?.user?.user_id === id) {
        return {
          ...elem,
          user: {
            ...elem.user,
            isSelected: e.target.checked,
          },
        };
      } else {
        return elem;
      }
    });

    setTimeout(() => {
      setUserData(updatedUserSelected);
    }, 20);

    if (e.target.checked === true) {
      setTimeout(() => {
        setCheckedUserId((prev) => [...prev, id]);
      }, 10);
    }

    if (e.target.checked === false) {
      const filterCheckUserID = checkedUserId?.filter((elem) => elem !== id);
      setTimeout(() => {
        setCheckedUserId(filterCheckUserID);
      }, 10);
    }
  };

  return (
    <>
      <Col sm={12} md={6} lg={4} xs={12}>
        <div className="follower-lists">
          <div className="follower-subscription-lists">
            <div className="follower-subscription-inner">
              <Link to={`/` + props.user.user_unique_id}>
                <div className="follower-wrapper">
                  {props?.isSelected && (
                    <input
                      type="checkbox"
                      className="tick-box"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "8px",
                        zIndex: "1",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      checked={props?.user?.isSelected}
                      onChange={(e) =>
                        handleUserChecked(e, props?.user?.user_id)
                      }
                    />
                  )}
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      top: "10px",
                      right: "1px",
                      borderRadius: "5px",
                      zIndex: "1",
                      color: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 12px",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "11px" }}>
                      {props.user?.user_account_type_formatted}
                    </p>
                  </div>
                  <Image className="follower-cover" src={props.user.cover} />
                </div>
              </Link>
              <div className="follower-profile-header">
                <Link to={`/` + props.user.user_unique_id}>
                  <span className="follower-profile-avatar">
                    <Image src={props.user.picture} className="" />
                  </span>
                </Link>
                <div className="follower-info">
                  <div className="follower-profile-status">
                    <div
                      className="follower-status-text"
                      style={{ display: "none" }}
                    >
                      {t("last_seen")}
                      <span title="User Updated">{props.user.updated}</span>
                    </div>
                    <div className="follower-profile-toggle-dropdown">
                      {/* <Link to="#" className="btn dropdown-toggle btn-link">
                        <Image
                          src="assets/images/icons/vertical-dots.svg"
                          className="svg-clone vertical-dots"
                        />
                      </Link> */}

                      <Dropdown
                        show={showDropdown}
                        className="btn dropdown-toggle btn-link"
                        onToggle={(isOpen) => setShowDropdown(isOpen)}
                      >
                        <Dropdown.Toggle
                          className="user-dropdown-dots dropdown-toggle"
                          type="button"
                          id="dropdown-basic"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/icons/vertical-dots-white.svg"
                            }
                            onClick={() => setUserId(props.user.user_id)}
                            className="svg-clone vertical-dots"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) => handleRemoveUserList(event)}
                            >
                              Remove from the list
                            </Link>
                          </Media>

                          <CopyToClipboard
                            text={props.user.share_link}
                            onCopy={onCopy}
                          >
                            <Media as="li">
                              <Link to="#"> {t("copy_link_to_profile")} </Link>
                            </Media>
                          </CopyToClipboard>

                          {blockUserStatus != "" ? (
                            blockUserStatus == "unblocked" ? (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "blocked")
                                  }
                                >
                                  {t("block_the_user")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "unblocked")
                                  }
                                >
                                  {t("unblock_the_user")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.is_block_user == 1 ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "unblocked")
                                }
                              >
                                {t("unblock_the_user")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "blocked")
                                }
                              >
                                {t("block_the_user")}
                              </Link>
                            </Media>
                          )}

                          {/* {subscribeStatus != "" ? (
                            subscribeStatus == "unsubscribed" ? (
                              <Media as="li">
                                <Link to={`/` + props.user.user_unique_id}>
                                  {t("subscribe")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleUnfollowUser(event, "unsubscribed")
                                  }
                                >
                                  {t("unsubscribe")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.show_follow ? (
                            <Media as="li">
                              <Link to={`/` + props.user.user_unique_id}>
                                {t("subscribe")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleUnfollowUser(event, "unsubscribed")
                                }
                              >
                                {t("unfollow")}
                              </Link>
                            </Media>
                          )} */}
                        </Dropdown.Menu>
                      </Dropdown>

                      <AddUserCustomUserList
                        isMoveModalOpen={isMoveModalOpen}
                        handleMoveModalClose={handleMoveModalClose}
                        handleMoveModalOpen={handleMoveModalOpen}
                        isCheckUserCustom={isCheckUserCustom}
                        user_id={userId}
                      />
                    </div>
                  </div>
                  <div className="follower-wrapper-name">
                    <div className="follower-profile-names">
                      {/* <div className="follower-name-row">
                        <Link to={props.user.user_unique_id}>
                          <div className="follower-user-name">
                            {props.user.name}{" "}
                            {badgeStatus == 1 ? (
                              <VerifiedBadgeNoShadow />
                            ) : null}
                          </div>
                        </Link>
                      </div> */}
                      {/* <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-id">
                            @{props.user.username}
                          </div>
                        </Link>
                      </div> */}
                      <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-name">
                            <span style={{display: "flex", gap: "7px"}}>
                              {props.user.username}
                              {props?.user?.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="group-follower-btns">
                      <CopyToClipboard
                        text={props.user.share_link}
                        onCopy={onCopy}
                      >
                        <Button
                          type="button"
                          className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/icons/share.svg"
                            }
                            className="svg-clone "
                          />
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-to-lists-button">
                <div className="swiper-favorite">
                  {props?.user?.is_fav_user === 1 ? (
                    <Link
                      type="button"
                      className="swiper-btn-fav"
                      onClick={(event) => handleStar(event)}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/star-active.svg"
                        }
                        className="svg-clone my-p-icons"
                        width="12"
                      />
                    </Link>
                  ) : (
                    <Link
                      type="button"
                      className="swiper-btn-fav"
                      onClick={(event) => handleStar(event)}
                    >
                      <Image
                        src={
                          window.location.origin +
                          "/assets/images/icons/star.svg"
                        }
                        className="svg-clone my-p-icons"
                        width="12"
                      />
                    </Link>
                  )}
                </div>
              </div>
              {props?.user?.is_certified_content_creator === 2 && (
                <div className="lists-button-group post-icons">
                  <Button
                    type="button"
                    className="btn gradient-btn gradientcolor flexAlignCenter"
                    onClick={() => setSendTip(true)}
                  >
                    <Image
                      src={
                        window.location.origin + "/assets/images/icons/tip.svg"
                      }
                      className="svg-clone"
                    />
                    <span className="b-btn-text">{t("tip")}</span>
                  </Button>
                </div>
              )}

              <div className="lists-button-group" style={{ display: "none" }}>
                <Link
                  type="button"
                  className="btn gradient-btn gradientcolor flexAlignCenter"
                >
                  <Image
                    src="assets/images/icons/subscribe.svg"
                    className="svg-clone"
                  />
                  <span className="b-btn-text">{t("subscribed_for_free")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <SendTipModal
        sendTip={sendTip}
        closeSendTipModal={closeSendTipModal}
        username={props.user.username}
        userPicture={props.user.picture}
        name={props.user.usernamee}
        post_id={null}
        user_id={props.user.user_id}
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(CustomListCard));
