import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";

import InfiniteScroll from "react-infinite-scroll-component";
import { t } from "react-multi-lang";
import { Link } from "react-router-dom";
import NoDataFound from "../NoDataFound/NoDataFound";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getReferralStart } from "../../store/actions/ReferralAction";
import "./style.css";

const RefreesIndex = () => {
  const periodTypes = ["all", "yearly", "quarterly", "monthly"];
  const [periodTypeValue, setPeriodTypeValue] = useState("all");
  const [periodValue, setPeriodValue] = useState(new Date().getFullYear());
  const {created_at} = useSelector((state) => state.users.profile.data)
  const [userCreatedDate, setUserCreatedDate] = useState(null);
  
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const currentQuarter = getCurrentQuarter();


  const { referralDetails } = useSelector((state) => state.referral);
  const history = useHistory();
  const dispatch = useDispatch();

  const formatDate = (createdAt) =>
    new Date(createdAt).toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    

    const handlePeriodTypeChange  = (e) => {

      setPeriodTypeValue(e.target.value);
      setPeriodValue(getDefaultSelectedYear());
    }


    const generateOptions = () => {
      const options = [];
      if (periodTypeValue === 'yearly' && userCreatedDate) {
        // for (let year = userCreatedYear; year <= currentYear; year++) {
        //   options.push(<option key={year} value={year} >{year}</option>);
        // }
        for (let year = currentYear; year >= userCreatedDate.year; year--) {
          options.push(<option key={year} value={year}>{year}</option>);
      }
      } else if (periodTypeValue === 'quarterly' && userCreatedDate) {
    
        const userCreatedQuarter = Math.ceil(userCreatedDate.month / 3);
        for (let year = currentYear; year >= userCreatedDate.year; year--) {
          const startQuarter = year === currentYear ? getCurrentQuarter() : 4;
          const endQuarter = year === userCreatedDate.year ? userCreatedQuarter : 1;
          for (let quarter = startQuarter; quarter >= endQuarter; quarter--) {
            const optionValue = `Q${quarter}-${year}`;
            const optionTitle = `${year} Q${quarter}`
            options.push(<option key={optionValue} value={optionValue}>{optionTitle}</option>);
          }
        }


      } else if (periodTypeValue === 'monthly' && userCreatedDate) {

        for (let year = currentYear; year >= userCreatedDate.year; year--) {
          const startMonth = year === currentYear ? currentMonth : 12;
          const endMonth = year === userCreatedDate.year ? userCreatedDate.month : 1;
          for (let month = startMonth; month >= endMonth; month--) {
            options.push(<option key={`${month}-${year}`} value={`${month}-${year}`}>{`${year} - ${month}`}</option>);
          }
        }

      }
      return options;
    };


    function getCurrentQuarter() {
      const month = new Date().getMonth() + 1;
      return Math.ceil(month / 3);
    }
  
    function getDefaultSelectedYear() {
      if (periodTypeValue === 'yearly') {
        return currentYear;
      } else if (periodTypeValue === 'quarterly') {
        return `Q${currentQuarter}-${currentYear}`;
      } else {
        return `${currentMonth}-${currentYear}`;
      }
    }
   
    useEffect(()=>{
      setPeriodValue(getDefaultSelectedYear());

    },[periodTypeValue])


  useEffect(() => {
    dispatch(getReferralStart({setUserCreatedDate : setUserCreatedDate , created_at : created_at  ,payload : {periodValue : periodTypeValue !== "all" ? periodValue : null , periodTypeValue : periodTypeValue} }));
  }, [periodTypeValue, periodValue]);

  return (
    <div className="payment-sec">
      <Container>
        <Row>
          <Col sm={12} md={12} xl={8}>
            <div className="bookmarkes-list bookmarks-right-side">
              <div className="pull-left">
                <Link
                  className="bookmarkes-list notify-title back-button"
                  onClick={() => history.goBack()}
                >
                  <img
                    src={
                      window.location.origin + "/assets/images/icons/back.svg"
                    }
                    className="svg-clone"
                  />
                  <h3 className="ml-2 mb-0">{t("referrals")}</h3>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="payment-tabs-card">
              <div className="flex-content">
                <h2>{t("refrees_list")}</h2>
                <div className="refrees-list-filter-wrapper">
                  <div className="refrees-list-filter-1">
                    <label style={{all: "unset", fontSize: "14px"}}>Period Type</label>
                    <select value={periodTypeValue} onChange={handlePeriodTypeChange}>
                      {
                        periodTypes.map((periodT)=>{
                          return(
                            <option value={periodT}>{periodT.toUpperCase()}</option>
                          )
                        })                        
                      }
                    </select>
                  </div>
                  {
                    periodTypeValue !== "all" && (
                      
                  <div className="refrees-list-filter-2">
                  <label style={{all: "unset",fontSize: "14px"}}>Period</label>
                    <select value={periodValue || ""} onChange={(e)=> setPeriodValue(e.target.value)}>
                       {generateOptions()}
                    </select>
                  </div>
                    )
                  }
                </div>
              </div>
              {/* <Tabs defaultActiveKey="earnings" id="uncontrolled-tab-example"> */}
              {/* <Tab eventKey="earnings" title="Transactions"> */}
              <div className="payment-tabs-content">
                {referralDetails?.loading ? (
                  t("loading")
                ) : referralDetails?.data?.userReferrals?.length > 0 ? (
                  <Table borderedless responsive>
                    <thead>
                      <tr className="bg-white">
                        <th>{t("refree_username")}</th>
                        <th>{t("refree_name")}</th>
                        <th>{t("refree_email")}</th>
                        <th>{t("refree_join_date")}</th>
                        <th>{t("refree_amount")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {referralDetails?.data?.userReferrals?.map(
                        (refree, index) => (
                          <tr key={index}>
                            {refree?.user ? (
                              <td style={{ fontSize: "15px" }}>
                                <Link to={`/${refree?.user?.user_unique_id}`}>
                                  @{refree?.user?.username}
                                </Link>
                              </td>
                            ) : null}
                            <td>{refree?.user?.name}</td>
                            <td>{refree?.user?.email}</td>
                            <td>{formatDate(refree?.created_at)}</td>
                            <td>
                              {(
                                Math.round(refree?.coin_amount * 100) / 100
                              ).toFixed(2)}{" "}
                              {t("coin")}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <NoDataFound />
                )}
              </div>
              {/* </Tab> */}
              {/* </Tabs> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RefreesIndex;
