import React, { useState, useRef, useEffect } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeChatAudio } from "../../store/actions/ChatAction";
import AltraChatAudioPlayer from "../CustomComponents/AudioPlayer/AltraChatAudioPlayer";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import ChatAssetPaymentModal from "../Model/PaymentModal/ChatAssetPaymentModal";
import ChatDocument from "./ChatDocument";

const ReceivedChat = ({ message, setRejectPpvModel, setPpvRequest, setChatMessageId, setRejectPppvContent}) => {
  const [chatPayment, setChatPayment] = useState(false);
  const closePaymentModal = () => {
    setChatPayment(false);
  };

  return (
    <>
      <div className="new-chat-room-left-sec">
        <div className="new-chat-room-left-msg-card">
          <div className="new-chat-room-left-msg-container">
            {message.chat_assets?.length > 0 ? (
              <>
                {message.file_type === "image" ||
                message.file_type === "video" ? (
                  <div
                    className={
                      message.chat_assets.length > 1
                        ? "uploaded-chat-room-multiple-img-card"
                        : "uploaded-chat-room-single-img-card"
                    }
                    style={{
                      zIndex: "0"
                    }}
                  >
                    {message.chat_assets.map((chatAsset, i) => (
                      <ReceivedChatAsset
                        chatAsset={chatAsset}
                        key={i}
                        i={i}
                        length={message.chat_assets.length}
                        payment={message.is_user_needs_pay}
                      />
                    ))}
                    
                    {
                      (Number(message.ppv_status) === 1 && message?.send_type === 'receiver') ? (
                        <>
                          <div
                            className="payment-overlay"
                            style={{
                              display: "flex",
                              flexDirection: "Column",
                            }}
                          >
                            {message.amount_formatted}
                            <div className="accept_reject_container">
                              <button
                                className="accept_btn"
                                onClick={() => setChatPayment(true)}
                              >
                                Accept
                              </button>
                              <button
                                className="reject_btn"
                                onClick={() => {
                                  setRejectPppvContent(true)
                                  setChatMessageId(message.chat_message_id)
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                            
                        </>
                      ) : message.is_user_needs_pay ? (
                      <div
                        className="payment-overlay"
                        onClick={() => setChatPayment(true)}
                      >
                        {
                          (Number(message.ppv_status) === 5)
                            ? "rejected"
                            : message.amount_formatted
                        }
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="uploaded-chat-room-audio-card">
                    {message.is_user_needs_pay ? (
                      <>
                        {message.chat_assets.map((chatAsset, i) => (
                          <>
                            <Image
                              className="uploaded-chat-room-audio-img"
                              src={
                                message.file_type === "audio"
                                  ? window.location.origin +
                                    "/assets/images/new-chat/audio-preview.png"
                                  : window.location.origin +
                                    "/assets/images/new-chat/file-preview.png"
                              }
                            />
                          </>
                        ))}
                        <div
                          className="payment-overlay"
                          onClick={() => setChatPayment(true)}
                        >
                          {message.amount_formatted}
                        </div>
                      </>
                    ) : (
                      message.chat_assets.map((chatAsset, i) =>
                        message.file_type === "audio" ? (
                          <AltraChatAudioPlayer src={chatAsset.asset_file} />
                        ) : (
                          <ChatDocument chatAsset={chatAsset} />
                        )
                      )
                    )}
                  </div>
                )}
              </>
            ) : null}
            {
              
              Number(message.ppv_amount) &&
              Number(message.ppv_amount) !== undefined ? (
                <>
                  <h6>{message.message}</h6>
                  <h6 style={{ paddingTop: 0, paddingBottom: 0 }}>
                    Budget: {message.ppv_amount}{" "}
                    {Number(message.ppv_amount) > 1 ? "Coins" : "Coin"}
                  </h6>
                  { (Number(message.ppv_status) === 3)
                    ? (
                    <div>
                      <h6 style={{color: '#E21B1B'}}>Request has been expired</h6>
                    </div>  
                    )
                    : (Number(message.ppv_status) === 1)
                      ? (<div>
                        <h6 style={{color: '#1ef436'}}>Accepted</h6>
                      </div>)
                      :
                        (Number(message.ppv_status) === 2) ? (
                        <div>
                          <h6 style={{color: '#E21B1B'}}>Rejected</h6>
                        </div>) : (
                        <div className="accept_reject_container">
                          <button
                            className="accept_btn"
                            onClick={() => {
                              setPpvRequest(true)
                              setChatMessageId(message.chat_message_id)
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="reject_btn"
                            onClick={() => {
                              setRejectPpvModel(true)
                              setChatMessageId(message.chat_message_id)
                            }}
                          >
                            Reject
                          </button>
                        </div>
                  )}
                </>
              ) : message.message 
                    ? message?.file_type === "share_post" 
                      ?  <h6 className='share_post_h6' dangerouslySetInnerHTML={{
                          __html:
                            message.message,
                        }}></h6>
                      : <h6>{message.message}</h6>
                : null
            }
            <p>
              <span>
                {message.amount > 0 && message.is_user_needs_pay == 0
                  ? message.amount_formatted
                  : ""}
              </span>
              <span>{message.time_formatted}</span>
            </p>
          </div>
        </div>
      </div>
      {chatPayment ? (
        <ChatAssetPaymentModal
          paymentsModal={chatPayment}
          closepaymentsModal={closePaymentModal}
          paymentData={message}
        />
      ) : null}
    </>
  );
};

const ReceivedChatAsset = ({ chatAsset, i, length, payment }) => {
  const dispatch = useDispatch();
  const assetImage = useRef(null);

  const handleClick = (e) => {
    e.preventDefault();
    if (assetImage.current) {
      assetImage.current.click();
      stopAudio();
    }
  };

  const stopAudio = () => {
    dispatch(changeChatAudio({ src: "" }));
  };

  return payment ? (
    chatAsset.file_type === "image" ? (
      <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
        <Image
          className="uploaded-chat-room-multiple-img"
          src={chatAsset.asset_file}
        />
        {length > 4 && i === 3 ? (
          <div className="uploaded-chat-room-video-icon-sec">
            <div className="chat-more-sec">+{length - 3}</div>
          </div>
        ) : null}
      </div>
    ) : (
      <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
        <Image
          className={`uploaded-chat-room-multiple-img`}
          src={chatAsset.blur_file}
        />
        <div className="uploaded-chat-room-video-icon-sec">
          {length > 4 && i === 3 ? (
            <div className="chat-more-sec">+{length - 3}</div>
          ) : (
            <Image
              src={
                window.location.origin +
                "/assets/images/new-home/icon/video-icon.png"
              }
              className="uploaded-chat-room-video-icon"
            />
          )}
        </div>
      </div>
    )
  ) : chatAsset.file_type === "image" ? (
    <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
      <Image
        ref={assetImage}
        className="uploaded-chat-room-multiple-img"
        src={chatAsset.asset_file}
        data-fancybox-chat
        onClick={() => stopAudio()}
      />
      {length > 4 && i === 3 ? (
        <div
          className="uploaded-chat-room-video-icon-sec"
          onClick={handleClick}
        >
          <div className="chat-more-sec">+{length - 3}</div>
        </div>
      ) : null}
    </div>
  ) : (
    <div style={{ position: "relative", display: i > 3 ? "none" : "block" }}>
      <Image
        ref={assetImage}
        className={`uploaded-chat-room-multiple-img`}
        src={chatAsset.blur_file}
        data-fancybox-chat
        href={chatAsset.asset_file}
        onClick={() => stopAudio()}
      />
      <div className="uploaded-chat-room-video-icon-sec" onClick={handleClick}>
        {length > 4 && i === 3 ? (
          <div className="chat-more-sec">+{length - 3}</div>
        ) : (
          <Image
            src={
              window.location.origin +
              "/assets/images/new-home/icon/video-icon.png"
            }
            className="uploaded-chat-room-video-icon"
          />
        )}
      </div>
    </div>
  );
};

export default ReceivedChat;
