import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import CommonCenterLoader from "../../../Loader/CommonCenterLoader";
import { tr } from "date-fns/locale";
import VerifiedBadgeNoShadow from "../../../Handlers/VerifiedBadgeNoShadow";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../../helper/NotificationMessage";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { saveFavStart } from "../../../../store/actions/FavAction";
import SendTipModal from "../../../helper/SendTipModal";
import NoDataFound from "../../../NoDataFound/NoDataFound";
import "../FollowingListView.css";

const FollowingListView = (props) => {
  // const [favStatus, setFavStatus] = useState(false);
  const [sendTip, setSendTip] = useState(false);
  const [filterUser, setFilterUser] = useState(props.userData);
  const [tipModel, setTipModel] = useState({});

  useEffect(() => {
    setFilterUser(props.userData);
  }, [props.userData]);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const openSendTipModel = () => {
    setSendTip(true);
  };

  const handleStar = (e, id) => {
    e.preventDefault();

    dispatch(
      saveFavStart({
        user_id: id,
      })
    );
    const filterUserFollowing = filterUser.map((follower) => {
      if (
        follower?.otherUser?.user_id === id ||
        follower?.user?.user_id === id
      ) {
        return props.componentType === "friends"
          ? {
              ...follower,
              is_fav_user: follower?.otherUser?.is_fav_user == 1 ? 0 : 1,
            }
          : {
              ...follower,
              otherUser: {
                ...follower.otherUser,
                is_fav_user: follower?.otherUser?.is_fav_user == 1 ? 0 : 1,
              },
            };
      } else {
        return follower;
      }
    });

    setFilterUser(filterUserFollowing);
  };

  const handleTipModel = (tipObj) => {
    setTipModel(tipObj);
  };

  const dispatch = useDispatch();
  return (
    <div className="following-table-sec">
      <Container>
        <Row>
          <Col sm={12} md={12}>
            <div className="following-table">
              <Table borderedless responsive>
                <thead>
                  <tr>
                    <th>Profile Picture</th>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Share Link</th>
                    <th>Favorites</th>
                    <th>Tip</th>
                  </tr>
                </thead>
                <tbody>
                  {props.following.loading ? (
                    <CommonCenterLoader></CommonCenterLoader>
                  ) : filterUser && filterUser.length > 0 ? (
                    filterUser.map((follower) => {
                      const userKey =
                        props.componentType === "friends"
                          ? follower.user.user_unique_id
                          : follower.otherUser.user_unique_id;
                      const userImg =
                        props.componentType === "friends"
                          ? follower.user.picture
                          : follower.otherUser.picture;
                      const userLinkId =
                        props.componentType === "friends"
                          ? follower.user.user_unique_id
                          : follower.otherUser.user_unique_id;
                      const name =
                        props.componentType === "friends"
                          ? follower.user.name
                          : follower.otherUser.name;
                      const userVerfiedBadge =
                        props.componentType === "friends"
                          ? follower.user.is_verified_badge
                          : follower.otherUser.is_verified_badge;
                      const userName =
                        props.componentType === "friends"
                          ? follower.user.username
                          : follower.otherUser.username;
                      const userShareLink =
                        props.componentType === "friends"
                          ? follower.user.share_link
                          : follower.otherUser.share_link;
                      const userId =
                        props.componentType === "friends"
                          ? follower.user.user_id
                          : follower.otherUser.user_id;
                      const userFavoraite =
                        props.componentType === "friends"
                          ? follower.is_fav_user
                          : follower.otherUser.is_fav_user;
											const isUserCertifiedCreator =
												props.componentType === "friends"
													? follower.user.is_certified_content_creator
													: follower.otherUser.is_certified_content_creator
                      const checkUser =
                        props.componentType === "friends"
                          ? follower.user
                          : follower.otherUser;
                      return checkUser ? (
                        // <UserCard user={follower.otherUser} />
                        <tr key={userKey}>
                          <td className="amount">
                            {/* <span className="follower-profile-avatar"> */}
                            <Image
                              src={userImg}
                              className=""
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />
                            {/* </span> */}
                          </td>
                          <td className="amount">
                            {/* <div className="follower-name-row"> */}
                            <Link to={userLinkId}>
                              <div className="follower-user-name">
                                {name}{" "}
                                {userVerfiedBadge == 1 ? (
                                  <VerifiedBadgeNoShadow />
                                ) : null}
                              </div>
                            </Link>
                            {/* </div> */}
                          </td>
                          <td className="amount">
                            <div className="follower-name-row">
                              <Link
                                to={`/` + userLinkId}
                                className="g-user-realname__wrapper"
                              >
                                <div className="follower-user-id">
                                  @{userName}
                                </div>
                              </Link>
                            </div>
                          </td>
                          <td className="amount">
                            <div className="group-follower-btns">
                              <CopyToClipboard
                                text={userShareLink}
                                onCopy={() => {
                                  const notificationMessage =
                                    getSuccessNotificationMessage(
                                      t("profile_link_copied")
                                    );
                                  dispatch(
                                    createNotification(notificationMessage)
                                  );
                                }}
                              >
                                <Button
                                  type="button"
                                  className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                                >
                                  <Image
                                    src="assets/images/icons/share.svg"
                                    className="svg-clone "
                                  />
                                </Button>
                              </CopyToClipboard>
                            </div>
                          </td>
                          <td className="amount">
                            <div className="swiper-favorite">
                              {/* {
																		 follower.otherUser.is_fav_user == 1 ? ( */}
                              <Link
                                type="button"
                                className="swiper-btn-fav"
                                onClick={(event) => handleStar(event, userId)}
                              >
                                {userFavoraite == 1 ? (
                                  <Image
                                    src="assets/images/icons/star-active.svg"
                                    className="svg-clone my-p-icons"
                                    width="12"
                                  />
                                ) : (
                                  <Image
                                    src="assets/images/icons/star.svg"
                                    className="svg-clone my-p-icons"
                                    width="12"
                                  />
                                )}
                              </Link>
                              {/* ) : (
																			<Link
																				type="button"
																				className="swiper-btn-fav"
																				onClick={(event) => {
																					event.preventDefault();
																					dispatch(
																						saveFavStart({
																							user_id: follower.otherUser.user_id,
																						})
																					);
																				}}
																			>
																				<Image
																					src="assets/images/icons/star.svg"
																					className="svg-clone my-p-icons"
																					width="12"
																				/>
																			</Link>
																		)} */}
                            </div>
                          </td>
                          <td className="amount">
														{
															isUserCertifiedCreator === 2
																&&
															(
																<div>
																	<span
																		style={{
																			padding: "10px",
																			backgroundColor: "#6661e7	",
																			display: "flex",
																			alignItems: "center",
																			width: "50px",
																			height: "50px",
																			borderRadius: "50%",
																			justifyContent: "center",
																			cursor: "pointer",
																		}}
																		onClick={() => {
																			openSendTipModel();
																			handleTipModel({
																				username: userName,
																				userPicture: userImg,
																				name: userName,
																				post_id: null,
																				user_id: userId,
																			});
																		}}
																	>
																		<Image
																			src="assets/images/icons/tip.svg"
																			className="svg-clone"
																		/>
																	</span>
																</div>
															)
														}
                            
                          </td>
                        </tr>
                      ) : (
                        ""
                      );
                    })
                  ) : (
                    <NoDataFound></NoDataFound>
                  )}
                </tbody>
              </Table>
              <SendTipModal
                sendTip={sendTip}
                closeSendTipModal={closeSendTipModal}
                username={tipModel.username}
                userPicture={tipModel.userPicture}
                name={tipModel.name}
                post_id={null}
                user_id={tipModel.user_id}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// function mapDispatchToProps(dispatch) {
//   return { dispatch };
// }

// export default connect(null, mapDispatchToProps)(translate(FollowingListView));

export default FollowingListView;
