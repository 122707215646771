import {
  FETCH_SUBSCRIPTION_START,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,
  FETCH_MY_SUBSCRIPTION_START,
  FETCH_MY_SUBSCRIPTION_SUCCESS,
  FETCH_MY_SUBSCRIPTION_FAILURE,
  FETCH_SINGLE_SUBSCRIPTION_START,
  FETCH_SINGLE_SUBSCRIPTION_SUCCESS,
  FETCH_SINGLE_SUBSCRIPTION_FAILURE,
  SUBSCRIPTION_PAYMENT_STRIPE_START,
  SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS,
  SUBSCRIPTION_PAYMENT_STRIPE_FAILURE,
  SUBSCRIPTION_PAYMENT_WALLET_START,
  SUBSCRIPTION_PAYMENT_WALLET_SUCCESS,
  SUBSCRIPTION_PAYMENT_WALLET_FAILURE,
  SUBSCRIPTION_AUTO_RENEWAL_START,
  SUBSCRIPTION_AUTO_RENEWAL_SUCCESS,
  SUBSCRIPTION_AUTO_RENEWAL_FAILURE,
  SUBSCRIPTION_PAYMENT_PAYPAL_START,
  SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS,
  SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE,
  SUBSCRIPTION_PAYMENT_CCBILL_START,
  SUBSCRIPTION_PAYMENT_CCBILL_SUCCESS,
  SUBSCRIPTION_PAYMENT_CCBILL_FAILURE,
  SUBSCRIPTION_PAYMENT_COINPAYMENT_START,
  SUBSCRIPTION_PAYMENT_COINPAYMENT_SUCCESS,
  SUBSCRIPTION_PAYMENT_COINPAYMENT_FAILURE,
  SUBSCRIPTION_BADGE_SUCCESS,
  SUBSCRIPTION_BADGE_FAILURE,
  ADD_SUBSCRIPTION_LEVEL_SUCCESS,
  ADD_SUBSCRIPTION_LEVEL_FAILURE,
  GET_SUBSCRIPTION_LEVELS_SUCCESS,
  GET_SUBSCRIPTION_LEVELS_FAILURE,
  SET_INHERIT_CHANGE_VAL,
  UNFOLLOW_USER_SUBSCRIPTION_START,
  UNFOLLOW_USER_SUBSCRIPTION_SUCCESS,
  UNFOLLOW_USER_SUBSCRIPTION_FAILURE,
} from "../actions/ActionConstant";

const initialState = {
  subscription: {
    data: {},
    loading: true,
    error: false,
  },
  mySubscription: {
    data: {},
    loading: true,
    error: false,
  },
  singleSubscription: {
    data: {},
    loading: true,
    error: false,
  },
  subPayStripe: {
    inputData: {},
    loading: false,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subPayWallet: {
    inputData: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  unSubscribeCreator: {
    loading: false,
    error: false,
    success: {},
  },
  subscriptionRenew: {
    inputData: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subPayCCBill: {
    inputData: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subPayCoinPayment: {
    inputData: {},
    loading: true,
    error: false,
    success: {},
    buttonDisable: false,
    loadingButtonContent: null,
  },
  subLevelData: {  
    data: {},
    error: false,
  },
  subLevelResponse: {  
    data: {},
    error: false,
  },
  
  allSubLevelList : {
    data : {},
    selectedInheritVal : "",
    error: false,
  }
};

const SubscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIPTION_START:
      return {
        ...state,
        subscription: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        subscription: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        subscription: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_MY_SUBSCRIPTION_START:
      return {
        ...state,
        mySubscription: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_MY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        mySubscription: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_MY_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        mySubscription: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case FETCH_SINGLE_SUBSCRIPTION_START:
      return {
        ...state,
        singleSubscription: {
          data: {},
          loading: true,
          error: false,
        },
        singleSubInputData: {
          data: action.data,
        },
      };
    case FETCH_SINGLE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        singleSubscription: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_SINGLE_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        singleSubscription: {
          data: {},
          loading: true,
          error: action.error,
        },
      };
    case SUBSCRIPTION_PAYMENT_STRIPE_START:
      return {
        ...state,
        subPayStripe: {
          inputData: action.data,
          loading: true,
          error: false,
          success: {},
          buttonDisable: true,
          loadingButtonContent: "Processing.. Please wait...",
        },
      };
    case SUBSCRIPTION_PAYMENT_STRIPE_SUCCESS:
      return {
        ...state,
        subPayStripe: {
          loading: false,
          error: false,
          success: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_PAYMENT_STRIPE_FAILURE:
      return {
        ...state,
        subPayStripe: {
          loading: false,
          error: action.error,
          success: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_PAYMENT_WALLET_START:
      return {
        ...state,
        subPayWallet: {
          inputData: action.data,
          loading: true,
          error: false,
          success: {},
          buttonDisable: true,
          loadingButtonContent: "Processing.. Please wait...",
        },
      };
    case SUBSCRIPTION_PAYMENT_WALLET_SUCCESS:
      return {
        ...state,
        subPayWallet: {
          loading: false,
          error: false,
          success: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_PAYMENT_WALLET_FAILURE:
      return {
        ...state,
        subPayWallet: {
          loading: true,
          error: action.error,
          success: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case UNFOLLOW_USER_SUBSCRIPTION_START: 
      return {
        ...state,
        unSubscribeCreator: {
          loading: true,
          success: {},
          error: false
        }
      };
    case UNFOLLOW_USER_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        unSubscribeCreator: {
          loading: false,
          success: action.data,
          error: false
        }
      };
    case UNFOLLOW_USER_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        unSubscribeCreator: {
          loading: false,
          success: {},
          error: action.error,
        }
      };
    case SUBSCRIPTION_AUTO_RENEWAL_START:
      return {
        ...state,
        subscriptionRenew: {
          inputData: action.data,
          loading: true,
          error: false,
          success: {},
          buttonDisable: true,
          loadingButtonContent: "Processing.. Please wait...",
        },
      };
    case SUBSCRIPTION_AUTO_RENEWAL_SUCCESS:
      return {
        ...state,
        subscriptionRenew: {
          loading: false,
          error: false,
          success: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_AUTO_RENEWAL_FAILURE:
      return {
        ...state,
        subscriptionRenew: {
          loading: true,
          error: action.error,
          success: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_PAYMENT_PAYPAL_START:
      return {
        ...state,
        subPayPaypal: {
          inputData: action.data,
          loading: true,
          error: false,
          success: {},
          buttonDisable: true,
          loadingButtonContent: "Processing.. Please wait...",
        },
      };
    case SUBSCRIPTION_PAYMENT_PAYPAL_SUCCESS:
      return {
        ...state,
        subPayPaypal: {
          loading: false,
          error: false,
          success: action.data,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SUBSCRIPTION_PAYMENT_PAYPAL_FAILURE:
      return {
        ...state,
        subPayPaypal: {
          loading: true,
          error: action.error,
          success: {},
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
      case SUBSCRIPTION_PAYMENT_CCBILL_START:
        return {
          ...state,
          subPayCCBill: {
            inputData: action.data,
            loading: true,
            error: false,
            success: {},
            buttonDisable: true,
            loadingButtonContent: "Processing.. Please wait...",
          },
        };
      case SUBSCRIPTION_PAYMENT_CCBILL_SUCCESS:
        return {
          ...state,
          subPayCCBill: {
            loading: false,
            error: false,
            success: action.data,
            buttonDisable: false,
            loadingButtonContent: null,
          },
        };
      case SUBSCRIPTION_PAYMENT_CCBILL_FAILURE:
        return {
          ...state,
          subPayCCBill: {
            loading: true,
            error: action.error,
            success: {},
            buttonDisable: false,
            loadingButtonContent: null,
          },
        };
        case SUBSCRIPTION_PAYMENT_COINPAYMENT_START:
          return {
            ...state,
            subPayCoinPayment: {
              inputData: action.data,
              loading: true,
              error: false,
              success: {},
              buttonDisable: true,
              loadingButtonContent: "Processing.. Please wait...",
            },
          };
        case SUBSCRIPTION_PAYMENT_COINPAYMENT_SUCCESS:
          return {
            ...state,
            subPayCoinPayment: {
              loading: false,
              error: false,
              success: action.data,
              buttonDisable: false,
              loadingButtonContent: null,
            },
          };
        case SUBSCRIPTION_PAYMENT_COINPAYMENT_FAILURE:
          return {
            ...state,
            subPayCoinPayment: {
              loading: true,
              error: action.error,
              success: {},
              buttonDisable: false,
              loadingButtonContent: null,
            },
          };
        case SUBSCRIPTION_BADGE_SUCCESS:
          return {
            ...state,
            subLevelData : {
              ...state.subLevelData,
              data : action.data
            }
          };
        case SUBSCRIPTION_BADGE_FAILURE:
          return {
            ...state,
            subLevelData : {
              ...state.subLevelData,
              error : action.error
            }
          };
        case ADD_SUBSCRIPTION_LEVEL_SUCCESS:
          return {
            ...state,
            subLevelResponse : {
              ...state.subLevelData,
              data : action.payload
            }
          };
        case ADD_SUBSCRIPTION_LEVEL_FAILURE:
          return {
            ...state,
            subLevelResponse : {
              ...state.subLevelResponse,
              error : action.error
            }
          };
        case GET_SUBSCRIPTION_LEVELS_SUCCESS:
          
        const array = action.payload.data;

           if (array.length < 1) {
            return {
              ...state,
              allSubLevelList : {
                ...state.allSubLevelList,
                data : {...action.payload , previousData : null }
              }
            }; // or any other value indicating not enough elements
            }

          return {
            ...state,
            allSubLevelList : {
              ...state.allSubLevelList,
              data : {...action.payload,  previousData: array[array.length - 1]},
              selectedInheritVal : array[array.length - 1].alias_of_level
            }
          };
        case GET_SUBSCRIPTION_LEVELS_FAILURE:
          return {
            ...state,
            allSubLevelList : {
              ...state.allSubLevelList,
              error : action.error
            }
          };

          case SET_INHERIT_CHANGE_VAL:
           
           const findVal = state.allSubLevelList.data?.data.find((ele)=> ele.alias_of_level === action.payload);
           
          return{
            ...state,
            allSubLevelList : {
              ...state.allSubLevelList,
              selectedInheritVal : action.payload,
              data : {...state.allSubLevelList.data,  previousData: findVal}
            }            
          }
    default:
      return state;
  }
};

export default SubscriptionReducer;
