import React, {useEffect, useState} from 'react'
import { Button, Container, Row, Col, Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { translate, t } from "react-multi-lang";
import { useDispatch } from 'react-redux';
import NoDataFound from '../../NoDataFound/NoDataFound';
import SendTipModal from '../../helper/SendTipModal';
import { saveFavStart } from '../../../store/actions/FavAction';
import { getSuccessNotificationMessage } from '../../helper/NotificationMessage';
import VerifiedBadgeNoShadow from '../../Handlers/VerifiedBadgeNoShadow';
import CommonCenterLoader from '../../Loader/CommonCenterLoader';

const CustomeListView = (props) => {
	const [sendTip, setSendTip] = useState(false);
	const [filterUser, setFilterUser] = useState(props.userData)
	const [tipModel, setTipModel] = useState({})

	useEffect(() => {	
		setFilterUser(props.userData)
	}, [props.userData])

	const closeSendTipModal = () => {
    setSendTip(false);
  };

	const openSendTipModel = () => {
		setSendTip(true)
	}
	const handleStar = (e, id) => {
		e.preventDefault()

		dispatch(
			saveFavStart({
				user_id: id,
			})
		);
		const filterUserSubscribing = filterUser.map(item => {
			if (item?.user_id === id) {
					return {
						...item,
						user: {
							...item.user,
						  is_fav_user: item?.user?.is_fav_user ==1 ? 0 : 1
						}
					}
			} else {
				return item
			}
		})

		setFilterUser(filterUserSubscribing)
	}

	const handleTipModel = ( tipObj) => {
		setTipModel(tipObj)
	}
	const dispatch = useDispatch()

	return (
		<div className='following-table-sec'>
			<Container>
				<Row>
					<Col sm={12} md={12}>
						<div className="following-table">
							<Table borderedless responsive>
								<thead>
									<tr>
										<th>Profile Picture</th>
										<th>Name</th>
										<th>Username</th>
										<th>Share Link</th>
										<th>Favorites</th>
										<th>Tip</th>
									</tr>
								</thead>
								<tbody>
									{
										props.allUserCustomList?.loading
											?
											(
												<CommonCenterLoader></CommonCenterLoader>
											)
											:filterUser && filterUser.length > 0 
											?
												(
													filterUser.map((allCustomListUser) =>
														allCustomListUser.user ? (
															<tr key={allCustomListUser.user.user_unique_id}>
																<td className='amount'>
																		<Image 
																			src={allCustomListUser.user.picture} className="" 
																			style={{
																				width: "50px",
																				height: "50px",
																				borderRadius: "50%"
																			}}
																		/>
																</td>
																<td className='amount'> 
																		<Link to={allCustomListUser.user.user_unique_id}>
																			<div className="follower-user-name">
																				{allCustomListUser.user.name}{" "}
																				{allCustomListUser.user.is_verified_badge == 1 ? (
																					<VerifiedBadgeNoShadow />
																				) : null}
																			</div>
																		</Link>
																</td>
																<td className='amount'>
																	<div className="follower-name-row">
																		<Link
																			to={`/` + allCustomListUser.user.user_unique_id}
																			className="g-user-realname__wrapper"
																		>
																			<div className="follower-user-id">
																				@{allCustomListUser.user.username}
																			</div>
																		</Link>
																	</div>	
																</td>
																<td className='amount'>
																	<div className="group-follower-btns">
																		<CopyToClipboard
																			text={allCustomListUser.user.share_link}
																			onCopy={() => {
																				const notificationMessage = getSuccessNotificationMessage(
																					t('profile_link_copied')
																				);
																				dispatch(createNotification(notificationMessage));
																			}}
																		>
																			<Button
																				type="button"
																				className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
																			>
																				<Image
																					src={window.location.origin
																						+ "/assets/images/icons/share.svg"
																					}
																					className="svg-clone "
																				/>
																			</Button>
																		</CopyToClipboard>
																	</div>
																</td>
																<td className='amount'>
																	<div className="swiper-favorite">
																		
																			<Link
																				type="button"
																				className="swiper-btn-fav"
																				onClick={(event) => handleStar(event, allCustomListUser.user.user_id)} 
																			>
																				{
																					allCustomListUser?.user?.is_fav_user == 1 
																						?
																							<Image
																								src={window.location.origin +
																									"/assets/images/icons/star-active.svg"
																								}
																								className="svg-clone my-p-icons"
																								width="12"
																							/>
																						:
																						<Image
																							src={window.location.origin +
																								"/assets/images/icons/star.svg"
																							}
																							className="svg-clone my-p-icons"
																							width="12"
																						/>
																				}
																				
																			</Link>
																	</div>
																</td>
																<td className='amount'>
																	<div>
																		<span
																		  style={{
																				padding: "10px",
																				backgroundColor: "#6661e7	",
																				display: "flex",
																				alignItems: "center",
																				width: "50px",
																				height: "50px",
																				borderRadius: "50%",
																				justifyContent: "center",
																				cursor: "pointer"
																			}}
																			onClick={() => {
																				openSendTipModel()
																				handleTipModel({
																					username:allCustomListUser.user.username,
																					userPicture:allCustomListUser.user.picture,
																					name:allCustomListUser.user.usernamee,
																					post_id:null,
																					user_id:allCustomListUser.user.user_id
																				})}
																			}
																		>
																			<Image
																				src={window.location.origin+"/assets/images/icons/tip.svg"}
																				className="svg-clone"
																			/>
																		</span>
																	</div>
																</td>
															</tr>
														) : (
															""
														)
													)
												)
											: (
												<NoDataFound></NoDataFound>
											)
									}
								</tbody>
							</Table>
							<SendTipModal
								sendTip={sendTip}
								closeSendTipModal={closeSendTipModal}
								username={tipModel.username}
								userPicture={tipModel.userPicture}
								name={tipModel.name}
								post_id={null}
								user_id={tipModel.user_id}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default CustomeListView