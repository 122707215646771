import React from "react";
import { Button, Modal } from "react-bootstrap";
import { t } from "react-multi-lang";

const EditProfileSubModal = ({
  isEditModalOpen,
  handleEditModalClose,
  isSubSet,
  udateSubscriptionDetails,
}) => {
  return (
    <>
      <Modal
        show={isEditModalOpen}
        onHide={handleEditModalClose}
        backdrop="static"
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }
        `}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {t("cancel_subscription_conformation")} */}
          {isSubSet ? (
            <>
              <p style={{fontSize: "15px", fontWeight: "500"}}>Update the price of your subscription plan.</p>
              <p style={{fontSize: "13px"}}>
                Your subscribers will be notified instantly. The collection of
                new price will be started on the first day of next month
                together with the updated price shown on your wall.
              </p>
            </>
          ) : (
            <>
              <p style={{fontSize: "15px", fontWeight: "500"}}>
                Update the price of your subscription plan? The new updated
                price will be shown on your wall instantly.
              </p>
              <p style={{fontSize: "13px"}}>
                When you gained your first subscriber, you will not be able to
                update the price on your wall instantly. Although your
                subscribers will be notified instantly. The collection of new
                price will be started on the first day of next month together
                with the updated price shown on your wall.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="lg"
            onClick={() => handleEditModalClose()}
          >
            {/* {t("close")} */} Back
          </Button>
          <Button
            variant=""
            size="lg"
            className="ftr-btn"
            onClick={(event) => udateSubscriptionDetails(event)}
          >
            {t("update")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditProfileSubModal;
