import {
  GET_KYC_DOCUMENT_START,
  GET_KYC_DOCUMENT_SUCCESS,
  GET_KYC_DOCUMENT_FAILURE,
  ADD_KYC_DOCUMENT_START,
  ADD_KYC_DOCUMENT_SUCCESS,
  ADD_KYC_DOCUMENT_FAILURE,
  SENT_OTP_START,
  SENT_OTP_SUCCESS,
  SENT_OTP_FAILURE,
  VERIFY_OTP_START,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  Add_PRIMARIY_INFO_SETP_8_START,
  Add_PRIMARIY_INFO_SETP_8_SUCCESS,
  Add_PRIMARIY_INFO_SETP_8_FAILURE,
} from "./ActionConstant";

// Get kyc document actions.

export function getKycDocumentStart(data) {
  return {
    type: GET_KYC_DOCUMENT_START,
    data,
  };
}

export function getKycDocumentSuccess(data) {
  return {
    type: GET_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function getKycDocumentFailure(error) {
  return {
    type: GET_KYC_DOCUMENT_FAILURE,
    error,
  };
}

// add kyc document actions.

export function addKycDocumentStart(data) {
  return {
    type: ADD_KYC_DOCUMENT_START,
    data,
  };
}

export function addKycDocumentSuccess(data) {
  return {
    type: ADD_KYC_DOCUMENT_SUCCESS,
    data,
  };
}

export function addKycDocumentFailure(error) {
  return {
    type: ADD_KYC_DOCUMENT_FAILURE,
    error,
  };
}




export function setOtpStart(data) {
  return {
    type: SENT_OTP_START,
    data,
  };
}

export function setOtpSuccess(data) {
  return {
    type: SENT_OTP_SUCCESS,
    data,
  };
}

export function setOtpFailure(error) {
  return {
    type: SENT_OTP_FAILURE,
    error,
  };
}

export function verifyOtpStart(data) {
  return {
    type: VERIFY_OTP_START,
    data,
  };
}

export function verifyOtpSuccess(data) {
  return {
    type: VERIFY_OTP_SUCCESS,
    data,
  };
}

export function verifyOtpFailure(error) {
  return {
    type: VERIFY_OTP_FAILURE,
    error,
  };
}


export function addPrimaryInfoStart(data) {
  return {
    type: Add_PRIMARIY_INFO_SETP_8_START,
    data,
  };
}

export function addPrimaryInfoSuccess(data) {
  return {
    type: Add_PRIMARIY_INFO_SETP_8_SUCCESS,
    data,
  };
}

export function addPrimaryInfoFailure(error) {
  return {
    type: Add_PRIMARIY_INFO_SETP_8_FAILURE,
    error,
  };
}