import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import UserCard from "../FansFollowing/UserCard";
import NoDataFound from "../../NoDataFound/NoDataFound";
import FollowingLoader from "../../Loader/FollowingLoader";
import SubscribingCard from "../SubscribingCard";

const FormerSubscriberActiveSec = (props) => {
	return (
    <>
      <div
        role="tabpanel"
        className={
          props.activeSec === "active-sec"
            ? "tab-pane fade in active"
            : "tab-pane fade"
        }
        id="active"
      >
        {props.formerSubscriber.loading ? (
           <FollowingLoader/>
        ) : (
          <>
            <Row>
              {props.userData && props.userData.length > 0 ? (
                props.userData.map((item) => (
                  <SubscribingCard 
                    pagePath={props.pagePath}
                    userData={item} 
                    user={item.from_user} 
                    isFavUser={item?.is_fav_user} 
                    formerSubscriber={"formerSubscriber"} 
                    isFriend={item?.is_friend}
                    formerSubscriberType={props.pagePath === "formerSub" ? true : false}
                    setUserData={props?.setUserData}
                    setCheckedUserId={props?.setCheckedUserId}
                    checkedUserId={props?.checkedUserId}
                    isSelected={props?.isSelected}
                    isSelectedData={props?.userData}
                  />
                ))
              ) : (
                <NoDataFound></NoDataFound>
              )}
            </Row>
          </>
        )}
      </div>
    </>
  );
}

export default FormerSubscriberActiveSec