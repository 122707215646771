import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { t } from 'react-multi-lang'
import { addSubscriptionLevelStart } from '../../../store/actions/SubscriptionAction'
import { useDispatch } from 'react-redux'

const AddSubLevelModal = ({isNotSub ,isSubAndPrice ,isSubAddLevelModalOpen ,handleAddSubLModalClose , subLevelData, allSubLevelList, isLevelEditData}) => {
 
  const dispatch = useDispatch();

  return (
    <Modal
    show={isSubAddLevelModalOpen}
    onHide={handleAddSubLModalClose}
    backdrop="static"
    keyboard={false}
    centered
    className={`${
      localStorage.getItem("theme") !== "" &&
      localStorage.getItem("theme") !== null &&
      localStorage.getItem("theme") !== undefined &&
      localStorage.getItem("theme") === "dark"
        ? "dark-theme-modal"
        : ""
    }
    `}
  >
    <Modal.Header closeButton>
      <Modal.Title>Save your updates</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {/* {t("cancel_subscription_conformation")} */}
     
     {
      isNotSub && (
        <>
        <p style={{fontSize: "15px", fontWeight: "500"}}>
          You will not be able to edit the subscriber levels again in the upcoming 90 days after you gained your first subscriber. 
        </p>
       
        <p style={{fontSize: "13px"}}>
           The change of level will be updated instanly.
        </p>

        </>
      )
     }


     {
      isSubAndPrice && (
         <>
            <p style={{fontSize: "15px", fontWeight: "500"}}>
             You will not be able to edit the subscriber levels again in the upcoming 90 days. 
           </p>
           <p style={{fontSize: "13px"}}>
             Your subscribers will be notified instantly and the subscriber level table on your wall will be updated as well. The change of level will be started on the first day of month.
           </p>
         </>
      )
     }

    </Modal.Body>
    <Modal.Footer>
      <Button
        variant="secondary"
        size="lg"
        onClick={handleAddSubLModalClose}
      >
        {/* {t("close")} */} Back
      </Button>
      <Button
        variant=""
        size="lg"
        className="ftr-btn"
        onClick={(event) => { 
          dispatch(addSubscriptionLevelStart({subLevelData, handleClose : handleAddSubLModalClose , allSubLevelList, isLevelEditData }))}}
      >
        {t("update")}
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default AddSubLevelModal