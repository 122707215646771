import { call, select, put, takeLatest, all } from "redux-saga/effects";
import api from "../../Environment";
import { createNotification } from "react-redux-notify";
import {
  getSuccessNotificationMessage,
  getErrorNotificationMessage,
} from "../../components/helper/NotificationMessage";
import {
  deleteFavFailure,
  deleteFavSuccess,
  fetchFavFailure,
  fetchFavSuccess,
  saveFavFailure,
  saveFavSuccess,
} from "../actions/FavAction";
import {
  DELETE_FAV_START,
  FETCH_FAV_START,
  SAVE_FAV_START,
} from "../actions/ActionConstant";
import {
  checkLogoutStatus,
} from "../actions/ErrorAction";
import { addUserListSuccessD } from "../actions/CustomListAction";

function* fetchFavAPI({data}) {
  try {
     
    if(data.setIsLoadingList !== undefined){
			data.setIsLoadingList(true);
		}
    
    const inputData = yield select(
      (state) => state.fav.fav.inputData
    );

    const filterSortPayload = data?.filterSort ? data?.filterSort : {
			filter: '',
			sort: '',
			tag_title: '',
			tag_colour: '',
			category: "",
		  }
    const response = yield api.postMethod("fav_users", filterSortPayload);
    if (response.data.success) {
      if(data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }

      const updatedFavUser = response.data.data?.fav_users?.map((item) => {
        return {
          ...item,
          fav_user: {
            ...item.fav_user,
            isSelected: false
          }
        }
      })

      yield put(fetchFavSuccess({fav_users: updatedFavUser, total: response.data.data.total}));
       
      
      if(data !== undefined && data.allListData !== undefined){

				const updatedData = data.allListData.map((ele)=> {
				   if(ele.list_name === data.typeList){

            const updatedData = response?.data?.data?.fav_users.map((ele)=> {
              return {
                ...ele,
                customListFlag : "FavFromList"
              }
              })
	  
					return {
						 ...ele,
						 data : updatedData,
						 total : response?.data?.data?.total
					}
		  
				   }else{
					    return ele;
				   }
				})

				yield put(addUserListSuccessD(updatedData));			
      }

    } else {
      if(data.setIsLoadingList !== undefined){
        data.setIsLoadingList(false);
      }
      yield put(fetchFavFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    if(data.setIsLoadingList !== undefined){
			data.setIsLoadingList(false);
		}
    yield put(fetchFavFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* saveFavAPI({data}) {
  try {
    const inputData = yield select((state) => state.fav.saveFav.inputData);

    const newData = (data?.subscribingCard || data?.userCard || data?.restrictedCard || data?.customListCard) 
                      ? {user_id: data?.user_id} 
                      : inputData

    const response = yield api.postMethod("fav_users_save", newData);
    if (response.data.success) {

      yield put(saveFavSuccess(response.data.data));

      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(createNotification(notificationMessage));

      if (data?.subscribingType || data?.subscribersType || data?.unsubscribedType || data?.formerSubscriberType) {
        const updatedData = (data?.subscribersType || data?.formerSubscriberType)
          ?
            data?.subscribeCardData?.map(item => {
              if (Number(response.data?.data?.fav_user_id) === item?.from_user?.user_id) {
                return {
                  ...item,
                  is_fav_user: response?.data?.data?.status ? 0 : 1
                }
              } else {
                return item
              }
            })
          :
            data?.subscribeCardData?.map(item => {
              if (Number(response.data?.data?.fav_user_id) === item?.to_user?.user_id) {
                return {
                  ...item,
                  is_fav_user: response?.data?.data?.status ? 0 : 1
                }
              } else {
                return item
              }
            })

        return data?.setUserData(updatedData)
      }


      if (data?.followingType || data?.followersType || data?.friendType) {
        const updatedData = data?.friendType
          ?
            data?.userCardData?.map(item => {
              if (Number(response.data?.data?.fav_user_id) === item?.user?.user_id) {
                return {
                  ...item,
                  is_fav_user: response?.data?.data?.status ? 0 : 1
                }
              } else {
                return item
              }
            })
          :
            data?.userCardData?.map(item => {
              if (Number(response.data?.data?.fav_user_id) === item?.otherUser?.user_id) {
                return {
                  ...item,
                  otherUser: {
                    ...item.otherUser,
                    is_fav_user: response?.data?.data?.status ? 0 : 1
                  }
                }
              } else {
                return item
              }
            })

        return data?.setUserData(updatedData)
      }

      if (data?.restrictedType) {
        const updatedData = data?.userCardData?.map(item => {
        if (Number(response.data?.data?.fav_user_id) === item?.restricteduser?.user_id) {
            return {
              ...item,
              is_fav_user: response?.data?.data?.status ? 0 : 1
            }
          } else {
            return item
          }
        })

        return data?.setUserData(updatedData)
      }

      if (data?.customListType) {

        const updatedData = data?.userCardData?.map(item => {
          if (Number(response.data?.data?.fav_user_id) === item?.user?.user_id) {
              return {
              ...item,
              user: {
                ...item.user,
                is_fav_user: response?.data?.data?.status ? 0 : 1
              }
            }
          } else {
            return item
          }
        })
  
        return data?.setUserData(updatedData)
      }
      
    } else {
      yield put(saveFavFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(saveFavFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

function* deleteFavAPI() {
  try {
    const inputData = yield select((state) => state.fav.deleteFav.inputData);
    const response = yield api.postMethod("fav_users_delete", inputData);
    if (response.data.success) {
      yield put(deleteFavSuccess(response.data.data));
      const notificationMessage = getSuccessNotificationMessage(
        response.data.message
      );
      yield put(checkLogoutStatus(response.data));
      yield put(createNotification(notificationMessage));
    } else {
      yield put(deleteFavFailure(response.data.error));
      const notificationMessage = getErrorNotificationMessage(
        response.data.error
      );
      yield put(createNotification(notificationMessage));
    }
  } catch (error) {
    yield put(deleteFavFailure(error));
    const notificationMessage = getErrorNotificationMessage(error.message);
    yield put(createNotification(notificationMessage));
  }
}

export default function* pageSaga() {
  yield all([yield takeLatest(FETCH_FAV_START, fetchFavAPI)]);
  yield all([yield takeLatest(SAVE_FAV_START, saveFavAPI)]);
  yield all([yield takeLatest(DELETE_FAV_START, deleteFavAPI)]);
}
