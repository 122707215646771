import React from "react";
import { Accordion, Card, Form } from "react-bootstrap";



const AddListUserModel = ({
  labelType,
  singleData,
  setSelectedAccro,
  setSelectedCustomId,
  handleChangeValue,
  value,
  isLoadingList,
  index
}) => {
  console.log(singleData);
   
   

  return (

    singleData?.total_user > 0 ? (
      <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        marginTop: "6px",
      }}
    >
      <Accordion>
        <Card key={index.toString()}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey={index.toString()}
            onClick={() => {
              if (singleData?.cid !== "") {
                setSelectedCustomId(singleData?.CID);
              }
              setSelectedAccro(singleData?.DID  ? labelType : "custom_list" );
            }}
          >
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <p style={{marginBottom: "0px"}}>{labelType}</p>
              <p style={{marginBottom: "0px" , position: "relative", right: "36px" }}>{singleData?.total_user}</p>
            </div>
            

            
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index.toString()}>
            <Card.Body className="check-user" style={{ height: "100%" }}>
              <Form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >

                {
                      singleData?.data?.length ? (
                        singleData?.data?.map((ele, idx) => {
                          const keyID =
                             ele?.customListFlag === "FriendList"
                              ? ele?.user?.user_id
                              : ele?.customListFlag === "SubscribingList"
                              ? ele?.to_user?.user_id
                              : ele?.customListFlag === "FollowingList"
                              ? ele?.otherUser.user_id
                              : ele?.customListFlag === "BlockedList"
                              ? ele?.blockeduser?.user_id
                              : ele?.customListFlag === "SubsciberFromList"
                              ? ele?.from_user?.user_id
                              : ele?.customListFlag === "FollowerFromList"
                              ? ele?.otherUser?.user_id
                              : ele?.customListFlag === "FormerSubsciberFromList"
                              ? ele?.from_user?.user_id
                              : ele?.customListFlag === "UnsubscribedFromList"
                              ? ele?.to_user?.user_id
                              : ele?.customListFlag === "FavFromList"
                              ? ele?.fav_user?.user_id
                              : ele?.customListFlag === "customListF"
                              ? ele?.user?.user_id 
                              : 0
      
                              const userImg = 
                              ele?.customListFlag === "FriendList"
                              ? ele?.user?.picture
                              : ele?.customListFlag === "SubscribingList"
                              ? ele?.to_user?.picture
                              : ele?.customListFlag === "FollowingList"
                              ? ele?.otherUser.picture
                              : ele?.customListFlag === "BlockedList"
                              ? ele?.blockeduser?.picture
                              : ele?.customListFlag === "SubsciberFromList"
                              ? ele?.from_user?.picture
                              : ele?.customListFlag === "FollowerFromList"
                              ? ele?.otherUser?.picture
                              : ele?.customListFlag === "FormerSubsciberFromList"
                              ? ele?.from_user?.picture
                              : ele?.customListFlag === "UnsubscribedFromList"
                              ? ele?.to_user?.picture
                              : ele?.customListFlag === "FavFromList"
                              ? ele?.fav_user?.picture
                              : ele?.customListFlag === "customListF"
                              ? ele?.user?.picture
                              : ""
      
      
                              const userName =  
                              ele?.customListFlag === "FriendList"
                              ? ele?.user?.username
                              : ele?.customListFlag === "SubscribingList"
                              ? ele?.to_user?.username
                              : ele?.customListFlag === "FollowingList"
                              ? ele?.otherUser.username
                              : ele?.customListFlag === "BlockedList"
                              ? ele?.blockeduser?.username
                              : ele?.customListFlag === "SubsciberFromList"
                              ? ele?.from_user?.username
                              : ele?.customListFlag === "FollowerFromList"
                              ? ele?.otherUser?.username
                              : ele?.customListFlag === "FormerSubsciberFromList"
                              ? ele?.from_user?.username
                              : ele?.customListFlag === "UnsubscribedFromList"
                              ? ele?.to_user?.username
                              : ele?.customListFlag === "FavFromList"
                              ? ele?.fav_user?.username
                              : ele?.customListFlag === "customListF"
                              ? ele?.user?.username
                              : ""
      
                          return (
                            <div
                              style={{ display: "flex", gap: "9px" }}
                              key={keyID}
                            >
                              <input
                                type="checkbox"
                                name={`checkbox-${singleData?.index}`}
                                id={`checkbox-id-${singleData?.index}`}
                                style={{ margin: "0" }}
                                onChange={handleChangeValue}
                                value={keyID}
                                checked={value.includes(keyID?.toString())}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  style={{
                                    height: "29px",
                                    width: "29px",
                                    borderRadius: "50%",
                                  }}
                                  src={userImg}
                                  alt=""
                                />
                                <span>
                                  {userName}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p style={{ textAlign: "center" }}>Loading...</p>
                      )
                }

                {/* {
                  !isLoadingList ? (
                    
                    singleData?.data?.length ? (
                      singleData?.data?.map((ele, idx) => {
                        const keyID =
                           ele?.customListFlag === "FriendList"
                            ? ele?.user?.user_id
                            : ele?.customListFlag === "SubscribingList"
                            ? ele?.to_user?.user_id
                            : ele?.customListFlag === "FollowingList"
                            ? ele?.otherUser.user_id
                            : ele?.customListFlag === "BlockedList"
                            ? ele?.blockeduser?.user_id
                            : ele?.customListFlag === "SubsciberFromList"
                            ? ele?.from_user?.user_id
                            : ele?.customListFlag === "FollowerFromList"
                            ? ele?.otherUser?.user_id
                            : ele?.customListFlag === "FormerSubsciberFromList"
                            ? ele?.from_user?.user_id
                            : ele?.customListFlag === "UnsubscribedFromList"
                            ? ele?.to_user?.user_id
                            : ele?.customListFlag === "FavFromList"
                            ? ele?.fav_user?.user_id
                            : ele?.customListFlag === "customListF"
                            ? ele?.user?.user_id 
                            : 0
    
                            const userImg = 
                            ele?.customListFlag === "FriendList"
                            ? ele?.user?.picture
                            : ele?.customListFlag === "SubscribingList"
                            ? ele?.to_user?.picture
                            : ele?.customListFlag === "FollowingList"
                            ? ele?.otherUser.picture
                            : ele?.customListFlag === "BlockedList"
                            ? ele?.blockeduser?.picture
                            : ele?.customListFlag === "SubsciberFromList"
                            ? ele?.from_user?.picture
                            : ele?.customListFlag === "FollowerFromList"
                            ? ele?.otherUser?.picture
                            : ele?.customListFlag === "FormerSubsciberFromList"
                            ? ele?.from_user?.picture
                            : ele?.customListFlag === "UnsubscribedFromList"
                            ? ele?.to_user?.picture
                            : ele?.customListFlag === "FavFromList"
                            ? ele?.fav_user?.picture
                            : ele?.customListFlag === "customListF"
                            ? ele?.user?.picture
                            : ""
    
    
                            const userName =  
                            ele?.customListFlag === "FriendList"
                            ? ele?.user?.username
                            : ele?.customListFlag === "SubscribingList"
                            ? ele?.to_user?.username
                            : ele?.customListFlag === "FollowingList"
                            ? ele?.otherUser.username
                            : ele?.customListFlag === "BlockedList"
                            ? ele?.blockeduser?.username
                            : ele?.customListFlag === "SubsciberFromList"
                            ? ele?.from_user?.username
                            : ele?.customListFlag === "FollowerFromList"
                            ? ele?.otherUser?.username
                            : ele?.customListFlag === "FormerSubsciberFromList"
                            ? ele?.from_user?.username
                            : ele?.customListFlag === "UnsubscribedFromList"
                            ? ele?.to_user?.username
                            : ele?.customListFlag === "FavFromList"
                            ? ele?.fav_user?.username
                            : ele?.customListFlag === "customListF"
                            ? ele?.user?.username
                            : ""
    
                        return (
                          <div
                            style={{ display: "flex", gap: "9px" }}
                            key={keyID}
                          >
                            <input
                              type="checkbox"
                              name={`checkbox-${singleData?.index}`}
                              id={`checkbox-id-${singleData?.index}`}
                              style={{ margin: "0" }}
                              onChange={handleChangeValue}
                              value={keyID}
                              checked={value.includes(keyID?.toString())}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                style={{
                                  height: "29px",
                                  width: "29px",
                                  borderRadius: "50%",
                                }}
                                src={userImg}
                                alt=""
                              />
                              <span>
                                {userName}
                              </span>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <p style={{ textAlign: "center" }}>No Users</p>
                    )

                  ) : (
                    <p>Loading...</p>
                  ) 
                } */}
             
              </Form>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
    ) : ""
    
  );
};

export default AddListUserModel;
