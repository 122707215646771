import { ADD_CUSTOM_LIST_FAILURE, ADD_CUSTOM_LIST_START, ADD_CUSTOM_LIST_SUCCESS, ADD_USER_CUSTOM_LIST_FAILURE, ADD_USER_CUSTOM_LIST_START, ADD_USER_CUSTOM_LIST_SUCCESS, ALL_USER_CUSTOM_LIST_FAILURE, ALL_USER_CUSTOM_LIST_START, ALL_USER_CUSTOM_LIST_SUCCESS, DELETE_CUSTOM_LIST_START, DELETE_CUSTOM_LIST_SUCCESS, DELETE_USER_CUSTOM_LIST_FAILURE, DELETE_USER_CUSTOM_LIST_START, DELETE_USER_CUSTOM_LIST_SUCCESS, FRIREND_REQUEST_SEEN_FAILURE, FRIREND_REQUEST_SEEN_START, FRIREND_REQUEST_SEEN_SUCCESS, GET_CHECK_USER_CUSTOM_LIST_FAILURE, GET_CHECK_USER_CUSTOM_LIST_START, GET_CHECK_USER_CUSTOM_LIST_SUCCESS, GET_CUSTOM_LIST_FAILURE, GET_CUSTOM_LIST_START, GET_CUSTOM_LIST_SUCCESS, GET_FRIREND_REQUEST_SEEN_START, GET_SINGLE_SEARCH_USER_LIST_FAILURE, GET_SINGLE_SEARCH_USER_LIST_START, GET_SINGLE_SEARCH_USER_LIST_SUCCESS, GET_USER_ALL_COLOR_TAG_FAILURE, GET_USER_ALL_COLOR_TAG_START, GET_USER_ALL_COLOR_TAG_SUCCESS, GET_USER_CUSTOM_LIST_FAILURE, GET_USER_CUSTOM_LIST_START, GET_USER_CUSTOM_LIST_SUCCESS, LIST_UTILS_IS_FRIEND_REQUEST, LIST_UTILS_IS_LIST_UPDATE, SET_ADD_USER_VALUE_LIST_SUCCESS , POST_CUSTOM_FAVOURITE_LIST_FAILURE, POST_CUSTOM_FAVOURITE_LIST_START, POST_CUSTOM_FAVOURITE_LIST_SUCCESS,  } from "./ActionConstant"

export function addCustomListStart(data) {
	return {
		type: ADD_CUSTOM_LIST_START,
		payload: data		
	}
}

export function addCustomListSuccess(data) {
	return {
		type: ADD_CUSTOM_LIST_SUCCESS,
		payload: data
	}
} 

export function addCustomListFailure(error) {
	return {
		type: ADD_CUSTOM_LIST_FAILURE,
		error
	}
}

export function getCustomListStart(data) {
	return {
		type: GET_CUSTOM_LIST_START,
		payload: data
	}
}

export function getCustomListSuccess(data) {
	return {
		type: GET_CUSTOM_LIST_SUCCESS,
		payload: data
	}
}

export function getCustomListFailure(error) {
	return {
		type: GET_CUSTOM_LIST_FAILURE,
		error
	}
}

export function getCheckUserCustomListStart(data) {
	return {
		type: GET_CHECK_USER_CUSTOM_LIST_START,
		payload: data
	}
}

export function getCheckUserCustomListSuccess(data) {
	return {
		type: GET_CHECK_USER_CUSTOM_LIST_SUCCESS,
		payload: data
	}
}

export function getCheckUserCustomListFailure(error) {
	return {
		type: GET_CHECK_USER_CUSTOM_LIST_FAILURE,
		error
	}
}

export function deleteCustomListStart(data) {
	return {
		type: DELETE_CUSTOM_LIST_START,
		payload: data
	}
}

export function deleteCustomListSuccess(data) {
	return {
		type: DELETE_CUSTOM_LIST_SUCCESS,
		payload: data
	}
}

export function deleteCustomListFailure(error) {
	return {
		type: DELETE_CUSTOM_LIST_START,
		error
	}
}

export function addUserCustomListStart(data) {
	return {
		type: ADD_USER_CUSTOM_LIST_START,
		payload: data		
	}
}

export function addUserCustomListSuccess(data) {
	return {
		type: ADD_USER_CUSTOM_LIST_SUCCESS,
		payload: data		
	}
}

export function addUserCustomListFailure(error) {
	return {
		type: ADD_USER_CUSTOM_LIST_FAILURE,
		error
	}
}

export function getAllUserCustomListStart(data) {
	return {
		type: GET_USER_CUSTOM_LIST_START,
		payload: data		
	}
}

export function getAllUserCustomListSuccess(data) {
	return {
		type: GET_USER_CUSTOM_LIST_SUCCESS,
		payload: data		
	}
}

export function getAllUserCustomListFailure(error) {
	return {
		type: GET_USER_CUSTOM_LIST_FAILURE,
		error	
	}
}

export function deleteCustomUserListStart(data) {
	return {
		type: DELETE_USER_CUSTOM_LIST_START,
		payload: data
	}
}

export function deleteCustomUserListSuccess(data) {
	return {
		type: DELETE_USER_CUSTOM_LIST_SUCCESS,
		payload: data
	}
}

export function deleteCustomUserListFailure(error) {
	return {
		type: DELETE_USER_CUSTOM_LIST_FAILURE,
		error
	}
}


export function addUserListSuccessD(data) {
	return {
		type: SET_ADD_USER_VALUE_LIST_SUCCESS,
		payload : data
	}
}


export function allUserListStart(data) {
	return {
		type: ALL_USER_CUSTOM_LIST_START,
		payload : data
	}
}

export function allUserListSuccess(data) {
	return {
		type: ALL_USER_CUSTOM_LIST_SUCCESS,
		payload : data
	}
}
export function allUserListFailure(error) {
	return {
		type: ALL_USER_CUSTOM_LIST_FAILURE,
		error : error
	}
}



export function getUserAllColorTagStart(data) {
	return {
		type: GET_USER_ALL_COLOR_TAG_START,
		payload : data
	}
}

export function getUserAllColorTagSuccess(data) {
	return {
		type: GET_USER_ALL_COLOR_TAG_SUCCESS,
		payload : data
	}
}
export function getUserAllColorTagFailure(error) {
	return {
		type: GET_USER_ALL_COLOR_TAG_FAILURE,
		error : error
	}
}


export function getSingleSearchListUserStart(data) {
	return {
		type: GET_SINGLE_SEARCH_USER_LIST_START,
		payload : data
	}
}

export function getSingleSearchListUserSuccess(data) {
	return {
		type: GET_SINGLE_SEARCH_USER_LIST_SUCCESS,
		payload : data
	}
}
export function getSingleSearchListUserFailure(error) {
	return {
		type: GET_SINGLE_SEARCH_USER_LIST_FAILURE,
		error : error
	}
}


export function isListUtilsIsList(data) {
	return {
		type: LIST_UTILS_IS_LIST_UPDATE,
		payload : data
	}
}


export function isListFriendRequestUpdate(data) {
	return {
		type: LIST_UTILS_IS_FRIEND_REQUEST,
		payload : data
	}
}


export function friendRequestSeenStart(data) {
	return {
		type: FRIREND_REQUEST_SEEN_START,
		payload : data
	}
}


export function getFriendRequestSeenStart(data) {
	return {
		type: GET_FRIREND_REQUEST_SEEN_START,
		payload : data
	}
}


export function addUserCustomFavouriteListStart(data) {
	return {
		type: POST_CUSTOM_FAVOURITE_LIST_START,
		payload: data,
	}
}

export function addUserCustomFavouriteListSuccess(data) {
	return {
		type: POST_CUSTOM_FAVOURITE_LIST_SUCCESS,
		payload: data,
	}
}

export function addUserCustomFavouriteListFailure(error) {
	return {
		type: POST_CUSTOM_FAVOURITE_LIST_FAILURE,
	  error,
	}
}