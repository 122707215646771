import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import './RemoveUserFromList.css'
import { removeUserFromListStart } from "../../../store/actions/UserAction";

const RemoveUserFromList = (props) => {
	const {isRemoveUser, handleCloseRemoveUserModal, checkedUserId, setIsSelected, setUserData, userData, setCheckedUserId, listType} = props

	const dispatch = useDispatch();

	const isLoading = useSelector(
    (state) => state.users.removeUserFromList.loading
  );

	const handleRemoveUsers = () => {

		if (checkedUserId.length > 0) {
			dispatch(removeUserFromListStart({
				users_ids: checkedUserId.toString(),
				type: listType,
				handleCloseRemoveUserModal: handleCloseRemoveUserModal,
				setIsSelected: setIsSelected,
				setCheckedUserId: setCheckedUserId,
				setUserData: setUserData,
				userData: userData,
			}))
		}

		if (checkedUserId.length > 0 && listType === "custom_list") {
			dispatch(removeUserFromListStart({
				users_ids: checkedUserId.toString(),
				type: listType,
				handleCloseRemoveUserModal: handleCloseRemoveUserModal,
				setIsSelected: setIsSelected,
				setCheckedUserId: setCheckedUserId,
				setUserData: setUserData,
				userData: userData,
				custom_list_id: props?.custom_list_id
			}))
		}
		
	}

  return (
    <>
      <Modal
        show={isRemoveUser}
        onHide={handleCloseRemoveUserModal}
        keyboard={false}
        centered
        className={`${
          localStorage.getItem("theme") !== "" &&
          localStorage.getItem("theme") !== null &&
          localStorage.getItem("theme") !== undefined &&
          localStorage.getItem("theme") === "dark"
            ? "dark-theme-modal"
            : ""
        }`}
      >
				<Modal.Header closeButton>
					<Modal.Title>Remove users from {props.userList}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{/* <h2 className="remove__consent">Are you sure want to remove {checkedUserId.length === 1 ? 'user' : 'users'} from {props.userList}?</h2> */}
					<h2 className="remove__consent">
						{
							(listType === "custom_list" || listType === 'unsubscribe' || listType === 'favourite')
								?
									`Are you sure to remove this/these users from ${props.userList}?`
								:
									`Are you sure to ${props.removeType} this/these users?`
						}
					</h2>	
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						size="lg"
						onClick={() => {
							handleCloseRemoveUserModal();
						}}
					>
						Back
					</Button>
					<Button
						size="lg"
						className="ftr-btn"
						type="submit"
						onClick={handleRemoveUsers}
						disabled={isLoading}
					>
						{isLoading ? "Loading...": props.removeType}
					</Button>
				</Modal.Footer>
      </Modal>
    </>
  );
};

export default RemoveUserFromList;
