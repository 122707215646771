import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import { t, translate } from 'react-multi-lang'
import NoDataFound from '../NoDataFound/NoDataFound'
import WithdrawModal from '../helper/WithdrawModal'
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CancelWithdrawModal from '../helper/CancelWithdrawModal'
import { fetchAllTransactionStart, fetchMoreAllTransactionStart } from '../../store/actions/TransactionAction'
import { fetchWithDrawalsStart } from '../../store/actions/WithDrawAction'
import { fetchPaymentsStart, fetchUserDetailsStart } from '../../store/actions/UserAction'

const PaymentTableModal = (props) => {
  const [data, setData] = useState("");
  const [cancelWithdrawModal, setCancelWithdrawModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const closeCancelWithdrawModal = () => {
     setCancelWithdrawModal(false);
     setIsLoading(false);
  };
  
  const showCancelWithdrawModel = (event, data) => {
    setCancelWithdrawModal(true);
    setData(data);
    setIsLoading(true);
  };

    
      const fetchMoreData = () => {
        props.dispatch(
          fetchMoreAllTransactionStart({
            type : props.selecteTransaction === "all" ? "all" :  props.selecteTransaction === "earnings" ? "earnings" : props.selecteTransaction === "expenditure" ? "expenditure" : "all",
            skip: props.transaction.data.history.length,
            take: 12,
          })
        );
      };


      useEffect(() => {
        props.dispatch(fetchWithDrawalsStart());
        props.dispatch(fetchPaymentsStart());
      }, []);
  return (
    <div className="payment-sec">
    <Container>
      <Row>
        <Col sm={12} md={12} xl={12} lg={12}>
          <div className="payment-tabs-card">
            <div className="flex-content">
              <h2>{t("statements")}</h2>

              {
                props?.certifiedCreator?.is_certified_content_creator === 2?
                props.selectTab === "earnings" && (
                    <select onChange={props.handleSelectChange} value={props.selecteTransaction} style={{padding: "4px", fontSize: '13px', borderRadius: "7px"}} name="paymentTransFilter" id="paymentTransFilterId">
                         <option value="all">All</option>
                         <option value="expenditure">Expenditure</option>
                         <option value="earnings">Earnings</option>
                    </select>
                )
                : null
               }

            </div>
            <Tabs defaultActiveKey={props.selectTab} onSelect={(k)=> props.setSelectTab(k)} id="uncontrolled-tab-example">
               
             
              <Tab eventKey="earnings" title="Transactions">
                <div className="payment-tabs-content">
                  {props.transaction.loading ? (
                    t("loading")
                  ) : props.transaction.data.history.length > 0 ? (
                    <InfiniteScroll
                    dataLength={props.transaction.data.history.length}
                    next={fetchMoreData}
                    hasMore={
                      props.transaction.data.history.length <
                        props.transaction.data.total &&
                      props.transaction.errorCount < 2
                    }
                    loader={<h4>{t("loading")}</h4>}
                  >
                    <Table borderedless responsive>
                      <thead>
                        <tr className="bg-white">
                          <th>{t("date")}</th>
                          <th>{t("transaction_id")}</th>
                          <th>{t("type_statement")}</th>
                          <th>{t("mode")}</th>
                          <th>{t("message")}</th>
                          <th>{t("amount")}</th>
                          <th>{t("service_fee")}</th>
                          <th>{t("status")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.transaction.data.history.map((history, index) => (
                          <tr>
                            <td>{history.paid_date}</td>
                            <td>{history.payment_id}</td>
                            <td style={{textTransform: "uppercase"}}>{history.usage_type}</td>
                            <td>{history.payment_mode}</td>
                            <td>{history.message}</td>
                            <td>{history.paid_amount_formatted}</td>
                            <td>{history.admin_amount_formatted}</td>
                            <td>
                              <p>
                                <i className="far fa-check-circle mr-2"></i>
                                  { history?.amount_type === "minus"  ? history.status_formatted : "Earned" }  
                              </p>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    </InfiniteScroll>
                  ) : (
                    <NoDataFound />
                  )}
                </div>
              </Tab>

              {
                props?.certifiedCreator?.is_certified_content_creator === 2
                 &&
                (
                <Tab eventKey="payments" title="Withdraws">
                  <div className="payment-tabs-content">
                    {props.withDrawals.loading ? (
                      t("loading")
                    ) : props.withDrawals.data.history.length > 0 ? (
                      <Table borderedless responsive>
                        <thead>
                          <tr className="bg-white">
                            <th>{t("date")}</th>
                            <th>{t("transaction_id")}</th>
                            <th>{t("billing_account")}</th>
                            <th>{t("requested")}</th>
                            <th>{t("paid")}</th>
                            <th>{t("status")}</th>
                            <th>{t("action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.withDrawals.data.history.map(
                            (withDrawRequest) => (
                              <tr>
                                <td>{withDrawRequest.created}</td>
                                <td>
                                  {withDrawRequest.user_withdrawal_unique_id}
                                </td>
                                <td>{withDrawRequest.billing_account_name}</td>
                                <td>
                                  {withDrawRequest.requested_amount_formatted}
                                </td>
                                <td>{withDrawRequest.paid_amount_formatted}</td>
                                <td>{withDrawRequest.status_formatted}</td>
                                <td>
                                  {withDrawRequest.cancel_btn_status == 1 ? (
                                    <Button
                                      onClick={(event) =>
                                        showCancelWithdrawModel(
                                          event,
                                          withDrawRequest
                                        )
                                      }
                                      className="cancel-btn"
                                    >
                                      {t("cancel")}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    ) : (
                      <NoDataFound />
                    )}
                  </div>
                </Tab>
                )
              }
              

            </Tabs>
          </div>
        </Col>
      </Row>
    </Container>
    <CancelWithdrawModal
        closeCancelWithdrawModal={closeCancelWithdrawModal}
        cancelWithdrawModal={cancelWithdrawModal}
        data={data}
        loading={isLoading}
      />
  </div>
  )
}


const mapStateToPros = (state) => ({
    withDrawals: state.withDraw.withDrawals,
    transaction: state.transaction.allTransaction,
    payments: state.users.payments,
  });
  
  function mapDispatchToProps(dispatch) {
    return { dispatch };
  }
  
  export default connect(
    mapStateToPros,
    mapDispatchToProps
  )(translate(PaymentTableModal));
