import { useEffect, useRef } from 'react';

const UseIntersectionObserver = (callback) => {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (callback) {
            callback(entry);
          }
        });
      },
      { threshold: 0.5 }
    );

    return () => observer.current.disconnect();
  }, [callback]);

  const observe = (element) => {
    if (observer.current && element) {
      observer.current.observe(element);
    }
  };

  const unobserve = (element) => {
    if (observer.current && element) {
      observer.current.unobserve(element);
    }
  };

  return { observe, unobserve };
};

export default UseIntersectionObserver;
