import React from "react";

const PostForwardUserCard = ({user, handleUserCheck}) => {
  return (
    <label htmlFor={`user-id-${user?.follower_id}`} className="post_forward_user_card ">
      <div className="post_forward_user_card_details">
        <img
          style={{ height: "47px" , width: "47px" , borderRadius: "50%", objectFit: "fill" }}
          src={user?.picture || ""}
          alt="profile_pic"
        />
        <div className="post_forward_user_card_user_info">
          <p style={{fontSize: "13px", fontWeight: "600"}}>{user?.name}</p>
          <p style={{fontSize: "12px", color: "grey", fontWeight: "300"}}>{user?.username}</p>
        </div>
      </div>
      <input type="checkbox" onChange={(e) => handleUserCheck(e, user)} name={`user-name-${user?.follower_id}`} id={`user-id-${user?.follower_id}`} checked={user?.isActive}/> 
    </label>
  );
};

export default PostForwardUserCard;
