import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Dropdown, Image, Media, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { createNotification } from "react-redux-notify/lib/modules/Notifications";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { translate, t } from "react-multi-lang";
import SendTipModal from "../helper/SendTipModal";
import { deleteFavStart, saveFavStart } from "../../store/actions/FavAction";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/NotificationMessage";
import {
  saveBlockUserStart,
  saveRestrictUserStart,
  saveTagUntagUserStart,
} from "../../store/actions/UserAction";
import {
  unFollowUserStart,
  unFollowUserSubStart,
} from "../../store/actions/FollowAction";
import VerifiedBadgeNoShadow from "../Handlers/VerifiedBadgeNoShadow";
import AddUserCustomUserList from "./AddUserList/AddUserCustomUserList";
import { getCheckUserCustomListStart } from "../../store/actions/CustomListAction";
import { fetchListsDetailsStart } from "../../store/actions/HomeAction";
import {
  addFriendRequestStart,
  removeRejectFriendRequestStart,
} from "../../store/actions/FriendRequestAction";
import { saveChatUserStart } from "../../store/actions/ChatAction";
import { useHistory } from "react-router-dom";
import { subscriptionPaymentStripeStart } from "../../store/actions/SubscriptionAction";
import SubscriptionPaymentModal from "../Model/PaymentModal/SubscriptionPaymentModal";
import ReportUserModel from "./ReportModel/ReportUserModel";
import TagUntagUser from "./TagUntagUser/TagUntagUser";

const SubscribingCard = (props) => {
  const [sendTip, setSendTip] = useState(false);

  const closeSendTipModal = () => {
    setSendTip(false);
  };

  const [addFav, setAddFav] = useState(false);
  const [favStatus, setFavStatus] = useState("");

  const [badgeStatus, setBadgeStatus] = useState(props.user?.is_verified_badge);
  const [blockUserStatus, setBlockUserStatus] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState("");
  const [showUnfollow, setShowUnfollow] = useState(false);

  const [skipRender, setSkipRender] = useState(true);

  const [isMoveModalOpen, setIsMoveModalOpen] = useState(false);

  const [userId, setUserId] = useState("");
  const [subscriptionData, setSubscriptionData] = useState({
    is_free: 0,
    plan_type: "months",
    amount: 0,
    amount_formatted: 0,
  });

  const [subscrptionPayment, setPaymentModal] = useState(false);
  const [reportMode, setReportMode] = useState(false);
  const [tagUntagMode, setTagUntagMode] = useState(false);

  const handleMoveModalClose = () => setIsMoveModalOpen(false);
  const handleMoveModalOpen = () => setIsMoveModalOpen(true);

  const history = useHistory();

  const isCheckUserCustom = useSelector(
    (state) => state.customList.getCheckUserCustomList.data
  );

  const customListCount = useSelector((state) => state.home.lists.data);

  const dispatch = useDispatch();
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const { subscribers, subscribingUser, unsubscribed, formerSubscriber } =
    useSelector((state) => state.subscriber);

  const closeAddFavModal = () => {
    setAddFav(false);
  };

  const removeFav = () => {
    props.dispatch(deleteFavStart({ user_id: props.user.user_id }));
  };

  const handleUnfollowModalClose = () => setShowUnfollow(false);
  const handleUnfollowModalShow = () => setShowUnfollow(true);

  const closeReportModeModal = () => setReportMode(false);

  const handleStar = (event, status) => {
    event.preventDefault();
    // setFavStatus(status);
    props.dispatch(
      saveFavStart({
        user_id: props.user.user_id,
        subscribingType: props?.subscribingType,
        subscribersType: props?.subscribersType,
        unsubscribedType: props?.unsubscribedType,
        formerSubscriberType: props?.formerSubscriberType,
        setUserData: props?.setUserData,
        subscribeCardData: props?.isSelectedData,
        subscribingCard: true
      })
    );
  };
  const handleBlockUser = (event, status) => {
    event.preventDefault();
    setBlockUserStatus(status);
    props.dispatch(
      saveBlockUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleRestrictUser = (event) => {
    event.preventDefault();

    props.dispatch(
      saveRestrictUserStart({
        user_id: props.user.user_id,
      })
    );
  };

  const handleUnfollowUser = (event, status) => {
    event.preventDefault();
    setSubscribeStatus(status);
    if (window.confirm(t("unfollowing")))
      props.dispatch(
        unFollowUserStart({
          user_id: props.user.user_id,
        })
      );
  };

  const handleUnfollow = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserStart({
        user_id: user_id,
      })
    );
  };

  const handleUnSubscriber = (event, user_id) => {
    event.preventDefault();
    props.dispatch(
      unFollowUserSubStart({
        user_id: user_id,
      })
    );
  };

  const onCopy = (event) => {
    const notificationMessage = getSuccessNotificationMessage(
      t("profile_link_copied")
    );
    props.dispatch(createNotification(notificationMessage));
  };

  const handleSetUserId = (e, id) => {
    e.preventDefault();
    setUserId(id);

    dispatch(
      getCheckUserCustomListStart({
        user_id: id,
      })
    );
  };

  const handleCheckUserList = (event) => {
    event.preventDefault();

    handleMoveModalOpen();
  };

  function handleCheckToast(event) {
    event.preventDefault();
    const notificationMessage = getSuccessNotificationMessage(
      "User is already present in all custom list"
    );
    props.dispatch(createNotification(notificationMessage));
  }

  useEffect(() => {
    if (
      !skipRender &&
      !props.saveChatUser.loading &&
      Object.keys(props.saveChatUser.data).length > 0
    ) {
      history.push("/inbox");
    }
    setSkipRender(false);
  }, [props.saveChatUser]);

  const handleChatUser = (event, user_id) => {
    event.preventDefault();
    if (!localStorage.getItem("userId")) {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    } else {
      props.dispatch(
        saveChatUserStart({
          from_user_id: localStorage.getItem("userId"),
          to_user_id: user_id,
        })
      );
    }
  };

  const closePaymentModal = () => {
    setPaymentModal(false);
  };

  const subscriptionPayment = (
    event,
    plan_type,
    amount,
    amount_formatted,
    is_free = 0
  ) => {
    event.preventDefault();
    if (localStorage.getItem("userId")) {
      setSubscriptionData({
        ...subscriptionData,
        is_free: is_free,
        plan_type: plan_type,
        amount: amount,
        amount_formatted: amount_formatted,
      });
      setPaymentModal(true);
    } else {
      const notificationMessage = getErrorNotificationMessage(
        t("login_to_continue")
      );
      props.dispatch(createNotification(notificationMessage));
    }
  };

  const handleReportModel = (event) => {
    event.preventDefault();
    setReportMode(true);
  };

  const handleTagUntagModel = (event) => {
    event.preventDefault();

    setTagUntagMode(true);
  };

  const closeTagUntagModel = () => setTagUntagMode(false);

  const untagColor = ((props?.subscribingType &&
    props.userData?.tag_info &&
    Object.keys(props.userData?.tag_info).length > 0) ||
    (props?.subscribersType &&
      props.userData?.tag_info &&
      Object.keys(props.userData?.tag_info).length > 0) ||
    (props?.formerSubscriberType &&
      props.userData?.tag_info &&
      Object.keys(props.userData?.tag_info).length > 0) ||
    (props?.unsubscribedType &&
      props.userData?.tag_info &&
      Object.keys(props.userData?.tag_info).length > 0)) ? true : false

  const handleRemoveTag = (event) => {
    event.preventDefault();

    if (props?.subscribingType) {
      props.dispatch(
        saveTagUntagUserStart({
          user_id: userId,
          untag: true,
          subscribingType: props?.subscribingType,
          tag_colour_code: untagColor ? props.userData?.tag_info?.colour : undefined,
          tag_title:  untagColor ? props.userData?.tag_info?.tag_title : undefined
        })
      );
    }
    if (props?.subscribersType) {
      props.dispatch(
        saveTagUntagUserStart({
          user_id: userId,
          untag: true,
          subscribersType: props?.subscribersType,
          tag_colour_code: untagColor ? props.userData?.tag_info?.colour : undefined,
          tag_title:  untagColor ? props.userData?.tag_info?.tag_title : undefined
        })
      );
    }
    if (props?.formerSubscriberType) {
      props.dispatch(
        saveTagUntagUserStart({
          user_id: userId,
          untag: true,
          formerSubscriberType: props?.formerSubscriberType,
          tag_colour_code: untagColor ? props.userData?.tag_info?.colour : undefined,
          tag_title:  untagColor ? props.userData?.tag_info?.tag_title : undefined
        })
      );
    }
    if (props?.unsubscribedType) {
      props.dispatch(
        saveTagUntagUserStart({
          user_id: userId,
          untag: true,
          unsubscribedType: props?.unsubscribedType,
          tag_colour_code: untagColor ? props.userData?.tag_info?.colour : undefined,
          tag_title:  untagColor ? props.userData?.tag_info?.tag_title : undefined
        })
      );
    }
  };

  const handleUserChecked = (e, id) => {

    const { setUserData, setCheckedUserId, checkedUserId } = props;
    const updatedUserSelected = (props?.subscribersType || props?.formerSubscriberType)
    ?
      props?.isSelectedData?.map((elem) => {
        if (elem?.from_user_id === id) {
          return {
            ...elem,
            isSelected: e.target.checked,
          }
        } else {
          return elem
        }
      })
    :
      props?.isSelectedData?.map((elem) => {
        if (elem?.to_user_id === id) {
          return {
            ...elem,
            isSelected: e.target.checked,       
          }
        } else {
          return elem;
        }
      });

    setTimeout(() => {
      setUserData(updatedUserSelected);
    }, 20);

    if (e.target.checked === true) {
      setTimeout(() => {
        setCheckedUserId((prev) => [...prev, id]);
      }, 10);
    }

    if (e.target.checked === false) {
      const filterCheckUserID = checkedUserId?.filter((elem) => elem !== id);
      setTimeout(() => {
        setCheckedUserId(filterCheckUserID);
      }, 10);
    }
  };

  return (
    <>
      <Col sm={12} md={6} lg={4} xs={12}>
        <div className="follower-lists">
          <div className="follower-subscription-lists">
            <div className="follower-subscription-inner">
              <Link to={`/` + props?.user?.user_unique_id}>
                <div className="follower-wrapper">
                  {props?.isSelected && (
                    <input
                      type="checkbox"
                      className="tick-box"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "8px",
                        zIndex: "1",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      checked={props?.userData?.isSelected}
                      onChange={(e) =>
                        handleUserChecked(e, props?.user?.user_id)
                      }
                    />
                  )}
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      position: "absolute",
                      top: "10px",
                      right: "1px",
                      borderRadius: "5px",
                      zIndex: "1",
                      color: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "0 12px",
                    }}
                  >
                    <p style={{ margin: 0, fontSize: "11px" }}>
                      {props?.user?.user_account_type_formatted}
                    </p>
                  </div>
                  <Image className="follower-cover" src={props?.user?.cover} />
                </div>
              </Link>
              <div className="follower-profile-header">
                <Link to={`/` + props?.user?.user_unique_id}>
                  <span className="follower-profile-avatar">
                    <Image src={props?.user?.picture} className="" />
                  </span>
                </Link>
                <div className="follower-info">
                  <div className="follower-profile-status">
                    <div
                      className="follower-status-text"
                      style={{ display: "none" }}
                    >
                      {t("last_seen")}
                      <span title="User Updated">{props.user.updated}</span>
                    </div>
                    <div className="follower-profile-toggle-dropdown">
                      <Dropdown
                        show={isShowDropDown}
                        onToggle={(isOpen) => setIsShowDropDown(isOpen)}
                        className="btn dropdown-toggle btn-link"
                        onClick={(e) => handleSetUserId(e, props.user.user_id)}
                      >
                        <Dropdown.Toggle
                          className="user-dropdown-dots dropdown-toggle"
                          type="button"
                          id="dropdown-basic"
                        >
                          <Image
                            src={
                              window.location.origin +
                              "/assets/images/icons/vertical-dots-white.svg"
                            }
                            className="svg-clone vertical-dots"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                          {customListCount?.total_Custom_list !==
                            Number(false) && (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => {
                                  if (isCheckUserCustom.length > 0) {
                                    handleCheckUserList(event);
                                  } else {
                                    handleCheckToast(event);
                                  }
                                }}
                              >
                                Move to custom list
                              </Link>
                            </Media>
                          )}

                          <Media as="li">
                            <Link
                              to="#"
                              onClick={(event) => handleReportModel(event)}
                            >
                              {t("report")}
                            </Link>
                          </Media>

                          {props.userData?.tag_info &&
                          Object.keys(props.userData?.tag_info).length > 0 ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => handleRemoveTag(event)}
                              >
                                {/* {t("report")} */}
                                Untag
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => handleTagUntagModel(event)}
                              >
                                {/* {t("report")} */}
                                Tag
                              </Link>
                            </Media>
                          )}

                          {props.userData.is_friend == 1 && (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={() => {
                                  props.dispatch(
                                    removeRejectFriendRequestStart({
                                      request_id: props?.friendListID,
                                      friendData: props?.friendData,
                                      from: "card",
                                      type: "remove",
                                      user_id: props.user.user_id,
                                      pagePath: props.pagePath,
                                      activeSubscribers: subscribers.data,
                                      activeSubscribing: subscribingUser.data,
                                      activeUnsubscriberd: unsubscribed.data,
                                      activeFormerSub: formerSubscriber.data,
                                    })
                                  );

                                  setIsShowDropDown(false);

                                  // props.dispatch(addFriendRequestStart({user_id : props.user.user_id}))
                                }}
                              >
                                {" "}
                                {t("Unfriend")}{" "}
                              </Link>
                            </Media>
                          )}

                          {props.userData.is_friend == 1 ||
                          props.userData.is_request_pending == 1 ? (
                            ""
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={() => {
                                  props.dispatch(
                                    addFriendRequestStart({
                                      user_id: props.user.user_id,
                                      activeSubscribers: subscribers.data,
                                      activeSubscribing: subscribingUser.data,
                                      activeUnsubscriberd: unsubscribed.data,
                                      activeFormerSub: formerSubscriber.data,
                                    })
                                  );

                                  setIsShowDropDown(false);
                                }}
                              >
                                {" "}
                                {t("add_friend")}{" "}
                              </Link>
                            </Media>
                          )}

                          <CopyToClipboard
                            text={props.user.share_link}
                            onCopy={onCopy}
                          >
                            <Media as="li">
                              <Link to="#"> {t("copy_link_to_profile")} </Link>
                            </Media>
                          </CopyToClipboard>

                          {blockUserStatus != "" ? (
                            blockUserStatus == "unblocked" ? (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "blocked")
                                  }
                                >
                                  {t("block_the_user")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleBlockUser(event, "unblocked")
                                  }
                                >
                                  {t("unblock_the_user")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.is_block_user == 1 ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "unblocked")
                                }
                              >
                                {t("unblock_the_user")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleBlockUser(event, "blocked")
                                }
                              >
                                {t("block_the_user")}
                              </Link>
                            </Media>
                          )}

                          {props.userData?.is_resticted === 0 ? (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => handleRestrictUser(event)}
                              >
                                {t("restrict_the_user")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) => handleRestrictUser(event)}
                              >
                                {t("unrestrict_the_user")}
                              </Link>
                            </Media>
                          )}

                          {/* {props.subscribers === "subscribers" ? (
                            <Media as="li">
                              <Link to={`/` + props.user.user_unique_id}>
                                {t("subscribe")}
                              </Link>
                            </Media>
                          ) : subscribeStatus != "" ? (
                            subscribeStatus == "unsubscribed" ? (
                              <Media as="li">
                                <Link to={`/` + props.user.user_unique_id}>
                                  {t("subscribe")}
                                </Link>
                              </Media>
                            ) : (
                              <Media as="li">
                                <Link
                                  to="#"
                                  onClick={(event) =>
                                    handleUnfollowUser(event, "unsubscribed")
                                  }
                                >
                                  {t("unsubscribe")}
                                </Link>
                              </Media>
                            )
                          ) : props.user.show_follow ? (
                            <Media as="li">
                              <Link to={`/` + props.user.user_unique_id}>
                                {t("subscribe")}
                              </Link>
                            </Media>
                          ) : (
                            <Media as="li">
                              <Link
                                to="#"
                                onClick={(event) =>
                                  handleUnSubscriber(event, props.user.user_id)
                                }
                              >
                                {t("unsubscribe")}
                              </Link>
                            </Media>
                          )} */}
                        </Dropdown.Menu>
                      </Dropdown>
                      {reportMode && (
                        <ReportUserModel
                          reportMode={reportMode}
                          closeReportModeModal={closeReportModeModal}
                          userId={userId}
                        />
                      )}
                      {tagUntagMode && (
                        <TagUntagUser
                          tagUntagMode={tagUntagMode}
                          closeTagUntagModel={closeTagUntagModel}
                          userId={userId}
                          subscribingType={props?.subscribingType}
                          subscribersType={props?.subscribersType}
                          formerSubscriberType={props?.formerSubscriberType}
                          unsubscribedType={props?.unsubscribedType}
                        />
                      )}
                      {isMoveModalOpen && (
                        <AddUserCustomUserList
                          isMoveModalOpen={isMoveModalOpen}
                          handleMoveModalClose={handleMoveModalClose}
                          handleMoveModalOpen={handleMoveModalOpen}
                          isCheckUserCustom={isCheckUserCustom}
                          user_id={userId}
                        />
                      )}
                    </div>
                  </div>
                  <div className="follower-wrapper-name">
                    <div className="follower-profile-names">
                      {/* <div className="follower-name-row">
                        <Link to={props.user.user_unique_id}>
                          <div className="follower-user-name">
                            {props.user.name}{" "}
                            {badgeStatus == 1 ? (
                              <VerifiedBadgeNoShadow />
                            ) : null}
                          </div>
                        </Link>
                      </div> */}
                      {/* <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-id">
                            @{props.user.username}
                          </div>
                        </Link>
                      </div> */}
                      <div className="follower-name-row">
                        <Link
                          to={`/` + props.user.user_unique_id}
                          className="g-user-realname__wrapper"
                        >
                          <div className="follower-user-name">
                            <span style={{display: "flex", gap: "7px"}}>
                            {props.user.username}

                            {props?.user?.is_certified_content_creator ===
                              2 ? (
                                <Image
                                  // style={{ marginBottom: "2px" }}
                                  className="sidebar-verified-icon"
                                  src={
                                    window.location.origin +
                                    "/assets/images/new-home/verified-icon.svg"
                                  }
                                />
                              ) : null}

                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>

                    <div className="group-follower-btns">
                      <CopyToClipboard
                        text={props.user.share_link}
                        onCopy={onCopy}
                      >
                        <Button
                          type="button"
                          className="g-btn m-rounded m-border m-icon m-icon-only m-colored has-tooltip"
                        >
                          <Image
                            src="assets/images/icons/share.svg"
                            className="svg-clone "
                          />
                        </Button>
                      </CopyToClipboard>
                    </div>
                    {props.userData?.is_resticted === 0 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "15px",
                          height: "48px",
                        }}
                        className="message-icon"
                      >
                        <Media as="li">
                          <Link
                            to="#"
                            onClick={(event) =>
                              handleChatUser(event, props.user.user_id)
                            }
                          >
                            <span>
                              <Image
                                className="sidebar-links-icon"
                                src={
                                  window.location.origin +
                                  "/assets/images/new-home/icon/message2-icon.svg"
                                }
                              />
                            </span>
                          </Link>
                        </Media>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div
                className="add-to-lists-button"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="swiper-favorite">
                    {
                    
                    props?.userData?.is_fav_user === 1 
                      ?
                        <Link
                          type="button"
                          className="swiper-btn-fav"
                          onClick={(event) => handleStar(event, "removed")}
                        >
                          <Image
                            src="assets/images/icons/star-active.svg"
                            className="svg-clone my-p-icons"
                            width="12"
                          />
                        </Link>
                      :
                        <Link
                          type="button"
                          className="swiper-btn-fav"
                          onClick={(event) => handleStar(event, "added")}
                        >
                          <Image
                            src="assets/images/icons/star.svg"
                            className="svg-clone my-p-icons"
                            width="12"
                          />
                        </Link>
                    // favStatus !== "" ? (
                    //   <>
                    //     <>
                    //       {favStatus === "added" ? (
                    //         <Link
                    //           type="button"
                    //           className="swiper-btn-fav"
                    //           onClick={(event) => handleStar(event, "removed")}
                    //         >
                    //           <Image
                    //             src="assets/images/icons/star-active.svg"
                    //             className="svg-clone my-p-icons"
                    //             width="12"
                    //           />
                    //           {/* {t("remove_from_favorites")} */}
                    //         </Link>
                    //       ) : null}
                    //     </>
                    //     <>
                    //       {favStatus === "removed" ? (
                    //         <Link
                    //           type="button"
                    //           className="swiper-btn-fav"
                    //           onClick={(event) => handleStar(event, "added")}
                    //         >
                    //           <Image
                    //             src="assets/images/icons/star.svg"
                    //             className="svg-clone my-p-icons"
                    //             width="12"
                    //           />
                    //           {/* {t("add_to_favorites")} */}
                    //         </Link>
                    //       ) : null}
                    //     </>
                    //   </>
                    // ) : props.isFavUser === 1 ? (
                    //   <Link
                    //     type="button"
                    //     className="swiper-btn-fav"
                    //     onClick={(event) => handleStar(event, "removed")}
                    //   >
                    //     <Image
                    //       src="assets/images/icons/star-active.svg"
                    //       className="svg-clone my-p-icons"
                    //       width="12"
                    //     />
                    //     {/* {t("remove_from_favorites")} */}
                    //   </Link>
                    // ) : (
                    //   <Link
                    //     type="button"
                    //     className="swiper-btn-fav"
                    //     onClick={(event) => handleStar(event, "added")}
                    //   >
                    //     <Image
                    //       src="assets/images/icons/star.svg"
                    //       className="svg-clone my-p-icons"
                    //       width="12"
                    //     />
                    //     {/* {t("add_to_favorites")} */}
                    //   </Link>
                    // )
                    }
                  </div>
                  {props?.isFriend == 1 && (
                    <div
                      style={{
                        background: "rgb(1 11 23 / 8%)",
                        padding: "0 10px",
                        borderRadius: "6px",
                      }}
                    >
                      Friend
                    </div>
                  )}
                </div>

                {((props?.subscribingType &&
                  props.userData?.tag_info &&
                  Object.keys(props.userData?.tag_info).length > 0) ||
                  (props?.subscribersType &&
                    props.userData?.tag_info &&
                    Object.keys(props.userData?.tag_info).length > 0) ||
                  (props?.formerSubscriberType &&
                    props.userData?.tag_info &&
                    Object.keys(props.userData?.tag_info).length > 0) ||
                  (props?.unsubscribedType &&
                    props.userData?.tag_info &&
                    Object.keys(props.userData?.tag_info).length > 0)) && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      padding: "0 7px",
                      margin: "0 12px",
                      background: "rgba(138, 150, 163, 0.08)",
                      borderRadius: "6px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        background: props.userData?.tag_info?.colour,
                      }}
                    ></div>
                    <p
                      style={{
                        margin: "0",
                      }}
                    >
                      {props.userData?.tag_info?.tag_title}
                    </p>
                  </div>
                )}
                {props?.userData?.subscription_level !== "" &&
                  props?.userData?.subscription_level_badge !== "" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        padding: "0 12px",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                          borderRadius: "6px",
                          background: "rgba(138, 150, 163, 0.08)",
                          padding: "0 10px",
                        }}
                      >
                        {props?.userData?.subscription_level}
                      </p>
                      <Image
                        src={props?.userData?.subscription_level_badge}
                        // className="svg-clone my-p-icons"
                        // width="12"
                        style={{ width: "20px" }}
                      />
                    </div>
                  )}
              </div>
              {props?.userData?.payment_info?.unsubscribe_btn_status == 0 &&
              props?.userData?.payment_info?.subscription_info
                ?.monthly_amount &&
              (props?.subscribers === "subscribers" ||
                props?.unsubscribed === "unsubscribed" ||
                props?.formerSubscriber === "formerSubscriber") ? (
                <div>
                  <div className="user-subscription-btn-sec">
                    <div
                      className="subscription-outline-btn"
                      onClick={(event) =>
                        subscriptionPayment(
                          event,
                          "months",
                          props?.userData?.payment_info.subscription_info
                            .monthly_amount,
                          props?.userData?.payment_info.subscription_info
                            .monthly_amount_formatted
                        )
                      }
                      style={{ fontSize: "1.0em" }}
                    >
                      {`Subscribe ${
                        props?.userData?.payment_info.subscription_info
                          .monthly_amount_formatted
                      } / ${t("month")}`}
                    </div>
                  </div>
                </div>
              ) : null}
              {subscrptionPayment ? (
                <SubscriptionPaymentModal
                  paymentsModal={subscrptionPayment}
                  closepaymentsModal={closePaymentModal}
                  name={props.user.name}
                  user_unique_id={props.user.user_unique_id}
                  subscriptionData={subscriptionData}
                  pageType={"userCard"}
                />
              ) : null}
              {props?.userData?.payment_info?.unsubscribe_btn_status == 1 && (
                <>
                  <div className="user-subscription-btn-sec">
                    <div
                      className="subscription-btn"
                      onClick={() => handleUnfollowModalShow()}
                      style={{ fontSize: "1em", backgroundColor: "#dc3545", border: "none"}}
                    >
                      {t("Subscribing")}
                    </div>
                  </div>

                  <Modal
                    show={showUnfollow}
                    onHide={handleUnfollowModalClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className={`${
                      localStorage.getItem("theme") !== "" &&
                      localStorage.getItem("theme") !== null &&
                      localStorage.getItem("theme") !== undefined &&
                      localStorage.getItem("theme") === "dark"
                        ? "dark-theme-modal"
                        : ""
                    }
                          `}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{t("unsubscribe")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {t("cancel_subscription_conformation")}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        size="lg"
                        onClick={handleUnfollowModalClose}
                      >
                        {t("close")}
                      </Button>
                      <Button
                        variant="primary"
                        size="lg"
                        onClick={(event) =>
                          handleUnSubscriber(event, props.user.user_id)
                        }
                      >
                        {t("yes")}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
              {props?.userData?.show_follow === 1 && (
                <div className="user-subscription-btn-sec">
                  <div
                    className="subscription-btn"
                    style={{ fontSize: "1em" }}
                    onClick={(event) => {
                      if (localStorage.getItem("userId")) {
                        props.dispatch(
                          subscriptionPaymentStripeStart({
                            user_unique_id: props?.user.user_unique_id,
                            plan_type: "months",
                            is_free: 0,
                            pageType: "userCard",
                          })
                        );
                      } else {
                        const notificationMessage = getErrorNotificationMessage(
                          t("login_to_continue")
                        );
                        props.dispatch(createNotification(notificationMessage));
                      }
                    }}
                  >
                    {t("subscribe_for_free")}
                  </div>
                </div>
              )}
              {props?.userData?.show_unfollow === 1 && (
                <div className="user-subscription-btn-sec">
                  <div
                    className="subscription-btn"
                    style={{fontSize: "1em", backgroundColor: "#17a2b8", border: "none"}}
                    // onClick={() => handleUnfollowModalShow()}
                    onClick={(event) =>
                      handleUnfollow(event, props?.user?.user_id)
                    }
                  >
                    {t("following")}
                  </div>
                </div>
              )}
              {props?.user?.is_certified_content_creator === 2 && (
                <div className="lists-button-group post-icons">
                  <Button
                    type="button"
                    className="btn gradient-btn gradientcolor flexAlignCenter"
                    onClick={() => setSendTip(true)}
                  >
                    <Image
                      src="assets/images/icons/tip.svg"
                      className="svg-clone"
                    />
                    <span className="b-btn-text">{t("tip")}</span>
                  </Button>
                </div>
              )}

              <div className="lists-button-group" style={{ display: "none" }}>
                <Link
                  type="button"
                  className="btn gradient-btn gradientcolor flexAlignCenter"
                >
                  <Image
                    src="assets/images/icons/subscribe.svg"
                    className="svg-clone"
                  />
                  <span className="b-btn-text">{t("subscribed_for_free")}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <SendTipModal
        sendTip={sendTip}
        closeSendTipModal={closeSendTipModal}
        username={props.user.username}
        userPicture={props.user.picture}
        name={props.user.usernamee}
        post_id={null}
        user_id={props.user.user_id}
      />
    </>
  );
};

const mapStateToPros = (state) => ({
  saveChatUser: state.chat.saveChatUser,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(translate(SubscribingCard));
