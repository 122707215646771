import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "./BecomeAContentCreator.css";
import { connect } from "react-redux";
import { translate, t } from "react-multi-lang";

const Step7 = () => {
	return (
		<>
			<div className="pending-validation-sec">
				<Row>
					<Col md={4}>
						<Image
							className="pending-validation-img"
							src={
								window.location.origin + "/assets/images/pending-approval.svg"
							}
						/>
					</Col>
					<Col md={8}>
						<div className="pending-validation-info">
							<h5>{t('your_request_is_pending_validation')}</h5>
							<p>{t('you_will_be_notified_when_it_is_processed')}</p>
						</div>
					</Col>
				</Row>
				
			</div>
		</>
	);
};

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(null, mapDispatchToProps)(translate(Step7));
