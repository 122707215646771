import React, { useState } from 'react'
import { Image, InputGroup, FormControl, Media } from "react-bootstrap";
import { translate, t } from "react-multi-lang";
import './SearchInput.css'

const SearchInput = ({handleFilterUserData}) => {

	const [search, setSearch] = useState("")

	const handleSearch = (e) => {
		const inputValue = e.target.value
		setSearch(inputValue)

		handleFilterUserData && handleFilterUserData(inputValue)
	}

	// console.log(search, "search value")
	return (
		<div className='new-feed-search'>
			 <InputGroup className="mb-0 input-group">
			 		<FormControl
            placeholder={t("search")}
            aria-describedby="basic-addon2"
            onChange={handleSearch}
          />
					<InputGroup.Text id="basic-addon2">
            <Image
              className="new-feeds-search-icon"
              src={
                window.location.origin +
                "/assets/images/feed-story/search-icon.svg"
              }
						/>
          </InputGroup.Text>
			 </InputGroup>
		</div>
	)
}

export default SearchInput