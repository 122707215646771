import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import './TagUntagUser.css'
import { saveTagUntagUserStart } from '../../../store/actions/UserAction';
import { components } from "react-select";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getUserAllColorTagStart } from '../../../store/actions/CustomListAction';

const checkTagUntagUser = Yup.object().shape({
	tagName: Yup.string().required("Tag is required")
})

const Option = (props) => {
    
  return (
    <div className="post-level-list-option-wrpapper">
      <components.Option {...props}>
          <div className="search-user-list-option-label-wrapper" style={{}}>
             <div style={{backgroundColor: `${props.data?.color_tag_code}`, height: "10px", width: "10px", borderRadius: "50%"}}></div>
             <label style={{marginBottom: "0px"}}>{props.label}</label>
          </div>
      </components.Option>
    </div>
  );
};

const TagUntagUser = (props) => {
	const { data: colorTagData, loading: colorTagLoader } = useSelector(
	(state) => state.customList.userAllColorTag
	);

	const [selectedColorTags, setSelectedColorTags] = useState(null);
	const [isEdit, setIsEdit] = useState(false)

	const {tagUntagMode, closeTagUntagModel,userId, friendType, followingType, followersType, subscribingType,subscribersType, formerSubscriberType, unsubscribedType,favouriteType, blockType,restrictedType , singleListUserType} = props 
	
	const dispatch = useDispatch();

	const isLoading = useSelector(state => state.users.tagUntagUsers.loading)
	const initialTagUntagValues = {
		tagName: selectedColorTags?.name ? selectedColorTags?.name : "",
		colorTag: selectedColorTags?.color_tag_code ? selectedColorTags?.color_tag_code: "#ae2929"
	}

	useEffect(()=>{
		 dispatch(getUserAllColorTagStart());
	},[])

	return (
		<>
			<Modal
				show={tagUntagMode}
				onHide={closeTagUntagModel}
				backdrop="static"
				keyboard={false}
				centered
				className={`${
					localStorage.getItem("theme") !== "" &&
					localStorage.getItem("theme") !== null &&
					localStorage.getItem("theme") !== undefined &&
					localStorage.getItem("theme") === "dark"
						? "dark-theme-modal"
						: ""
					}`
				}
			>
				<Formik
					enableReinitialize={true}
					initialValues={initialTagUntagValues}
					validationSchema={checkTagUntagUser}
					onSubmit={(values) => {
						if (values.tagName !== "") {
							dispatch(
								saveTagUntagUserStart({
									user_id: userId, 
									tag_title: values.tagName, 
									tag_colour_code: values.colorTag, 
									friendType: friendType, 
									closeTagUntagModel: closeTagUntagModel, 
									tagModel: true,
									followingType: followingType,
									followersType: followersType,
									subscribingType: subscribingType,
									subscribersType: subscribersType,
									formerSubscriberType: formerSubscriberType,
									unsubscribedType: unsubscribedType,
									favouriteType: favouriteType,
									blockType: blockType,
									restrictedType: restrictedType,
									singleListUserType : singleListUserType
								})
							)
						}
					}}
				>
					{({errors, touched, handleSubmit}) => (
						<form onSubmit={handleSubmit}>
							<Modal.Header closeButton>
								<Modal.Title>Tag</Modal.Title>
							</Modal.Header>

							<Modal.Body>
								<div className='tag-container'>
									{
										colorTagData.length > 0
										? (<>
										<div className='field-container'>
											<div className="label-container">
												<label htmlFor="tagName">Select color tag</label>

													<i
														className="fa fa-pencil-square-o"
														aria-hidden="true"
														style={{
															fontSize: "18px",
															color: "#8c2be2",
															cursor: "pointer",
														}}
														onClick={() => setIsEdit(true)}
													/>
											</div>
											
											{
												colorTagData.length > 0
													&&
												(
													<Select
														className="basic-single"
														classNamePrefix="select"
														// defaultValue={usersFilters[0]}
														isDisabled={colorTagLoader}
														isLoading={colorTagLoader}
														isClearable={false}
														isRtl={false}
														isSearchable={false}
														components={{ Option , animatedComponents: makeAnimated()}}
														placeholder="Color tag"	
														name="color"
														// value={selectedColorTags}
														options={colorTagData?.map((ele) => {
															return {
																name: ele.tag_title,
																label: ele.tag_title,
																color_tag_code: ele.tag_colour_code,
															};
														})}
														onChange={(selected) => {
															// debugger;
															setSelectedColorTags(selected);
														}}
													/>
												)
											}
												
									</div>
									{
										isEdit
											&&
										(
											<div className='color__tag__input'>
												<div className='field-container'>
													<label htmlFor="tagName">Tag name</label>
													<Field
														name="tagName"
														id="tagName"
														className="add-tag"
													/>
													{
														errors.tagName && touched.tagName ?
														(
															<p
																style={{
																	color: "rgb(255, 0, 0)",
																	fontSize: "13px",
																	fontWeight: "400",
																	marginTop: "4px"
																}}	
															>
																{errors.tagName}
															</p>
														)
														: ""
													}
												</div>
												<div className='field-container'>
													<label htmlFor="colorTag">Choose color:</label>
													<Field
														type="color"
														name="colorTag"
														id="colorTag"
													/>
												</div>
											</div>
										)
										}
										</>)
										: (
											<div className='color__tag__input'>
												<div className='field-container'>
													<label htmlFor="tagName">Tag name</label>
													<Field
														name="tagName"
														id="tagName"
														className="add-tag"
													/>
													{
														errors.tagName && touched.tagName ?
														(
															<p
																style={{
																	color: "rgb(255, 0, 0)",
																	fontSize: "13px",
																	fontWeight: "400",
																	marginTop: "4px"
																}}	
															>
																{errors.tagName}
															</p>
														)
														: ""
													}
												</div>
												<div className='field-container'>
													<label htmlFor="colorTag">Choose color:</label>
													<Field
														type="color"
														name="colorTag"
														id="colorTag"
													/>
												</div>
											</div>
										)
									}
									
								</div>
							</Modal.Body>
							
							<Modal.Footer>
									<Button
										variant="secondary"
										size="lg"
										onClick={props.closeTagUntagModel}
									>
										Close
									</Button>
									<Button
										size="lg" 
										className="ftr-btn" 
										type="submit"
										disabled={isLoading}
									>
										{isLoading ? "Loading..." : "Add"}
									</Button>
								</Modal.Footer>
						</form>
					)}
				</Formik>
			</Modal>
		</>		
	)
}

export default TagUntagUser